import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { useEffect, useState } from 'react';

const apikey: string = 'AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs'

const mapStyles = {
    height: "50vh",
    width: "60vw",
}

function ProfileMap(props: any) {

    let lat = parseFloat(props.lastLocation.split(',')[0])
    let lng = parseFloat(props.lastLocation.split(',')[1])

    const [address, setAddress] = useState("")

    useEffect(() => {
        setTimeout(() => {
            const google = window.google
            if (google) {
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({
                    location: {
                        lat: parseFloat(props.lastLocation.split(',')[0]),
                        lng: parseFloat(props.lastLocation.split(',')[1]),
                    }
                })
                    .then((res: any) => {
                        let results = res.results
                        if (results.length > 0) {
                            let formatted_address = results[0].formatted_address
                            let values = formatted_address.split(',')
                            let city = values[values.length - 3]
                            let province = values[values.length - 2]
                            let country = values[values.length - 1]
                            setAddress(city + ',' + province + ',' + country)
                        }
                    })
            }
        }, 1000);
    }, [])

    return (
        <div >
            <h2 style={{ textAlign: "center" }}>I currently work in {address}</h2>
            <LoadScript
                googleMapsApiKey={apikey}
                libraries={["places"]}
            >
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={13}
                    center={{
                        lat: lat,
                        lng: lng
                    }}
                >
                    <Marker
                        position={{
                            lat: lat,
                            lng: lng
                        }}
                    />
                </GoogleMap>
            </LoadScript>
        </div>
    );
}

export default ProfileMap;