import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { postLogin } from './login.api'


export interface LoginState {
  loading : boolean,
  postLoginData : any
}
const initialState: LoginState = {
  loading : false,
  postLoginData : null
}

export const loginSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
        // },
        // decrement: (state) => {
        // state.value -= 1
        // },
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        // state.value += action.payload
        // },
    },
    extraReducers : (builder) => {
        //post
        builder.addCase(postLogin.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postLogin.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postLogin.rejected, (state, action) => {
            state.loading = false
            // state.postAccountData = action.payload.

        })
    },
})

export default loginSlice.reducer