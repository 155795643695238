
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../../state'

export const getCompany = createAsyncThunk('account/getCompany', async () => {
    const response = await axios.get(`${baseUrl}/api/Company/GetList`)
    console.log(response)
    return response.data
})

export const postCompany = createAsyncThunk('account/postCompany', async (body:any) => {
    try {
        const resCompany = await axios.post(`${baseUrl}/api/Company/Create`, body)
        return resCompany.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const editCompany = createAsyncThunk('account/editCompany', async (body:any) => {
    try {
        const resCompany = await axios.post(`${baseUrl}/api/Company/Edit`, body)
        return resCompany.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const deleteCompany = createAsyncThunk('account/deleteCompany', async (id:string) => {
    try {
        const resCompany = await axios.delete(`${baseUrl}/api/Company/Delete`, {
            params : {
                targetCompanyId : id
            }
        })
        return resCompany
    } 
    catch (error:any) {
        return error.response
    }
})