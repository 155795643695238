
import { useEffect, useState } from 'react';
import { Button, Container } from '@material-ui/core';
import ModalTemplate from '../../../../shared/ModalTemplate';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Template from '../../../../shared/Template'
import FormAccount from '../component/FormAccount';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAccount, getAccount } from '../account.api';
import { RootState } from '../../../../app/store';
import LoadingCustom from '../../../../shared/LoadingCustom';
import Swal from 'sweetalert2';
import MUIDataTable from 'mui-datatables'
import Roles from '../../../../shared/Roles';
import FormResetPassword from '../component/FormResetPassword';

function Account() {
    const dispatch = useDispatch()

    const { listAccount, loading } = useSelector((state: RootState) => state.account)

    const [modal, setModal] = useState<any>({})
    const [modalResetPassword, setModalResetPassword] = useState<any>({})
    const [listAccountFiltered, setListAccountFiltered] = useState<any>([])

    useEffect(() => {
        dispatch(getAccount())
    }, [])

    useEffect(() => {
        if (Roles("tracker_account_all")) {
            setListAccountFiltered(listAccount)
        }
        else if (Roles("tracker_account_filtered")) {
            setListAccountFiltered(listAccount.filter((item: any) => item.company === localStorage.getItem('company')))
        }
    }, [listAccount])

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Are you sure you want to delete this account?',
            text: "You won't be able to undo this change!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch<any>(deleteAccount(id))
                    .unwrap()
                    .then((res: any) => {
                        console.log(res, 'saya resssssss')
                        if (res.status !== 200) {
                            Swal.fire(
                                'Delete Account Failed!',
                                `${res.data.message || Object.values(res.data.errors)}`,
                                'error'
                            )
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'Account Successfully Deleted',
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then(() => {
                                    dispatch(getAccount())
                                })
                        }
                    })
            }
        })

    }

    const handleAdd = () => {
        setModal({
            status: true,
            type: 'create',
            data: {}
        })
    }

    const handleEdit = (id: string) => {
        setModal({
            status: true,
            type: 'edit',
            data: listAccount?.filter((item: any) => item.id === id)[0]
        })
    }

    const handleResetPassword = (id: string) => {
        setModalResetPassword({
            status: true,
            data: listAccount?.filter((item: any) => item.id === id)[0]
        })
    }

    let component = () => (
        <>
            <LoadingCustom isLoading={loading} />
            <Container maxWidth={false} className="conten">
                <ModalTemplate
                    component={() => <FormAccount type={modal.type} data={modal.data} onClose={() => setModal({ status: false })} />}
                    onOpen={modal.status}
                />
                <ModalTemplate
                    component={() => <FormResetPassword data={modalResetPassword.data} onClose={() => setModalResetPassword({ status: false })} />}
                    onOpen={modalResetPassword.status}
                />
                <div className="account">
                    <MUIDataTable
                        title={"User Account"}
                        data={listAccountFiltered}
                        columns={[
                            {
                                name: "company",
                                label: "Company"
                            },
                            {
                                name: "firstName",
                                label: "First Name"
                            },
                            {
                                name: "lastName",
                                label: "Last Name"
                            },
                            {
                                name: "email",
                                label: "Email"
                            },
                            {
                                name: "rolesDisplay",
                                label: "Roles"
                            },
                            {
                                name: "id",
                                label: "Action",
                                options: {
                                    customBodyRender: (value: any) => (
                                        <div className='action-button'>
                                            <Button className="button-add" size='small' variant='contained' style={{ marginRight: 10 }} onClick={() => handleEdit(value)}>Edit</Button>
                                            <Button className="button-delete" size='small' variant='contained' style={{ marginRight: 10 }} onClick={() => handleDelete(value)}>Delete</Button>
                                            <Button className="button-edit" size='small' variant='contained' onClick={() => handleResetPassword(value)}>Reset Password</Button>
                                        </div>
                                    ),
                                    filter: false,
                                    download: false
                                }
                            }
                        ]}
                        options={{
                            customToolbar: (data: any) => {
                                return (
                                    <Button className="button-add" variant='contained' onClick={handleAdd}>Create Account</Button>
                                )
                            },
                            selectableRows: "none",
                            rowsPerPageOptions: [50, 100, 200],
                            rowsPerPage: 50,
                            downloadOptions: {
                                filename: "utility fielding - account.csv"
                            },
                            print: false
                        }}
                    />
                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default Account;