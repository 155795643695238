import { useEffect } from "react";
import { Fab } from "@material-ui/core";
import IndexMap from "./IndexMap";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { handleLeftButton, handleRightButton, setLeftButton, setLeftButtonShow } from "../dashboard.reducer";
import { RootState } from "../../../app/store";
import Roles from "../../../shared/Roles";

function DashboardMap(props: any) {
  let dispatch = useDispatch();

  const { leftButton, rightButton, leftButtonShow } = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    if (String(Roles("home")) === '/poleloading') {
      dispatch(setLeftButtonShow(false))
      dispatch(setLeftButton(false))
    }
  }, [])

  return (
    <div className="dashboard">
      {leftButtonShow && (
        <Fab size="small" className="arrowright" onClick={() => dispatch(handleLeftButton())}>
          {leftButton ? <ChevronLeft /> : <ChevronRight className="blink" />}
        </Fab>
      )}
      <div className="wrapmap">
        <IndexMap />
      </div>
      <Fab size="small" className="arrowleft" onClick={() => dispatch(handleRightButton())}>
        {rightButton ? <ChevronRight /> : <ChevronLeft />}
      </Fab>
    </div>
  );
}

export default DashboardMap;
