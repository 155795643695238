import { createSlice } from "@reduxjs/toolkit";
import { getAllAssignedJob, getAllAssignedJobMapView, getFielders, getFieldingRequestDetail, moveAttachment, postFielders } from "./assignFielding.api";
// import {  } from './account.api'

export interface AccountState {
  loading: boolean;
  isAssignModal: boolean;
  isMapModal: boolean;
  isMapModalFielding: boolean;
  isChooseFielderModal: boolean;
  isFielderListModal: boolean;
  aerialFielding: any;
  activeAerial: any;
  statusSelected: any; 
  assignFieldingList: any;
  mapPoleList: any;
  fielderList: any;
  requestSelected: any;
}
const initialState: AccountState = {
  loading: false,
  isAssignModal: false,
  isMapModal: false,
  isMapModalFielding: false,
  isChooseFielderModal: false,
  isFielderListModal: false,
  aerialFielding: {},
  activeAerial: null,
  statusSelected: 0,
  assignFieldingList: [],
  mapPoleList: [],
  fielderList: [],
  requestSelected: {},
};

export const assignFieldingSlice = createSlice({
  name: "assignFielding",
  initialState,
  reducers: {
    handleIsAssignModal: (state) => {
      state.isAssignModal = !state.isAssignModal;
    },
    handleIsMapModal: (state) => {
      state.isMapModal = !state.isMapModal;
    },
    handleIsMapModalFielding: (state) => {
      state.isMapModalFielding = !state.isMapModalFielding;
    },
    handleIsChooseFielderModal: (state, action) => {
      state.isChooseFielderModal = !state.isChooseFielderModal;
      state.requestSelected = action.payload;
    },
    handleIsFielderListModal: (state, action) => {
      state.isFielderListModal = !state.isFielderListModal;
      state.requestSelected = action.payload;
    },
    setAerialFielding: (state, action) => {
      state.aerialFielding = action.payload;
    },
    setActiveAerial: (state, action) => {
      state.activeAerial = action.payload;
    },
    setStatusSelected: (state, action) => {
      state.statusSelected = action.payload;
    }
  },
  extraReducers: (builder) => {
    // get
    builder.addCase(getAllAssignedJob.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAllAssignedJob.fulfilled, (state, action) => {
      state.loading = false
      state.assignFieldingList = action.payload.data
    })
    builder.addCase(getAllAssignedJob.rejected, (state, action) => {
      state.loading = false
    })
    // get map
    builder.addCase(getAllAssignedJobMapView.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAllAssignedJobMapView.fulfilled, (state, action) => {
      state.loading = false
      state.mapPoleList = action.payload.data
    })
    builder.addCase(getAllAssignedJobMapView.rejected, (state, action) => {
      state.loading = false
    })
    // get
    builder.addCase(getFielders.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getFielders.fulfilled, (state, action) => {
      state.loading = false
      state.fielderList = action.payload.data
    })
    builder.addCase(getFielders.rejected, (state, action) => {
      state.loading = false
    })
    // post fielder
    builder.addCase(postFielders.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(postFielders.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(postFielders.rejected, (state, action) => {
      state.loading = false
    })
    // move attachment
    builder.addCase(moveAttachment.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(moveAttachment.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(moveAttachment.rejected, (state, action) => {
      state.loading = false
    })
    // get filding req detail
    builder.addCase(getFieldingRequestDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFieldingRequestDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.aerialFielding = action.payload.data
    });
    builder.addCase(getFieldingRequestDetail.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  handleIsAssignModal,
  handleIsMapModal,
  handleIsMapModalFielding,
  handleIsChooseFielderModal, 
  handleIsFielderListModal, 
  setActiveAerial, 
  setAerialFielding,
  setStatusSelected
} = assignFieldingSlice.actions;

export default assignFieldingSlice.reducer;
