import { Button, Card, CardContent, Grid, MenuItem, TextField } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

export default function FormFieldingCsv(props: any) {

    const keys = [
        { label: "Pole Sequence", name: "poleSequence" },
        { label: "Latitude", name: "lat" },
        { label: "MRE Estimation", name: "mreEstimation" },
        { label: "Street Name", name: "streetName" },
        { label: "Longitude", name: "long" },
        { label: "Notes", name: "notes" }
    ]

    const [columnsSelected, setColumnsSelected] = useState<any>({})

    useEffect(() => {
        if (props.csvData.length > 0) {
            let result = {}
            keys.map((key, i: number) => (
                result = { ...result, [i]: props.csvData[0].indexOf(key.label) }
            ))
            setColumnsSelected(result)
        }
    }, [props.csvData])

    const handleDone = () => {
        let data = props.csvData
            .filter((item: any, i: number) => i !== 0)
            .map((row: any) => {
                let object: any = {}
                keys.map((key: any, j: number) => {
                    object[key.name] = row[columnsSelected[j]]
                })
                return object
            })
            .filter((d: any) => Boolean(d.lat) && Boolean(d.long))
        props.setJsonData(data)
        props.setCsvData([])
    }

    return (
        <Card>
            <CardContent className="csv-import">
                <Grid container spacing={2}>
                    {keys.map((key: any, i: number) => (
                        <Grid item md={4}>
                            <div className="option" key={i}>
                                <span className="label">
                                    {key.label}
                                </span>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    select
                                    label="Column"
                                    placeholder="Choose column on CSV"
                                    value={String(columnsSelected[i])}
                                    onChange={(e) => setColumnsSelected({ ...columnsSelected, [i]: e.target.value })}
                                >
                                    {props.csvData[0]?.map((column: any, j: number) => (
                                        <MenuItem key={j} value={j}>{column}</MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <div className="wrapbutton">
                    <Button type="button" className="cancel" variant="outlined" onClick={() => props.setCsvData([])}>
                        <X /> Cancel
                    </Button>
                    <Button type="submit" className="done" variant="outlined" onClick={handleDone}>
                        <Done /> Done
                    </Button>
                </div>
                <br />
                <div className="table-container">
                    <Table>
                        <Thead>
                            <Tr>
                                {props.csvData[0]?.map((column: any) => (
                                    <Th>{column}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.csvData.map((row: any, i: number) => {
                                if (i !== 0) {
                                    return (
                                        <Tr>
                                            {row.map((column: any) => (
                                                <Td>{column}</Td>
                                            ))}
                                        </Tr>
                                    )
                                }
                                return null
                            })}
                        </Tbody>
                    </Table>
                </div>
            </CardContent>
        </Card >
    )
}