import axios from "axios";
import { useEffect, useState } from "react";
import { Table, Tr, Td } from "react-super-responsive-table";
import Swal from "sweetalert2";
import { baseUrl } from "../../state";

function JobNumberPdf() {

    const [data, setData] = useState<any>([])
    const [poleEnum, setPoleEnum] = useState<any>({})
    const [imageList, setImageList] = useState<any>({})

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        Swal.fire({
            title: "Loading",
            text: "loading pdf data",
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const jobNumberId = new URLSearchParams(window.location.search).get('jobNumberId');
        axios.get(baseUrl + "/api/Pole/GetPoleInformationEnums")
            .then(response => {
                setPoleEnum(response?.data?.data)
                axios.get(baseUrl + "/api/JobNumber/GetPDFData?targetJobNumberId=" + jobNumberId + "&userId=" + localStorage.getItem("id"))
                    .then(response => {
                        setData(response?.data?.data)
                        let totalImages = 0
                        response?.data?.data?.fieldingRequests.map((fieldingRequest: any) => {
                            fieldingRequest.poles.map((pole: any) => {
                                totalImages = totalImages + pole.imageList.length
                            })
                            fieldingRequest.measurementMarkers.map((pole: any) => {
                                totalImages = totalImages + pole.imageList.length
                            })
                        })
                        Swal.fire({
                            title: "Loading",
                            html: 'loading <b></b> images',
                            didOpen: async () => {
                                Swal.showLoading()
                                const b: any = Swal.getHtmlContainer()?.querySelector('b') || {}
                                let imageListLocal = {}
                                let totalImagesLoaded = 0
                                for (const i in response?.data?.data?.fieldingRequests) {
                                    if (response?.data?.data?.fieldingRequests[i].poles) {
                                        for (const j in response?.data?.data?.fieldingRequests[i].poles) {
                                            for (const k in response?.data?.data?.fieldingRequests[i].poles[j].imageList) {
                                                const { id, filePath } = response?.data?.data?.fieldingRequests[i].poles[j].imageList[k]
                                                try {
                                                    const res = await axios({
                                                        url: filePath.includes(".com") ? filePath + '?width=100' : baseUrl + "/api/Global/Download?path=" + filePath,
                                                        method: "GET",
                                                        responseType: "blob"
                                                    })
                                                    const imageBlob = new Blob([res.data])
                                                    const imageObjectURL = URL.createObjectURL(imageBlob);
                                                    imageListLocal = {
                                                        ...imageListLocal,
                                                        [id]: imageObjectURL
                                                    }
                                                }
                                                catch (error) {
                                                    console.log(error)
                                                }
                                                totalImagesLoaded = totalImagesLoaded + 1
                                                b.textContent = `${totalImagesLoaded}/${totalImages}`
                                            }
                                        }
                                    }
                                    if (response?.data?.data?.fieldingRequests[i].measurementMarkers) {
                                        for (const j in response?.data?.data?.fieldingRequests[i].measurementMarkers) {
                                            for (const k in response?.data?.data?.fieldingRequests[i].measurementMarkers[j].imageList) {
                                                const { id, filePath } = response?.data?.data?.fieldingRequests[i].measurementMarkers[j].imageList[k]
                                                try {
                                                    const res = await axios({
                                                        url: filePath.includes(".com") ? filePath + '?width=100' : baseUrl + "/api/Global/Download?path=" + filePath,
                                                        method: "GET",
                                                        responseType: "blob"
                                                    })
                                                    const imageBlob = new Blob([res.data])
                                                    const imageObjectURL = URL.createObjectURL(imageBlob);
                                                    imageListLocal = {
                                                        ...imageListLocal,
                                                        [id]: imageObjectURL
                                                    }
                                                }
                                                catch (error) {
                                                    console.log(error)
                                                }
                                                totalImagesLoaded = totalImagesLoaded + 1
                                                b.textContent = `${totalImagesLoaded}/${totalImages}`
                                            }
                                        }
                                    }
                                }
                                setImageList(imageListLocal)
                                Swal.close()
                                setTimeout(() => {
                                    window.print()
                                }, 1000)
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        console.log(imageList)
    }, [imageList])

    const typeInitial = ["T", "C", "O", "P"]
    const valueInitial = ["1in", "2in", "3in", "4in", "VGR"]

    return (
        <div className="pole-table">
            <Table>
                <Tr><Td><span style={{ fontSize: 22 }}>Utility Fielding</span></Td></Tr>
                <Tr><Td>Last update: {data?.completeDateString}</Td></Tr>
            </Table>
            <br />
            <br />
            <Table>
                <Tr>
                    <Td>Company</Td>
                    <Td>{data?.companyName}</Td>
                </Tr>
                <Tr>
                    <Td>Project Name</Td>
                    <Td>{data?.projectName}</Td>
                </Tr>
                <Tr>
                    <Td>Job Number</Td>
                    <Td>{data?.jobNumber}</Td>
                </Tr>
            </Table>
            <br />
            {data?.fieldingRequests?.map((fielding: any, fieldingIndex: number) => (
                <div key={fieldingIndex}>
                    <br />
                    <Table>
                        <Tr>
                            <td rowSpan={2}>{fielding?.name}</td>
                            <Td>Fielding Created Date</Td>
                            <Td>Fielding Due Date</Td>
                            <Td>Status</Td>
                            <Td>Fielding Complete</Td>
                        </Tr>
                        <Tr>
                            <Td>{fielding?.createdDateString}</Td>
                            <Td>{fielding?.dueDateString}</Td>
                            <Td>{fielding?.completedPolePercentage}</Td>
                            <Td>{fielding?.completeDateString}</Td>
                        </Tr>
                    </Table>
                    <br />
                    {fielding?.fieldingRequestTypeString === "MREEstimation"
                        ? (
                            fielding?.poles?.map((pole: any, poleIndex: number) => (
                                <div key={poleIndex} style={{ pageBreakBefore: "always" }}>
                                    <Table>
                                        <Tr>
                                            <Td>Pole Sequence</Td>
                                            <Td>{pole?.poleSequence}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>MRE Estimation</Td>
                                            <Td>{pole?.mreRequirementsString}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Pole Number/Tag</Td>
                                            <Td>{pole?.isPoleNumberUnknown ? "UNK" : pole?.poleNumber}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Pole Location</Td>
                                            <Td>{pole?.latitude}, {pole?.longitude}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Street Name</Td>
                                            <Td>{pole?.street}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Photos</Td>
                                            <Td>
                                                {pole?.imageList?.length > 0 ? (
                                                    <div className="image-container">
                                                        {pole?.imageList?.map((item: any, index: any) => {
                                                            return (
                                                                <img
                                                                    key={index}
                                                                    src={imageList[item.id]}
                                                                    alt={item.fileName}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                ) : "-"}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Documents</Td>
                                            <Td>
                                                {pole?.documentList?.length > 0 ? (
                                                    pole?.documentList?.map((item: any, index: any) => (
                                                        <div>
                                                            <span>{index + 1}. {item?.fileName}</span>
                                                        </div>
                                                    ))
                                                ) : "-"}
                                            </Td>
                                        </Tr>
                                    </Table>
                                    <br />
                                </div>
                            ))
                        )
                        : fielding?.name.includes("MM")
                            ? (
                                fielding?.measurementMarkers?.map((pole: any, poleIndex: number) => (
                                    <div key={poleIndex} style={{ pageBreakBefore: "always" }}>
                                        <Table>
                                            <Tr>
                                                <Td>MM Sequence</Td>
                                                <Td>{pole?.sequence}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Location</Td>
                                                <Td>{pole?.latitude}, {pole?.longitude}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Length</Td>
                                                <Td>{pole?.length}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Note</Td>
                                                <Td>{pole?.note}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Photos</Td>
                                                <Td>
                                                    {pole?.imageList?.length > 0 ? (
                                                        <div className="image-container">
                                                            {pole?.imageList?.map((item: any, index: any) => {
                                                                return (
                                                                    <img
                                                                        key={index}
                                                                        src={imageList[item.id]}
                                                                        alt={item.fileName}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                        </Table>
                                        <br />
                                    </div>
                                ))
                            )
                            : (
                                fielding?.poles?.map((pole: any, poleIndex: number) => (
                                    <div key={poleIndex} style={{ pageBreakBefore: "always" }}>
                                        <Table>
                                            <Tr>
                                                <Td>Fielding Date</Td>
                                                <Td>{pole?.fieldingCompletedDateString}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Sequence</Td>
                                                <Td>{pole?.poleSequence}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Number/Tag</Td>
                                                <Td>{pole?.isPoleNumberUnknown ? "UNK" : pole?.poleNumber}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Location</Td>
                                                <Td>{pole?.latitude}, {pole?.longitude}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Street Name</Td>
                                                <Td>{pole?.street}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>FAP Terminal</Td>
                                                <Td>{pole?.isFAPUnknown ? "UNK" : pole?.vapTerminal}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>OSMOSE Number</Td>
                                                <Td>{pole?.isOsmoseUnknown ? "UNK" : pole?.osmose}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Other Number</Td>
                                                <Td>{pole?.isOtherNumberUnknown ? "UNK" : pole?.otherNumber}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Ground Line Circumference</Td>
                                                <Td>{pole?.isGroundLineUnknown ? "UNK" : (pole?.groundCircumference + (pole?.isGroundLineEstimated ? ", EST" : ""))}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Year</Td>
                                                <Td>{pole?.isYearUnknown ? "UNK" : (pole?.poleYear + (pole?.isYearEstimated ? ", EST" : ""))}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Condition</Td>
                                                <Td>{pole?.poleConditionString}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>MRE Estimation</Td>
                                                <Td>{pole?.mreRequirementsString}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Radio Antena</Td>
                                                <Td>{pole?.isRadioAntenna ? 'Yes' : 'No'}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Stamp</Td>
                                                <Td>{pole?.poleStamp ? 'Yes' : 'No'}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Length</Td>
                                                <Td>{pole?.isPoleLengthUnknown ? "UNK" : (pole?.poleHeightString + 'ft' + (pole?.isPoleLengthEstimated ? ", EST" : ""))}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Class</Td>
                                                <Td>{pole?.isPoleClassUnknown ? "UNK" : (pole?.poleClassString + (pole?.isPoleClassEstimated ? ", EST" : ""))}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Species</Td>
                                                <Td>{pole?.isSpeciesUnknown ? "UNK" : (pole?.poleSpeciesString + (pole?.isSpeciesEstimated ? ", EST" : ""))}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole HOA</Td>
                                                <Td>
                                                    {pole?.hoaList?.length > 0 ? (
                                                        <table>
                                                            <thead>
                                                                <td>Type</td>
                                                                <td>Size (Feet)</td>
                                                                <td>Size (Inch)</td>
                                                            </thead>
                                                            <tbody>
                                                                {pole?.hoaList?.map((item: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td>{poleEnum.hoaTypes.filter((enumItem: any) => enumItem.value === item?.type)[0].display}</td>
                                                                        <td>{item?.poleLengthInFeet}</td>
                                                                        <td>{item?.poleLengthInInch}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Transformer</Td>
                                                <Td>
                                                    {pole?.transformerList?.length ? (
                                                        <table>
                                                            <thead>
                                                                <td>Value (KV)</td>
                                                            </thead>
                                                            <tbody>
                                                                {pole?.transformerList?.map((item: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td>{item?.value}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Span And Directions</Td>
                                                <Td>
                                                    {pole?.spanDirectionList?.length > 0 ? (
                                                        <table>
                                                            <thead>
                                                                <td>Length</td>
                                                            </thead>
                                                            <tbody>
                                                                {pole?.spanDirectionList?.map((item: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td>{item?.length}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Anchor</Td>
                                                <Td>
                                                    {pole?.anchorList?.length > 0 ? (
                                                        <table>
                                                            <thead>
                                                                <td>Name</td>
                                                                <td>Size</td>
                                                                <td>Eyes</td>
                                                                <td>Picture of eyes</td>
                                                                <td>Condition</td>
                                                                <td>Down Guy</td>
                                                            </thead>
                                                            <tbody>
                                                                {pole?.anchorList?.map((item: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td>A{item?.text}</td>
                                                                        <td>{item.isAnchorSizeUnknown ? "Unknown" : poleEnum.anchorSizes.filter((enumItem: any) => enumItem.value === item.size)[0].display}</td>
                                                                        <td>{item.isAnchorEyeUnknown ? "Unknown" : poleEnum.anchorEyes.filter((enumItem: any) => enumItem.value === item.anchorEye)[0].display}</td>
                                                                        <td>{item.eyesPict ? "Yes" : "Yo"}</td>
                                                                        <td>{poleEnum.anchorConditions.filter((enumItem: any) => enumItem.value === item.anchorCondition)[0].display}</td>
                                                                        <td>
                                                                            {item?.downGuyList?.length > 0 ? (
                                                                                <table>
                                                                                    <thead>
                                                                                        <td>Condition</td>
                                                                                        <td>Size</td>
                                                                                        <td>Owner</td>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {item?.downGuyList.map((dg: any, index: number) => (
                                                                                            <tr>
                                                                                                <td>{poleEnum.downGuyTypes.filter((enumItem: any) => enumItem.value === dg.type)[0].display}</td>
                                                                                                <td>{poleEnum.downGuySizes.filter((enumItem: any) => enumItem.value === dg.size)[0].display}</td>
                                                                                                <td>{poleEnum.downGuyOwners.filter((enumItem: any) => enumItem.value === dg.owner)[0].display}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            ) : "-"}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Riser And Vgr</Td>
                                                <Td>
                                                    {pole?.riserAndVGRList?.length > 0 ? (
                                                        <table>
                                                            <thead>
                                                                <td>Name</td>
                                                                <td>Size</td>
                                                                <td>Type</td>
                                                            </thead>
                                                            <tbody>
                                                                {pole?.riserAndVGRList?.map((item: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td>{item.generalRVGRSequence + "." + typeInitial[item.type] + "R - " + valueInitial[item.value]}</td>
                                                                        <td>{poleEnum.riserVgrTypes.filter((enumItem: any) => enumItem.value === item.value)[0].display}</td>
                                                                        <td>{poleEnum.downGuyOwners.filter((enumItem: any) => enumItem.value === item.type)[0].display}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Photos</Td>
                                                <Td>
                                                    {pole?.imageList?.length > 0 ? (
                                                        <div className="image-container">
                                                            {pole?.imageList?.map((item: any, index: any) => {
                                                                return (
                                                                    <img
                                                                        key={index}
                                                                        src={imageList[item.id]}
                                                                        alt={item.fileName}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Documents</Td>
                                                <Td>
                                                    {pole?.documentList?.length > 0 ? (
                                                        pole?.documentList?.map((item: any, index: any) => (
                                                            <div>
                                                                <span>{index + 1}. {item?.fileName}</span>
                                                            </div>
                                                        ))
                                                    ) : "-"}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Pole Set Surfaces</Td>
                                                <Td>{pole?.poleSetSurfacesString}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>C-Truss</Td>
                                                <Td>{pole?.cTruss === true ? "Yes" : pole?.cTruss === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>C-Tree Trim</Td>
                                                <Td>{pole?.treeTrim === true ? "Yes" : pole?.treeTrim === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>PL VIS Strips</Td>
                                                <Td>{pole?.pL_VIS_Strips === true ? "Yes" : pole?.pL_VIS_Strips === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>PL VGR</Td>
                                                <Td>{pole?.pL_VGR === true ? "Yes" : pole?.pL_VGR === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>INS DG</Td>
                                                <Td>{pole?.inS_DG === true ? "Yes" : pole?.inS_DG === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>RMV ABN DW'S</Td>
                                                <Td>{pole?.rmV_ABN_DWS === true ? "Yes" : pole?.rmV_ABN_DWS === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Reloc DW'S Out Of Climbing Space</Td>
                                                <Td>{pole?.relocDWS === true ? "Yes" : pole?.relocDWS === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>PL GG On DG</Td>
                                                <Td>{pole?.pL_GG_OnDG === true ? "Yes" : pole?.pL_GG_OnDG === false ? "No" : "-"}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Notes</Td>
                                                <Td>{pole?.notes}</Td>
                                            </Tr>
                                        </Table>
                                        <br />
                                    </div>
                                ))
                            )
                    }
                </div>
            ))}
        </div>
    )
}

export default JobNumberPdf;

