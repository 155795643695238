import { Button, TextField, MenuItem } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Save, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import ModalTemplate from "../../../../shared/ModalTemplate";
import { getFieldingRequestChoose, getJobNumberChoose } from "../../sideTab.api";
import AerialFieldingMoveModalAdd from "./AerialFieldingMoveModalAdd";

export default function AerialFieldingMoveModal(props: any) {

  const dispatch = useDispatch()

  const { listProject } = useSelector((state: RootState) => state.sideTab);

  const [projectId, setProjectId] = useState("")
  const [listJobNumber, setListJobNumber] = useState<any>([])
  const [jobNumberId, setJobNumberId] = useState("")
  const [jobNumberName, setJobNumberName] = useState("")
  const [fieldingRequestList, setFieldingRequestList] = useState<any>([])
  const [fieldingRequestId, setFieldingRequestId] = useState("")
  const [modalAddFielding, setModalAddFielding] = useState(false)

  useEffect(() => {
    setJobNumberId("")
    if (projectId) {
      dispatch<any>(getJobNumberChoose({
        id: projectId,
        status: 0
      }))
        .unwrap()
        .then((response: any) => {
          setListJobNumber(response.data)
        })
    }
    else {
      setFieldingRequestList([])
    }
  }, [projectId])

  useEffect(() => {
    setFieldingRequestId("")
    if (jobNumberId) {
      if (!modalAddFielding) {
        dispatch<any>(getFieldingRequestChoose(jobNumberId))
          .unwrap()
          .then((response: any) => {
            setFieldingRequestList(response.data)
          })
      }
    }
    else {
      setFieldingRequestList([])
    }
  }, [jobNumberId, modalAddFielding])

  const handleSave = (e: any) => {
    e.preventDefault()
    props.onSubmit(fieldingRequestId)
  }

  const handleChooseProject = (id: any) => {
    setProjectId(id)
  }

  const handleChooseJobnumber = (id: any) => {
    setJobNumberId(id)
    setJobNumberName(listJobNumber.filter((item: any) => item.id === id)[0].name)
  }

  return (
    <div className="formnote">
      <ModalTemplate
        onOpen={modalAddFielding}
        component={() =>
          <AerialFieldingMoveModalAdd
            onClose={() => setModalAddFielding(false)}
            jobnumberId={jobNumberId}
            jobnumberName={jobNumberName}
            setFieldingRequestId={setFieldingRequestId}
          />
        }
      />
      <h3>Cut Pole<X onClick={props.handleCancel} /></h3>
      <br />
      <form onSubmit={(handleSave)}>
        <TextField
          id="select"
          className="inp"
          label="Project"
          variant="outlined"
          size="small"
          select
          value={projectId}
          onChange={(e) => handleChooseProject(e.target.value)}
        >
          {listProject.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>{item.projectNumber}</MenuItem>
          ))}
        </TextField>
        <br />
        <TextField
          id="select"
          className="inp"
          label="Job Number"
          variant="outlined"
          size="small"
          select
          value={jobNumberId}
          onChange={(e) => handleChooseJobnumber(e.target.value)}
        >
          {listJobNumber.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
          ))}
        </TextField>
        <br />
        <TextField
          id="select"
          className="inp"
          label="Fielding Request"
          variant="outlined"
          size="small"
          select
          value={fieldingRequestId}
          onChange={(e) => setFieldingRequestId(e.target.value)}
        >
          <MenuItem >
            <Button onClick={() => setModalAddFielding(true)} startIcon={<Add />}>
              Add Fielding
            </Button>
          </MenuItem>
          {fieldingRequestList.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
          ))}
        </TextField>
        <br />
        <div className="wrapbutton">
          <Button type="submit" className="save" variant="outlined" onClick={handleSave}>
            <Save /> Save
          </Button>
        </div>
      </form>
    </div >
  )
}
