import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Info, Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import ModalTemplate from "../../../../shared/ModalTemplate";
import Roles from "../../../../shared/Roles";
import { setAerialFielding } from "../../../assignFielding/assignFielding.reducer";
import { setCenter } from "../../../dashboard/dashboard.reducer";
import { setListMarkerFieldingRequest, setListMeasureMarker, setListMidspanFieldingRequest, setListPoleFieldingRequest } from "../../../poleInformation/poleInformation.reducer";
import { handleFormType, handleJobNumberIdActive, handleModalProject, handleProjectIdActive, setDetailMenu, setFieldingIdList, setJobDetail, setJobIdList, setNoteList, setSidetabActive, setStatusJobNumber } from "../../sideTab.reducer";
import ModalForm from "./ModalForm";

const Project = () => {
    const dispatch = useDispatch()

    const { listProject, projectIdActive, modalProject, detailMenu, sideTabActive } = useSelector((state: RootState) => state.sideTab);

    const [pidList, setPidList] = useState<any>([])
    const [listProjectFiltered, setListProjectFiltered] = useState<any>([])

    useEffect(() => {
        if (listProject.length && !detailMenu.id) {
            if (!Boolean(projectIdActive.id)) {
                handleProject(listProject[0])
            }
        }
        initSearch()
    }, [listProject])

    const initSearch = () => {
        let pidListNew = listProject.map((d: any) => d.pid)
        let pidListUnique: any = [];
        pidListNew.forEach((element: any) => {
            if (!pidListUnique.includes(element) && element !== null) {
                pidListUnique.push(element);
            }
        });
        setPidList(pidListUnique);
        setListProjectFiltered(listProject)
    }

    const handleClickNewProject = () => {
        if (localStorage.getItem("companyIdSelected") === "null") {
            Swal.fire({
                icon: "warning",
                title: "Select your company",
                timer: 1500,
            })
        }
        else {
            dispatch(handleModalProject());
            dispatch(handleFormType("new"));
        }
    };

    const searchCity = async (city: string) => {
        const geocoder = new google.maps.Geocoder()
        let response = await geocoder.geocode({ address: city })
        console.log(response)
        let lat = response.results[0].geometry.location.lat();
        let lng = response.results[0].geometry.location.lng();
        dispatch(setCenter({ latitude: lat, longitude: lng }))
    }

    const handleProject = (data: any) => {
        dispatch(setStatusJobNumber(0));
        dispatch(setJobDetail(null));
        dispatch(setDetailMenu({}))
        dispatch(setAerialFielding({}))
        dispatch(setListPoleFieldingRequest([]))
        dispatch(setListMarkerFieldingRequest([]))
        dispatch(setListMidspanFieldingRequest([]))
        dispatch(setListMeasureMarker([]))
        dispatch(setJobIdList([]))
        dispatch(setFieldingIdList([]))
        dispatch(setNoteList([]))
        if (data.id === projectIdActive.id) {
            dispatch(handleProjectIdActive({ id: "" }));
            dispatch(handleJobNumberIdActive({}));
        } else {
            dispatch(setSidetabActive({ ...sideTabActive, jobNumber: true }))
            dispatch(handleProjectIdActive(data));
            dispatch(handleJobNumberIdActive({}));
            if (data.projectLocation) {
                if (data.projectLocation.includes(',')) {
                    let latlong = data.projectLocation ? data.projectLocation.split(',') : []
                    let lat = parseFloat(latlong[1])
                    let lng = parseFloat(latlong[0])
                    dispatch(setCenter({ latitude: lat, longitude: lng }))
                }
                else {
                    searchCity(data.projectLocation)
                }
            }
        }
    };

    let clickModalProject = () => {
        dispatch(handleModalProject());
        dispatch(handleFormType("info"));
    };

    const handleChangePid = (e: any, value: any) => {
        let newList = []
        if (value.length === 0) {
            newList = listProject
        }
        else {
            newList = listProject.filter((d: any) => value.map((e: any) => e === "null" ? null : e).includes(d.pid))
        }
        setListProjectFiltered(newList)
        if (!newList.includes(projectIdActive)) {
            dispatch(setStatusJobNumber(0));
            dispatch(setJobDetail(null));
            dispatch(setDetailMenu({}))
            dispatch(setAerialFielding({}))
            dispatch(setListPoleFieldingRequest([]))
            dispatch(setJobIdList([]))
            dispatch(setFieldingIdList([]))
            dispatch(setNoteList([]))
            dispatch(handleProjectIdActive({ id: "" }));
            dispatch(handleJobNumberIdActive({}));
        }
    }

    return (
        <>
            <ModalTemplate component={() => <ModalForm onClose={() => dispatch(handleModalProject())} />} onOpen={modalProject} />
            <div className="wraptab">
                {Roles("project_add") && (
                    <Button onClick={handleClickNewProject}>
                        <Plus /> PROJECT
                    </Button>
                )}
                <Autocomplete
                    options={pidList}
                    onChange={handleChangePid}
                    multiple
                    renderInput={(params) => <TextField {...params} label="Search by PID" size="small" placeholder="Search by PID" variant="outlined" />}
                />
                <br />
                <ul>
                    {listProjectFiltered?.map((res: any, i: number) => (
                        <li key={i} className={res.id === projectIdActive.id ? "listActive" : ""}>
                            <span onClick={() => handleProject(res)}>
                                {res.projectNumber}
                                {res.isSharedProject && (<small>*shared</small>)}
                            </span>
                            {Roles("project_edit") && (
                                res.id === projectIdActive.id ? (
                                    // <div className="wrapshare">
                                    //     <div className="sharebut" onClick={clickModalProject}>
                                    <Info onClick={clickModalProject} />
                                    //     </div>
                                    // </div>
                                ) : null
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Project