
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../state'

export const getAllAssignedJob = createAsyncThunk('assign/list', async (status: any) => {
    try {
        let params = {
            statusValue: status,
            userId: localStorage.getItem('id')
        }
        if (status === -1) delete params.statusValue
        const response = await axios.get(`${baseUrl}/api/JobNumber/GetAllAssignedJob`, {
            params: params
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getAllAssignedJobMapView = createAsyncThunk('assign/listMap', async () => {
    try {
        let params = {
            userId: localStorage.getItem('id')
        }
        const response = await axios.get(`${baseUrl}/api/JobNumber/GetAllAssignedJobMapView`, {
            params: params
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getFielders = createAsyncThunk('assign/getFielder', async (id: any) => {
    try {
        let params = {
            companyId: id
        }
        const response = await axios.get(`${baseUrl}/api/Account/GetFielders`, {
            params: params
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const postFielders = createAsyncThunk('assign/postFielder', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/FieldingRequest/AssignFieldingRequests`, body)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const moveAttachment = createAsyncThunk('assign/moveAttachment', async (body: any) => {
    try {
        const resFielding = await axios.post(`${baseUrl}​/api/FieldingRequest/MoveFieldingRequestAttachmentToPole`, body)
        return resFielding.data
    }
    catch (error: any) {
        console.log(error)
        return error.response
    }
})

export const getFieldingRequestDetail = createAsyncThunk('getFieldingRequestDetail', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/FieldingRequest/GetDetails`, {
            params: {
                fieldingRequestId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})