import { createSlice } from '@reduxjs/toolkit'
import { getTrackerFieldingRequest } from './fieldingRequest.api';

export interface FieldingRequestState {
  value: number,
  data : any,
  loading : boolean
}
const initialState: FieldingRequestState = {
  value: 0,
  data : [],
  loading : false
}

export const counterSlice = createSlice({
    name: 'fieldingRequest',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
      //get list
      builder.addCase(getTrackerFieldingRequest.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getTrackerFieldingRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      });
      builder.addCase(getTrackerFieldingRequest.rejected, (state, action) => {
        state.loading = false;
      });
    },
})

export default counterSlice.reducer