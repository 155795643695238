import { Button, Checkbox, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ChevronRight, ExpandMore, Restore } from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import moment from "moment";
import { useEffect, useState } from "react";
import { Trash2, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import ModalTemplate from "../../../../shared/ModalTemplate";
import { setActiveMultiplePole } from "../../../dashboard/dashboard.api";
import { deleteMarkerFielding, deletePoleFieldingRequest, getPoleFieldingRequestInactive, getTempDeleteMarkerFielding, undoDeleteMarkerFielding, getMeasureMarkerInactive, deleteMeasureMarker, undoDeleteTempMeasureMarker, getMidSpanFieldingRequestInactive, deleteMultipleMeasureMarker, undoDeleteMultipleMeasureMarker } from "../../../poleInformation/poleInformation.api";
import { deleteFieldingRequest, deleteNoteMarker, deleteUndoNoteMarker, fieldingrequestUndoDelete, getFieldingRequest, getFieldingRequestDeleted, getFieldingRequestIncludeDeleted, getNoteListByJobNumberId, getNoteListDeleted } from "../../sideTab.api";
import { setNoteList, setNoteListDeleted } from "../../sideTab.reducer";
import DeleteditemsMap from "./DeletedItemsMap";

export default function DeletedItems(props: any) {

    const dispatch = useDispatch()

    const { jobNumberIdActive, noteList, noteListDeleted, listFieldingRequestDeleted, listFieldingRequestIncludeDeleted, statusFieldingRequest } = useSelector((state: RootState) => state.sideTab)

    const [fieldingRequestPoleList, setFieldingRequestPoleList] = useState<any>({})
    const [fieldingRequestMidspanList, setFieldingRequestMidspanList] = useState<any>({})
    const [fieldingRequestMarkerList, setFieldingRequestMarkerList] = useState<any>({})
    const [measureMarkerList, setMeasureMarkerList] = useState<any>({})
    const [poleSelected, setPoleSelected] = useState<any>([])
    const [midspanSelected, setMidspanSelected] = useState<any>([])
    const [markerSelected, setMarkerSelected] = useState<any>([])
    const [measureMarkerSelected, setMeasureMarkerSelected] = useState<any>([])
    const [modalMaps, setModalMaps] = useState(false)

    useEffect(() => {
        dispatch(getFieldingRequestIncludeDeleted(jobNumberIdActive.id))
        dispatch(getFieldingRequestDeleted(jobNumberIdActive.id))
        dispatch<any>(getNoteListDeleted(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
                dispatch(setNoteListDeleted(res.data))
            })
    }, [])

    let parseDate = (d: any) => {
        let date = moment(new Date(d)).format("MM-DD-yyyy hh:mm:ss");
        return date
    }

    const handleRestore = (id: string) => {
        dispatch<any>(deleteUndoNoteMarker(id))
            .unwrap()
            .then((res: any) => {
                dispatch<any>(getNoteListByJobNumberId(jobNumberIdActive.id))
                    .unwrap()
                    .then((res: any) => {
                        let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
                        dispatch(setNoteList(noteListNew.concat(res.data)))
                    })
                dispatch<any>(getNoteListDeleted(jobNumberIdActive.id))
                    .unwrap()
                    .then((res: any) => {
                        let noteListDeletedNew: any = noteListDeleted?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
                        dispatch(setNoteListDeleted(noteListDeletedNew.concat(res.data)))
                    })
            })
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'are you sure you want to delete this data?',
            text: 'this action cannot be undone',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch<any>(deleteNoteMarker(id))
                    .unwrap()
                    .then((res: any) => {
                        dispatch<any>(getNoteListDeleted(jobNumberIdActive.id))
                            .unwrap()
                            .then((res: any) => {
                                let noteListDeletedNew: any = noteListDeleted?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
                                dispatch(setNoteListDeleted(noteListDeletedNew.concat(res.data)))
                            })
                    })
            }
        })
    }

    const handleExpandFieldingRequest = (fieldingRequestId: string) => {
        let fieldingRequestIdGetted = Object.keys(fieldingRequestMarkerList)
        if (!fieldingRequestIdGetted.includes(fieldingRequestId)) {
            getFieldingRequestMarkerChild(fieldingRequestId)
            getFieldingRequestMidspanChild(fieldingRequestId)
            getFieldingRequestPoleChild(fieldingRequestId)
            getMeasureMarkerChild(fieldingRequestId)
        }
    }

    const getFieldingRequestPoleChild = (fieldingRequestId: string) => {
        dispatch<any>(getPoleFieldingRequestInactive(fieldingRequestId))
            .unwrap()
            .then((res: any) => {
                setFieldingRequestPoleList({
                    ...fieldingRequestPoleList,
                    [fieldingRequestId]: res.data
                })
            })
    }
    const handleRestoreFieldingRequestPole = (id: string, fieldingRequestId: string) => {
        dispatch<any>(setActiveMultiplePole({
            poleIds: [id],
            isActive: true,
            userId: localStorage.getItem('id')
        }))
            .unwrap()
            .then(() => {
                getFieldingRequestPoleChild(fieldingRequestId)
            })
    }
    const handleDeleteFieldingRequestPole = (id: string, fieldingRequestId: string) => {
        dispatch<any>(deletePoleFieldingRequest([id]))
            .unwrap()
            .then((res: any) => {
                getFieldingRequestPoleChild(fieldingRequestId)
            })
    }

    const getFieldingRequestMidspanChild = (fieldingRequestId: string) => {
        dispatch<any>(getMidSpanFieldingRequestInactive(fieldingRequestId))
            .unwrap()
            .then((res: any) => {
                setFieldingRequestMidspanList({
                    ...fieldingRequestMidspanList,
                    [fieldingRequestId]: res.data
                })
            })
    }
    const handleRestoreFieldingRequestMidspan = (id: string, fieldingRequestId: string) => {
        // dispatch<any>(setActiveMultipleMidspan({
        //     MidspanIds: [id],
        //     isActive: true,
        //     userId: localStorage.getItem('id')
        // }))
        //     .unwrap()
        //     .then(() => {
        //         getFieldingRequestMidspanChild(fieldingRequestId)
        //     })
    }
    const handleDeleteFieldingRequestMidspan = (id: string, fieldingRequestId: string) => {
        // dispatch<any>(deleteMidspanFieldingRequest([id]))
        //     .unwrap()
        //     .then((res: any) => {
        //         getFieldingRequestMidspanChild(fieldingRequestId)
        //     })
    }

    const getFieldingRequestMarkerChild = (fieldingRequestId: string) => {
        dispatch<any>(getTempDeleteMarkerFielding(fieldingRequestId))
            .unwrap()
            .then((res: any) => {
                setFieldingRequestMarkerList({
                    ...fieldingRequestMarkerList,
                    [fieldingRequestId]: res.data
                })
            })
    }
    const handleRestoreFieldingRequestMarker = (id: string, fieldingRequestId: string) => {
        dispatch<any>(undoDeleteMarkerFielding([id]))
            .unwrap()
            .then(() => {
                getFieldingRequestMarkerChild(fieldingRequestId)
            })
    }
    const handleDeleteFieldingRequestMarker = (id: string, fieldingRequestId: string) => {
        dispatch<any>(deleteMarkerFielding([id]))
            .unwrap()
            .then((res: any) => {
                getFieldingRequestMarkerChild(fieldingRequestId)
            })
    }

    const getMeasureMarkerChild = (measureId: string) => {
        dispatch<any>(getMeasureMarkerInactive(measureId))
            .unwrap()
            .then((res: any) => {
                setMeasureMarkerList({
                    ...measureMarkerList,
                    [measureId]: res.data
                })
            })
    }
    const handleRestoreMeasureMarker = (id: string, measureId: string) => {
        dispatch<any>(undoDeleteTempMeasureMarker(id))
            .unwrap()
            .then(() => {
                getMeasureMarkerChild(measureId)
            })
    }
    const handleDeleteMeasureMarker = (id: string, measureId: string) => {
        dispatch<any>(deleteMeasureMarker(id))
            .unwrap()
            .then((res: any) => {
                getMeasureMarkerChild(measureId)
            })
    }

    const handleChangePoleCheck = (e: any, id: string) => {
        if (e.target.checked) {
            setPoleSelected([...poleSelected, id])
        }
        else {
            setPoleSelected(poleSelected.filter((d: string) => d !== id))
        }
    }

    const handleChangeMidspanCheck = (e: any, id: string) => {
        if (e.target.checked) {
            setMidspanSelected([...poleSelected, id])
        }
        else {
            setMidspanSelected(poleSelected.filter((d: string) => d !== id))
        }
    }

    const handleChangeMarkerCheck = (e: any, id: string) => {
        if (e.target.checked) {
            setMarkerSelected([...markerSelected, id])
        }
        else {
            setMarkerSelected(markerSelected.filter((d: string) => d !== id))
        }
    }

    const handleChangeMeasureMarkerCheck = (e: any, id: string) => {
        if (e.target.checked) {
            setMeasureMarkerSelected([...measureMarkerSelected, id])
        }
        else {
            setMeasureMarkerSelected(measureMarkerSelected.filter((d: string) => d !== id))
        }
    }

    const handleRestoreAll = async () => {
        await dispatch<any>(setActiveMultiplePole({ poleIds: poleSelected, isActive: true, userId: localStorage.getItem('id') }))
        await dispatch<any>(undoDeleteMarkerFielding(markerSelected))
        await dispatch<any>(undoDeleteMultipleMeasureMarker(measureMarkerSelected))
        dispatch(getFieldingRequestIncludeDeleted(jobNumberIdActive.id))
        setFieldingRequestPoleList({})
        setFieldingRequestMarkerList({})
        setPoleSelected([])
        setMarkerSelected([])
        setMeasureMarkerSelected([])
    }

    const handleCleanAll = async () => {
        await dispatch<any>(deletePoleFieldingRequest(poleSelected))
        await dispatch<any>(deleteMarkerFielding(markerSelected))
        await dispatch<any>(deleteMultipleMeasureMarker(measureMarkerSelected))
        dispatch(getFieldingRequestIncludeDeleted(jobNumberIdActive.id))
        setFieldingRequestPoleList({})
        setFieldingRequestMarkerList({})
        setPoleSelected([])
        setMarkerSelected([])
        setMeasureMarkerSelected([])
    }

    const handleRestoreFieldingRequest = (id: string) => {
        dispatch<any>(fieldingrequestUndoDelete(id))
            .unwrap()
            .then(() => {
                dispatch(getFieldingRequestDeleted(jobNumberIdActive.id))
                dispatch<any>(getFieldingRequest({
                    jobnumberId: jobNumberIdActive.id,
                    status: statusFieldingRequest
                }))
            })
    }
    const handleDeleteFieldingRequest = (id: string) => {
        dispatch<any>(deleteFieldingRequest(id))
            .unwrap()
            .then((res: any) => {
                dispatch(getFieldingRequestDeleted(jobNumberIdActive.id))
            })
    }

    return (
        <div className="modalsketchlib">
            <h3>
                Deleted Items
                <X onClick={props.onClose} />
            </h3>
            <div className="note-list-container">
                <Typography align="right">
                    <Button color="primary" variant="contained" size="small" onClick={() => setModalMaps(true)}>View On Map</Button>
                    <ModalTemplate component={() => <DeleteditemsMap onClose={() => setModalMaps(false)} notes={noteListDeleted} />} onOpen={modalMaps} />
                </Typography>
                <br />
                <h4>Pole</h4>
                {(poleSelected.length > 0 || markerSelected.length > 0 || measureMarkerSelected.length > 0) && (
                    <div className="align-right">
                        <Tooltip title="Delete permanently">
                            <IconButton onClick={handleCleanAll} >
                                <Trash2 color="red" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Restore">
                            <IconButton onClick={handleRestoreAll}>
                                <Restore />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />} >
                    {listFieldingRequestIncludeDeleted?.map((fieldingRequest: any) => (
                        <TreeItem
                            key={fieldingRequest.id}
                            nodeId={String(fieldingRequest.id)}
                            label={fieldingRequest.name}
                            onClick={() => handleExpandFieldingRequest(fieldingRequest.id)}
                        >
                            <Checkbox
                                checked={(
                                    (fieldingRequestPoleList[fieldingRequest.id] ? fieldingRequestPoleList[fieldingRequest.id].every((d: any) => poleSelected.includes(d.id)) : true) &&
                                    (fieldingRequestMarkerList[fieldingRequest.id] ? fieldingRequestMarkerList[fieldingRequest.id].every((d: any) => markerSelected.includes(d.id)) : true)
                                )}
                                onClick={(e: any) => {
                                    if (e.target.checked) {
                                        setPoleSelected(fieldingRequestPoleList[fieldingRequest.id].map((d: any) => d.id))
                                        setMarkerSelected(fieldingRequestMarkerList[fieldingRequest.id].map((d: any) => d.id))
                                    }
                                    else {
                                        setPoleSelected(poleSelected.filter((d: any) => fieldingRequestPoleList[fieldingRequest.id].map((e: any) => e.id).includes(e)))
                                        setMarkerSelected(markerSelected.filter((d: any) => fieldingRequestMarkerList[fieldingRequest.id].map((e: any) => e.id).includes(e)))
                                    }
                                }}
                            />
                            <div className="note-list">
                                {fieldingRequestPoleList[fieldingRequest.id] && (
                                    fieldingRequestPoleList[fieldingRequest.id]?.map((pole: any, index: number) =>
                                        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                                            <Checkbox className="checkbox" checked={poleSelected.includes(pole.id)} onClick={e => handleChangePoleCheck(e, pole.id)} />
                                            <div className="note-container" key={index} >
                                                <span className="note-header">
                                                    pole seq {pole.poleSequence}
                                                    <div>
                                                        <Tooltip title="Restore">
                                                            <IconButton onClick={() => handleRestoreFieldingRequestPole(pole.id, fieldingRequest.id)}>
                                                                <Restore />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete permanently">
                                                            <IconButton onClick={() => handleDeleteFieldingRequestPole(pole.id, fieldingRequest.id)}>
                                                                <Trash2 color="red" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </span>
                                                <span className="note-content">{pole.fieldingRequest}, last update by {pole.lastModifiedBy}</span>
                                            </div>
                                        </div>
                                    )
                                )}
                                {fieldingRequestMidspanList[fieldingRequest.id] && (
                                    fieldingRequestMidspanList[fieldingRequest.id]?.map((midspan: any, index: number) =>
                                        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                                            <Checkbox className="checkbox" checked={midspanSelected.includes(midspan.id)} onClick={e => handleChangeMidspanCheck(e, midspan.id)} />
                                            <div className="note-container" key={index} >
                                                <span className="note-header">
                                                    midspan seq {midspan.midspanSequence}
                                                    <div>
                                                        <Tooltip title="Restore">
                                                            <IconButton onClick={() => handleRestoreFieldingRequestMidspan(midspan.id, fieldingRequest.id)}>
                                                                <Restore />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete permanently">
                                                            <IconButton onClick={() => handleDeleteFieldingRequestMidspan(midspan.id, fieldingRequest.id)}>
                                                                <Trash2 color="red" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </span>
                                                <span className="note-content">{midspan.fieldingRequest}, last update by {midspan.lastModifiedBy}</span>
                                            </div>
                                        </div>
                                    )
                                )}
                                {fieldingRequestMarkerList[fieldingRequest.id] && (
                                    fieldingRequestMarkerList[fieldingRequest.id]?.map((marker: any, index: number) =>
                                        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                                            <Checkbox className="checkbox" checked={markerSelected.includes(marker.id)} onClick={e => handleChangeMarkerCheck(e, marker.id)} />
                                            <div className="note-container" key={index} >
                                                <span className="note-header">
                                                    {marker.type === 0 ? "Path" : marker.type === 1 ? "Area" : ""} {marker.subject}
                                                    <div>
                                                        <Tooltip title="Restore">
                                                            <IconButton onClick={() => handleRestoreFieldingRequestMarker(marker.id, fieldingRequest.id)}>
                                                                <Restore />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete permanently">
                                                            <IconButton onClick={() => handleDeleteFieldingRequestMarker(marker.id, fieldingRequest.id)}>
                                                                <Trash2 color="red" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </span>
                                                <span className="note-content">{marker.notes}</span>
                                            </div>
                                        </div>
                                    )
                                )}
                                {measureMarkerList[fieldingRequest.id] && (
                                    measureMarkerList[fieldingRequest.id]?.map((marker: any, index: number) =>
                                        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                                            <Checkbox className="checkbox" checked={measureMarkerSelected.includes(marker.id)} onClick={e => handleChangeMeasureMarkerCheck(e, marker.id)} />
                                            <div className="note-container" key={index} >
                                                <span className="note-header">
                                                    Marker {marker.sequence}
                                                    <div>
                                                        <Tooltip title="Restore">
                                                            <IconButton onClick={() => handleRestoreMeasureMarker(marker.id, fieldingRequest.id)}>
                                                                <Restore />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete permanently">
                                                            <IconButton onClick={() => handleDeleteMeasureMarker(marker.id, fieldingRequest.id)}>
                                                                <Trash2 color="red" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </TreeItem>
                    ))}
                </TreeView>
                <div className="note-list">
                    <h4>Request Number</h4>
                    {listFieldingRequestDeleted?.map((fielding: any, index: number) =>
                        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                            <div className="note-container" key={index} >
                                <span className="note-header">
                                    {fielding.name}
                                    <div>
                                        <Tooltip title="Restore">
                                            <IconButton onClick={() => handleRestoreFieldingRequest(fielding.id)}>
                                                <Restore />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete permanently">
                                            <IconButton onClick={() => handleDeleteFieldingRequest(fielding.id)}>
                                                <Trash2 color="red" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="note-list">
                    <h4>Shape Notes</h4>
                    {noteListDeleted?.map((note: any, index: number) =>
                        (note.markerType === 2) && (
                            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                                <div className="note-container" key={index} >
                                    <span className="note-header">
                                        {note.name}
                                        <div>
                                            <Tooltip title="Restore">
                                                <IconButton onClick={() => handleRestore(note.id)}>
                                                    <Restore />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete permanently">
                                                <IconButton onClick={() => handleDelete(note.id)}>
                                                    <Trash2 color="red" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </span>
                                    <span className="note-content">created by {note.creator} at {parseDate(note.createdDate)} </span>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="note-list">
                    <h4>Line Notes</h4>
                    {noteListDeleted?.map((note: any, index: number) =>
                        (note.markerType === 3) && (
                            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                                <div className="note-container" key={index} >
                                    <span className="note-header">
                                        {note.name}
                                        <div>
                                            <Tooltip title="Restore">
                                                <IconButton onClick={() => handleRestore(note.id)}>
                                                    <Restore />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete permanently">
                                                <IconButton onClick={() => handleDelete(note.id)}>
                                                    <Trash2 color="red" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </span>
                                    <span className="note-content">created by {note.creator} at {parseDate(note.createdDate)} </span>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="note-list">
                    <h4>Point Notes</h4>
                    {noteListDeleted?.map((note: any, index: number) =>
                        (note.markerType === 4) && (
                            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                                <div className="note-container" key={index} >
                                    <span className="note-header">
                                        {note.name}
                                        <div>
                                            <Tooltip title="Restore">
                                                <IconButton onClick={() => handleRestore(note.id)}>
                                                    <Restore />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete permanently">
                                                <IconButton onClick={() => handleDelete(note.id)}>
                                                    <Trash2 color="red" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </span>
                                    <span className="note-content">created by {note.creator} at {parseDate(note.createdDate)} </span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}