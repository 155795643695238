import { configureStore } from '@reduxjs/toolkit'
import accountReducer from '../features/tracker/account/account.reducer'
import assignFieldingReducer from '../features/assignFielding/assignFielding.reducer'
import companyReducer from '../features/tracker/company/company.reducer'
import dashboardReducer from '../features/dashboard/dashboard.reducer'
import loginReducer from '../features/login/login.reducer'
import poleloadingReducer from '../features/tracker/poleLoading/poleloading.reducer'
import jpaReducer from '../features/tracker/jpa/jpa.reducer'
import mreReducer from '../features/tracker/mre/mre.reducer'
import tcpReducer from '../features/tracker/tcp/tcp.reducer'
import fieldingRequestReducer from '../features/tracker/fieldingRequest/fieldingRequest.reducer'
import poleInformationReducer from '../features/poleInformation/poleInformation.reducer'
import profileReducer from '../features/profile/profile.reducer'
import sideTabReducer from '../features/sideTab/sideTab.reducer'
import { loadState, saveState } from './localStorage'
import throttle from 'lodash.throttle';

const persistedState = loadState();

const store = configureStore({
  reducer: {
    login: loginReducer,
    poleloading: poleloadingReducer,
    jpa: jpaReducer,
    mre: mreReducer,
    tcp: tcpReducer,
    fieldingRequest: fieldingRequestReducer,
    sideTab: sideTabReducer,
    dashboard: dashboardReducer,
    poleInformation: poleInformationReducer,
    account: accountReducer,
    company: companyReducer,
    assignFielding: assignFieldingReducer,
    profile: profileReducer
  },
  preloadedState: persistedState
})

export type RootState = ReturnType<typeof store.getState>

store.subscribe(throttle(() => {
  saveState({
    login: store.getState().login,
    poleloading: store.getState().poleloading,
    jpa: store.getState().jpa,
    mre: store.getState().mre,
    tcp: store.getState().tcp,
    fieldingRequest: store.getState().fieldingRequest,
    sideTab: store.getState().sideTab,
    dashboard: store.getState().dashboard,
    poleInformation: store.getState().poleInformation,
    account: store.getState().account,
    company: store.getState().company,
    assignFielding: store.getState().assignFielding,
    profile: store.getState().profile,
  });
}, 1000));

export default store