import { createSlice } from '@reduxjs/toolkit'
import { copyPole, deleteMultiplePole, editColorMultiplePole, editPoleLocation, getNotificationList, movePole, setActiveMultiplePole, setMarkerRead } from './dashboard.api'

export interface Dashboard {
  leftButton: boolean,
  leftButtonShow: boolean,
  rightButton: boolean,
  noteShape: any,
  noteShapeModal: any,
  notePath: any,
  notePathModal: any
  notePoint: any,
  notePointModal: any
  noteSymbolModal: any
  loadingDashboard: boolean,
  center: any,
  zoom: any,
  noteToShow: any,
  selectedIdMarkers: any
  isDragSelectActive: boolean,
  searchResult: any,
  notificationList: any,
  measureMarkerData: any
}

const initialState: Dashboard = {
  leftButton: true,
  leftButtonShow: true,
  rightButton: false,
  noteShape: [],
  noteShapeModal: {},
  notePath: [],
  notePathModal: {},
  notePoint: {},
  notePointModal: {},
  noteSymbolModal: {},
  loadingDashboard: false,
  center: { latitude: 34.0633584, longitude: -118.3595673 },
  zoom: 10,
  noteToShow: [0, 1, 2, 3, 4],
  selectedIdMarkers: [],
  isDragSelectActive: false,
  searchResult: {},
  notificationList: [],
  measureMarkerData: {}
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    handleLeftButton: (state) => {
      state.leftButton = !state.leftButton
      state.rightButton = false
    },
    setLeftButton: (state, action) => {
      state.leftButton = action.payload
    },
    setLeftButtonShow: (state, action) => {
      state.leftButtonShow = action.payload
    },
    handleRightButton: (state) => {
      state.rightButton = !state.rightButton
      state.leftButton = false
    },
    showLeftTab: (state) => {
      state.leftButton = true
    },
    setNoteShape: (state, action) => {
      state.noteShape = action.payload
    },
    setNoteShapeModal: (state, action) => {
      state.noteShapeModal = action.payload
    },
    setNotePoint: (state, action) => {
      state.notePoint = action.payload
    },
    setNotePointModal: (state, action) => {
      state.notePointModal = action.payload
    },
    setNotePath: (state, action) => {
      state.notePath = action.payload
    },
    setNotePathModal: (state, action) => {
      state.notePathModal = action.payload
    },
    setNoteSymbolModal: (state, action) => {
      state.noteSymbolModal = action.payload
    },
    setCenter: (state, action) => {
      if (action.payload.latitude) {
        state.center = action.payload
      }
    },
    setZoom: (state, action) => {
      state.zoom = action.payload
    },
    setNoteToShow: (state, action) => {
      state.noteToShow = action.payload
    },
    setSelectedIdMarkers: (state, action) => {
      console.log(action.payload)
      state.selectedIdMarkers = action.payload
    },
    setIsDragSelectActive: (state, action) => {
      state.isDragSelectActive = action.payload
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload
    },
    setMeasureMarkerData: (state, action) => {
      state.measureMarkerData = action.payload
    }
  },
  extraReducers: (builder) => {
    //edit multiple pole
    builder.addCase(editColorMultiplePole.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(editColorMultiplePole.fulfilled, (state, action) => {
      state.loadingDashboard = false;
    });
    builder.addCase(editColorMultiplePole.rejected, (state, action) => {
      state.loadingDashboard = false;
    });
    //delete multiple pole
    builder.addCase(deleteMultiplePole.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(deleteMultiplePole.fulfilled, (state, action) => {
      state.loadingDashboard = false;
    });
    builder.addCase(deleteMultiplePole.rejected, (state, action) => {
      state.loadingDashboard = false;
    });
    //edit pole location
    builder.addCase(editPoleLocation.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(editPoleLocation.fulfilled, (state, action) => {
      state.loadingDashboard = false;
    });
    builder.addCase(editPoleLocation.rejected, (state, action) => {
      state.loadingDashboard = false;
    });

    builder.addCase(setActiveMultiplePole.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(setActiveMultiplePole.fulfilled, (state, action) => {
      state.loadingDashboard = false;
    });
    builder.addCase(setActiveMultiplePole.rejected, (state, action) => {
      state.loadingDashboard = false;
    });

    builder.addCase(movePole.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(movePole.fulfilled, (state, action) => {
      state.loadingDashboard = false;
    });
    builder.addCase(movePole.rejected, (state, action) => {
      state.loadingDashboard = false;
    });

    builder.addCase(copyPole.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(copyPole.fulfilled, (state, action) => {
      state.loadingDashboard = false;
    });
    builder.addCase(copyPole.rejected, (state, action) => {
      state.loadingDashboard = false;
    });

    builder.addCase(getNotificationList.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(getNotificationList.fulfilled, (state, action) => {
      state.loadingDashboard = false;
      state.notificationList = action.payload.data;
    });
    builder.addCase(getNotificationList.rejected, (state, action) => {
      state.loadingDashboard = false;
    });

    builder.addCase(setMarkerRead.pending, (state, action) => {
      state.loadingDashboard = true;
    });
    builder.addCase(setMarkerRead.fulfilled, (state, action) => {
      state.loadingDashboard = false;
    });
    builder.addCase(setMarkerRead.rejected, (state, action) => {
      state.loadingDashboard = false;
    });
  }
})

export const {
  handleLeftButton,
  setLeftButton,
  setLeftButtonShow,
  handleRightButton,
  showLeftTab,
  setNoteShape,
  setNoteShapeModal,
  setNotePath,
  setNotePathModal,
  setNotePoint,
  setNotePointModal,
  setNoteSymbolModal,
  setCenter,
  setZoom,
  setNoteToShow,
  setSelectedIdMarkers,
  setIsDragSelectActive,
  setSearchResult,
  setMeasureMarkerData
} = dashboardSlice.actions


export default dashboardSlice.reducer