import { Button, Card, CardContent, CardHeader, IconButton, Modal, TextField, Typography } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { X, ChevronLeft, ChevronRight, Edit, Image, Download, Move } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getPoleDetails, getPoleFieldingRequest, getPoleImageLogs, movePoleAttachment, setImageAttachmentAsMain } from "../poleInformation.api";
import { handleModalImage } from "../poleInformation.reducer";
import Swal from "sweetalert2";
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageModalEditor from "./ImageModalEditor";
import axios from "axios";
import { baseUrl } from "../../../state";
import { Autocomplete } from "@material-ui/lab";

function ImageModal(props: any) {

    const dispatch = useDispatch()

    const { poleDetails, imageModalInitId, poleImageLogs } = useSelector((state: RootState) => state.poleInformation)

    const [images, setImages] = useState<any>([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [editorModalStatus, setEditorModalStatus] = useState(false);
    const [downloadImages, setDownloadImages] = useState<any>({
        isDownloading: false,
        progressValue: 70
    })
    const [modalMove, setModalMove] = useState(false)
    const [poleList, setPoleList] = useState<any>([])
    const [poleIdSelected, setPoleIdSelected] = useState("")

    useEffect(() => {
        setImages(poleDetails?.imageList)
        dispatch(getPoleImageLogs(poleDetails.id))
    }, []);

    useEffect(() => {
        poleDetails?.imageList.map((image: any, index: number) => {
            if (image.id === imageModalInitId) {
                setImageIndex(index)
            }
        })
    }, [imageModalInitId])

    useEffect(() => {
        if (modalMove) {
            getPoleList()
        }
    }, [modalMove])

    const timeOffsetUpload = [
        {
            offset: 0,
            text: "-",
        },
        {
            offset: 240,
            text: "Eastern Daylight Time (EDT)",
        },
        {
            offset: 300,
            text: "Eastern Standard Time (EST)",
        },
        {
            offset: 480,
            text: "Pacific Standard Time (PST)",
        },
        {
            offset: 420,
            text: "Pacific Daylight Time (PDT)",
        },
        {
            offset: 300,
            text: "Central Daylight Time (CDT)",
        },
        {
            offset: 360,
            text: "Central Standard Time (CST)",
        },
        {
            offset: 360,
            text: "Mountain Daylight Time (MDT)",
        },
        {
            offset: 420,
            text: "Mountain Standard Time (MST)",
        },
        {
            offset: 600,
            text: "Hawaii Standard Time (HWT)",
        },
        {
            offset: 540,
            text: "Hawaii-Aleutian Daylight Time (HADT)",
        }
    ]

    const handleSetAsMain = async () => {
        await dispatch(setImageAttachmentAsMain(images[imageIndex]?.id))
        await dispatch(getPoleDetails(poleDetails?.id))
        Swal.fire({
            icon: "success",
            title: "Photo set as main successfully",
            showConfirmButton: false,
            timer: 1500,
        });
    }

    const handleDownload = (item: any) => {
        setDownloadImages({
            isDownloading: true,
            progressValue: 0
        })
        axios({
            url: item.filePath.includes('.com') ? item.filePath : baseUrl + "/api/Global/Download?path=" + item.filePath,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setDownloadImages({
                    isDownloading: true,
                    progressValue: percentCompleted
                })
                console.log(percentCompleted)
            }
        })
            .then(response => {
                let x = item.filePath.split('.')
                let extension = x[x.length - 1]
                const downloadLink = document.createElement('a')
                downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
                downloadLink.setAttribute('download', item.fileName.includes('.') ? item.fileName : item.fileName + '.' + extension)
                document.body.appendChild(downloadLink)
                downloadLink.click()
                downloadLink.remove()
                setDownloadImages({
                    isDownloading: false,
                    progressValue: 0
                })
            })
    }

    const handleEdit = () => {
        setEditorModalStatus(!editorModalStatus)
        if (editorModalStatus) {
            setImages(poleDetails?.imageList)
        }
    }

    const getPoleList = async () => {
        await dispatch<any>(getPoleFieldingRequest(poleDetails.fieldingRequestId))
            .unwrap()
            .then((response: any) => {
                setPoleList(response.data)
            })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        let data = {
            poleAttachmentId: images[imageIndex]?.id,
            targetPoleId: poleIdSelected,
            userId: localStorage.getItem("id"),
        }
        dispatch<any>(movePoleAttachment(data))
            .unwrap()
            .then((res: any) => {
                if (res.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "attachment successfully moved",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                        .then(() => {
                            dispatch<any>(getPoleDetails(poleDetails?.id))
                                .unwrap()
                                .then(() => {
                                    setImages(images.filter((a: any, i: number) => i !== imageIndex))
                                    if (imageIndex === images.length - 1) {
                                        setImageIndex(imageIndex - 1)
                                    }
                                    setModalMove(false)
                                    if (images.length === 1) {
                                        dispatch(handleModalImage(""))
                                    }
                                })
                        })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "error move attachment",
                        text: res.statusText,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err: any) => {
                Swal.fire({
                    icon: "error",
                    title: "error move attachment",
                    text: err,
                    showConfirmButton: true,
                });
            })
    }

    return (
        <>
            <Modal className="image-editor-modal" open={editorModalStatus} disableEnforceFocus={true}>
                <ImageModalEditor file={images[imageIndex]} handleCancel={handleEdit} />
            </Modal>
            <Modal className="image-editor-modal" open={modalMove}>
                <Card>
                    <CardHeader title="Move Attachment" />
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Autocomplete
                                options={
                                    poleList
                                        .sort((a: any, b: any) => {
                                            if (a.poleSequence < b.poleSequence) {
                                                return -1;
                                            }
                                            if (a.poleSequence > b.poleSequence) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .map((pole: any) => { return { id: pole.id, poleSequence: pole.poleSequence, poleNumber: pole.poleNumber } })
                                }
                                getOptionLabel={(pole: any) => {
                                    return `seq:${pole.poleSequence || ""},${pole.poleNumber || ""}`
                                }}
                                onChange={(e, value) => setPoleIdSelected(value.id)}
                                renderInput={(params) => <TextField {...params} margin="dense" fullWidth label="Target Pole" />}
                            />
                            <br />
                            <br />
                            <div className="wrapbutton">
                                <Button type="button" className="cancel" variant="outlined" onClick={() => setModalMove(false)}>
                                    <X /> Cancel
                                </Button>
                                <Button type="submit" className="save" variant="outlined">
                                    <Move /> Move
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Modal>
            <div className='modalsketchlib image-modal'>
                <h3>
                    Image
                    <X onClick={() => dispatch(handleModalImage(""))} />
                </h3>
                <div className="box">
                    <div className="slider">
                        <IconButton size='small'>
                            <ChevronLeft size='30' cursor='pointer' onClick={() => imageIndex > 0 && setImageIndex(imageIndex - 1)} />
                        </IconButton>
                        <img src={images[imageIndex]?.filePath?.includes(".com") ? images[imageIndex]?.filePath + '?width=500' : (baseUrl + "/" + images[imageIndex]?.filePath + '?width=500')} alt="image" />
                        <IconButton size='small'>
                            <ChevronRight size='30' cursor='pointer' onClick={() => imageIndex < images.length - 1 && setImageIndex(imageIndex + 1)} />
                        </IconButton>
                    </div>
                    <br />
                    <div className="footer">
                        <div></div>
                        <div className="option">
                            <Button variant='outlined' size='small' startIcon={<Move />} onClick={() => setModalMove(true)}>Move to another pole</Button>
                            <Button variant='outlined' size='small' startIcon={<Image />} onClick={handleSetAsMain}>Set As Main</Button>
                            <Button variant='outlined' size='small' startIcon={<Edit />} onClick={handleEdit}>Edit</Button>
                            <Button variant='outlined' size='small' startIcon={<Download />} onClick={() => handleDownload(images[imageIndex])} disabled={downloadImages.isDownloading}>
                                {downloadImages.isDownloading
                                    ? "Downloading " + downloadImages.progressValue + "%"
                                    : "Download"
                                }
                            </Button>
                        </div>
                    </div>
                    <br />
                    <Typography variant="h6">Image Info</Typography>
                    <Typography className="bordered-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Timezone Job Location</td>
                                    <td>{timeOffsetUpload.filter((time: any) => time.offset === (images[imageIndex]?.selectedUploadTimeOffset || 0))[0]?.text}</td>
                                </tr>
                                <tr>
                                    <td>Filename</td>
                                    <td>{images[imageIndex]?.fileName}</td>
                                </tr>
                                <tr>
                                    <td>Image Date Taken Local</td>
                                    <td>{images[imageIndex]?.detectedImageTakenDate}</td>
                                </tr>
                                <tr>
                                    <td>Image Date Taken Server Time</td>
                                    <td>{images[imageIndex]?.convertedDetectedImageTakenDate}</td>
                                </tr>
                                <tr>
                                    <td>Upload Time ( Server Time (UTC-7) )</td>
                                    <td>{images[imageIndex]?.uploadDateString}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {images[imageIndex]?.isOfficeUpload === true
                                            ? "Uploaded With Web"
                                            : images[imageIndex]?.isOfficeUpload === false
                                                ? "Uploaded With Fielder App"
                                                : "-"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Typography>
                    <br />
                    <Typography variant="h6">Pole Fielding Image Timer Logs :</Typography>
                    {poleImageLogs.map((log: any, i: number) =>
                        <Typography className="list-item" key={i}>
                            <span className="number">{i + 1}.</span>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Start ( Server Time (UTC-7) ) </td>
                                        <td>:</td>
                                        <td>{log.startDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Stop ( Server Time (UTC-7) ) </td>
                                        <td>:</td>
                                        <td>{log.endDate}</td>
                                    </tr>
                                    <tr>
                                        <td>By {log.createdBy}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Typography>
                    )}
                </div>
            </div>
        </>
    );
}

export default ImageModal;