import { Container, Grid } from "@material-ui/core";
import DashboardMap from "../components/DashboardMap";
import SideTab from "../../sideTab/container/SideTab";
import Template from "../../../shared/Template";
import LayerActive from "../components/LayerActive";
import { RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import PoleInformation from "../../poleInformation/container/PoleInformation";
import LoadingCustom from "../../../shared/LoadingCustom";
import { useEffect, useState } from "react";
import { setLeftButton } from "../dashboard.reducer";

const Dashboard = () => {

  const dispatch = useDispatch()

  const { leftButton, rightButton, loadingDashboard } = useSelector((state: RootState) => state.dashboard);
  const { loading } = useSelector((state: RootState) => state.sideTab);
  const { loadingPole } = useSelector((state: RootState) => state.poleInformation);
  const loadingAssign = useSelector((state: RootState) => state.assignFielding).loading

  const [confirmClose, setConfirmClose] = useState(false)

  useEffect(() => {
    setConfirmClose(!rightButton)
  }, [rightButton])

  useEffect(() => {
    if (rightButton === false) {
      dispatch(setLeftButton(true))
    }
  }, [rightButton])

  let component = () => (
    <>
      <LayerActive />
      <LoadingCustom isLoading={loading || loadingPole || loadingDashboard || loadingAssign} />
      <Container maxWidth={false}>
        <div style={leftButton ? {} : { marginLeft: "-100vw" }}>
          <SideTab />
        </div>
        <Grid container>
          <Grid item sm={rightButton ? 6 : 12}>
            <DashboardMap />
          </Grid>
          {(rightButton || !confirmClose) ? (
            <Grid item sm={6}>
              <PoleInformation />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </>
  );
  return <Template component={component} />;
};

export default Dashboard;
