
import { GoogleMap, Marker, Polyline, useLoadScript } from '@react-google-maps/api'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

import white_blue_pole from "../../../../assets/icons/white_blue_pole.png";
import midspan from "../../../../assets/icons/midspan.png";
import pole_color_red from "../../../../assets/icons/pole_color_red.png";
import pole_color_blue from "../../../../assets/icons/pole_color_blue.png";
import pole_color_white from "../../../../assets/icons/pole_color_white.png";
import pole_color_green from "../../../../assets/icons/pole_color_green.png";
import pole_color_black from "../../../../assets/icons/pole_color_black.png";
import pole_color_yellow from "../../../../assets/icons/pole_color_yellow.png";
import pole_color_purple from "../../../../assets/icons/pole_color_purple.png";
import pole_color_orange from "../../../../assets/icons/pole_color_orange.png";
import pole_color_gray from "../../../../assets/icons/pole_color_gray.png";
import pole_color_brown from "../../../../assets/icons/pole_color_brown.png";
import target from "../../../../assets/icons/target.png";

const mapStyles = {
    height: "500px",
    width: "100%",
}
function SpanMapSmall() {

    const { listPoleFieldingRequest, poleDetails, midspanDetail, listMidspanFieldingRequest } = useSelector((state: RootState) => state.poleInformation);
    const { spanMapLines } = useSelector((state: RootState) => state.poleInformation)

    const [coor, setCoor] = useState<any>({})
    const [selected, setSelected] = useState<any>({})

    useEffect(() => {
        setCoor(midspanDetail.id ? midspanDetail : poleDetails)
        setSelected({})
    }, [poleDetails, midspanDetail])

    const center = {
        lat: coor.latitude ? parseFloat(coor.latitude) : 0,
        lng: coor.longitude ? parseFloat(coor.longitude) : 0
    }

    const handleSelected = (line: any) => {
        if (JSON.stringify(selected) !== JSON.stringify(line)) {
            setSelected(line)
        }
        else {
            setSelected({})
        }
    }

    const colorList = [white_blue_pole, pole_color_red, pole_color_blue, pole_color_white, pole_color_green, pole_color_black, pole_color_yellow, pole_color_purple, pole_color_orange, pole_color_gray, pole_color_brown]

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs",
        libraries: ['places'],
    });
    if (!isLoaded) return <div>Loading...</div>;
    return (
        <div className="minimap-container">
            <div className="minimap">
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={20}
                    tilt={0}
                    center={center}
                    mapTypeId="terrain"
                    clickableIcons={false}
                    options={{
                        disableDefaultUI: true,
                        draggable: false
                    }}
                >
                    {
                        listPoleFieldingRequest.map((res: any, i: number) =>
                            <Marker
                                key={i}
                                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                                clickable={false}
                                icon={colorList[res.color]}
                            />
                        )
                    }

                    {
                        listMidspanFieldingRequest.map((res: any, i: number) =>
                            <Marker
                                key={i}
                                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                                clickable={false}
                                icon={midspan}
                            />
                        )
                    }

                    <Marker
                        position={{ lat: parseFloat(coor.latitude ? coor.latitude : 0), lng: parseFloat(coor.longitude ? coor.longitude : 0) }}
                        clickable={false}
                        icon={target}
                    />

                    {
                        spanMapLines.map((res: any, i: any) => (
                            <Polyline
                                key={i}
                                onClick={() => handleSelected(res)}
                                path={res.lineData}
                                options={{
                                    strokeColor: res.color,
                                    strokeOpacity: 0.8,
                                    strokeWeight: 7,
                                    //@ts-ignore
                                    fillColor: '#FF0000',
                                    fillOpacity: 0.35,
                                    clickable: true,
                                    draggable: false,
                                    editable: false,
                                    visible: true,
                                    radius: 30000,
                                    paths: res.data,
                                    zIndex: 1
                                }}
                            />
                        ))
                    }

                </GoogleMap>
            </div>
            <div className="minimap-info">
                {selected.length &&
                    <table>
                        <tr>
                            <td>Length</td>
                            <td>:</td>
                            <td>{selected.length}</td>
                        </tr>
                    </table>
                }
            </div>
        </div>
    );
}

export default SpanMapSmall;