import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { getPoleFieldingRequest, postPoleFieldingRequest, getPoleDetails, setImageAttachmentAsMain, deletePoleAttachment, editPoleFieldingRequest, getPoleFieldingRequestByJobNumber, getPoleInformationEnums, getPoleLoadingRequest, generateAllImages, deletePoleFieldingRequest, postMidspanFieldingRequest, deleteMidspanFieldingRequest, getMidSpanFieldingRequest, editMidspanFieldingRequest, getMidspanFieldingRequestByJobNumber, getPoleFieldingRequestHistory, postMarkerFielding, editMarkerFielding, getMarkerFielding, deleteMarkerFielding, getPoleImageLogs, movePoleAttachment, getMarkerFieldingRequestByJobNumber, getPolePplx, getPoleInventory, getMidspanDetails, createMeasureMarker, getMeasureMarker, deleteMeasureMarker, editMeasureMarker, createCommentMeasureMarker, getCommentMeasureMarker, moveMeasureMarker, getMeasureMarkerDetails, deleteMeasureMarkerAttachment, getMidspanImageLogs, generateMidspanAllImages, generateMeasureMarkerImages, getMidspanHistory, getMeasureMarkerByJob, deleteTempMarkerFielding, getTempDeleteMarkerFielding, undoDeleteMarkerFielding, deleteTempMeasureMarker, getMeasureMarkerImageLogs, getMeasureMarkerInactive, getPoleFieldingRequestInactive, undoDeleteTempMeasureMarker, getMeasureMarkerByJobInactive, getMarkerFieldingByJobnumberId, getMarkerFieldingByJobnumberIdInactive, getPoleFieldingRequestByJobNumberInactive, getMidspanFieldingRequestByJobNumberInactive, deleteTempMidspanFieldingRequest, getMidSpanFieldingRequestInactive, deleteMultipleMeasureMarker, undoDeleteMultipleMeasureMarker } from "./poleInformation.api";

export interface PoleState {
  modalFormHOA: boolean;
  modalFormTransformer: boolean;
  isEditPoleInfo: boolean;
  isModalSpanDirection: boolean;
  isAnchorModal: boolean;
  isImageModal: boolean;
  isHistoryModal: boolean;
  isSpanAndDirectionModal: boolean;
  imageModalInitId: string;
  isModalRiserVgr: boolean;
  // spanData : any,
  anchorAddType: string;
  anchorCanvasDotes: any;
  anchorCanvasStreets: any;
  anchorCanvasFences: any;
  riserAddType: string;
  riserCanvasDotes: any;
  riserCanvasDotesActive: any;
  riserCanvasFences: any;
  spanMapLines: any;
  loadingPole: boolean;
  poleFieldingRequest: any;
  listPoleFieldingRequest: any;
  listMarkerFieldingRequest: any;
  listPoleLoadingRequest: any;
  listPoleInventory: any;
  listPolePplx: any;
  listMeasureMarker: any;
  isShowPoleFieldingRequest: boolean;
  isShowPoleLoadingRequest: boolean;
  poleDetails: any;
  poleImageLogs: any;
  hoaData: any;
  transformersData: any;
  poleEnums: any;
  listMidspanFieldingRequest: any;
  midspanDetail: any;
  midspanImageLogs: any;
  poleType: string;
  poleOcalcId: string;
  measureMarkerImageLogs: any;
}

const initialState: PoleState = {
  modalFormHOA: false,
  modalFormTransformer: false,
  isEditPoleInfo: false,
  isModalSpanDirection: false,
  isAnchorModal: false,
  isImageModal: false,
  isHistoryModal: false,
  isSpanAndDirectionModal: false,
  imageModalInitId: "",
  isModalRiserVgr: false,
  // spanData : [],
  anchorAddType: "",
  anchorCanvasDotes: [],
  anchorCanvasStreets: [],
  anchorCanvasFences: [],
  riserAddType: "",
  riserCanvasDotes: [],
  riserCanvasDotesActive: [],
  riserCanvasFences: [],
  spanMapLines: [],
  loadingPole: false,
  poleFieldingRequest: {},
  listPoleFieldingRequest: [],
  listMarkerFieldingRequest: [],
  listPoleLoadingRequest: [],
  listPoleInventory: [],
  listPolePplx: [],
  listMeasureMarker: [],
  isShowPoleFieldingRequest: true,
  isShowPoleLoadingRequest: false,
  poleDetails: {},
  poleImageLogs: [],
  hoaData: [],
  transformersData: [],
  poleEnums: {},
  listMidspanFieldingRequest: [],
  midspanDetail: {},
  midspanImageLogs: [],
  poleType: "",
  poleOcalcId: "",
  measureMarkerImageLogs: []
};

export const poleInformationSlice = createSlice({
  name: "poleInformation",
  initialState,
  reducers: {
    handleModalHOA: (state) => {
      state.modalFormHOA = !state.modalFormHOA;
    },
    handleModalTransformer: (state) => {
      state.modalFormTransformer = !state.modalFormTransformer;
    },
    handleEditPoleInfo: (state) => {
      state.isEditPoleInfo = !state.isEditPoleInfo;
    },
    setEditPoleInfo: (state, action: PayloadAction<boolean>) => {
      state.isEditPoleInfo = action.payload;
    },
    // handleModalSpanDirection : (state) => {
    //     state.isModalSpanDirection =  !state.isModalSpanDirection
    // },
    handleModalAnchor: (state) => {
      state.isAnchorModal = !state.isAnchorModal;
    },
    handleModalImage: (state, action: PayloadAction<string>) => {
      state.isImageModal = !state.isImageModal;
      state.imageModalInitId = action.payload
    },
    handleModalHistory: (state) => {
      state.isHistoryModal = !state.isHistoryModal;
    },
    handleModalSpanAndDirection: (state) => {
      state.isSpanAndDirectionModal = !state.isSpanAndDirectionModal;
    },
    handleModalRiserVgr: (state) => {
      state.isModalRiserVgr = !state.isModalRiserVgr;
    },
    // handleSpanData : (state, action: PayloadAction<any>) => {
    //   state.spanData =  action.payload
    // },
    // Anchor Canvas
    handleAnchorAddType: (state, action: PayloadAction<string>) => {
      state.anchorAddType = action.payload;
    },
    handleAnchorCanvasFences: (state, action: PayloadAction<any>) => {
      state.anchorCanvasFences = action.payload;
    },
    handleAnchorCanvasStreets: (state, action: PayloadAction<any>) => {
      state.anchorCanvasStreets = action.payload;
    },
    handleAnchorCanvasDotes: (state, action: PayloadAction<any>) => {
      state.anchorCanvasDotes = action.payload;
    },
    //RiserVGR Canvas
    handleRiserAddType: (state, action: PayloadAction<string>) => {
      state.riserAddType = action.payload;
    },
    handleRiserCanvasDotes: (state, action: PayloadAction<any>) => {
      state.riserCanvasDotes = action.payload;
    },
    handleRiserCanvasDotesActive: (state, action: PayloadAction<any>) => {
      state.riserCanvasDotesActive = action.payload;
    },
    handleRiserCanvasFences: (state, action: PayloadAction<any>) => {
      state.riserCanvasFences = action.payload;
    },
    handleSpanMapLines: (state, action: PayloadAction<any>) => {
      state.spanMapLines = action.payload;
    },
    setPoleFieldingRequest: (state, action: PayloadAction<any>) => {
      state.poleFieldingRequest = action.payload;
    },
    setListPoleFieldingRequest: (state, action) => {
      state.listPoleFieldingRequest = action.payload;
    },
    setListMarkerFieldingRequest: (state, action) => {
      state.listMarkerFieldingRequest = action.payload;
    },
    setListPoleLoadingRequest: (state, action) => {
      state.listPoleLoadingRequest = action.payload;
    },
    setListPoleInventory: (state, action) => {
      state.listPoleInventory = action.payload;
    },
    setListPolePplx: (state, action) => {
      state.listPolePplx = action.payload;
    },
    setListMeasureMarker: (state, action) => {
      state.listMeasureMarker = action.payload;
    },
    setIsShowPoleFieldingRequest: (state, action) => {
      state.isShowPoleFieldingRequest = action.payload;
    },
    setIsShowPoleLoadingRequest: (state, action) => {
      state.isShowPoleLoadingRequest = action.payload;
    },
    setHoaData: (state, action) => {
      state.hoaData = action.payload;
    },
    deleteHoaData: (state, action) => {
      let array = [...state.hoaData];
      console.log(action.payload);
      array.splice(action.payload, 1);
      console.log(array);
      state.hoaData = array;
    },
    setTransformersData: (state, action) => {
      state.transformersData = action.payload;
    },
    deleteTransformersData: (state, action) => {
      let array = [...state.transformersData];
      console.log(action.payload);
      array.splice(action.payload, 1);
      console.log(array);
      state.transformersData = array;
    },
    setListMidspanFieldingRequest: (state, action) => {
      state.listMidspanFieldingRequest = action.payload;
    },
    setMidspanDetail: (state, action) => {
      state.midspanDetail = action.payload;
    },
    setPoleType: (state, action) => {
      state.poleType = action.payload;
    },
    clearPoleDetails: (state) => {
      state.poleDetails = {};
    },
    setPoleOcalcId: (state, action) => {
      state.poleOcalcId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loadingPole state as needed
    builder.addCase(getPoleFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPoleFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //
    builder.addCase(getPoleFieldingRequestInactive.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleFieldingRequestInactive.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPoleFieldingRequestInactive.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get history
    builder.addCase(getPoleFieldingRequestHistory.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleFieldingRequestHistory.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPoleFieldingRequestHistory.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // delete pole
    builder.addCase(deletePoleFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deletePoleFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deletePoleFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get pole loading list
    builder.addCase(getPoleLoadingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleLoadingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPoleLoadingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get pole loading pplx
    builder.addCase(getPolePplx.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPolePplx.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPolePplx.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get pole inventory
    builder.addCase(getPoleInventory.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleInventory.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPoleInventory.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get pole enums
    builder.addCase(getPoleInformationEnums.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleInformationEnums.fulfilled, (state, action) => {
      state.loadingPole = false;
      state.poleEnums = action.payload.data;
    });
    builder.addCase(getPoleInformationEnums.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // Add reducers for additional action types here, and handle loadingPole state as needed
    builder.addCase(getPoleFieldingRequestByJobNumber.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleFieldingRequestByJobNumber.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPoleFieldingRequestByJobNumber.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // Add reducers for additional action types here, and handle loadingPole state as needed
    builder.addCase(getPoleFieldingRequestByJobNumberInactive.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleFieldingRequestByJobNumberInactive.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getPoleFieldingRequestByJobNumberInactive.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // Add reducers for additional action types here, and handle loadingPole state as needed
    builder.addCase(getMidspanFieldingRequestByJobNumber.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMidspanFieldingRequestByJobNumber.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMidspanFieldingRequestByJobNumber.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // Add reducers for additional action types here, and handle loadingPole state as needed
    builder.addCase(getMidspanFieldingRequestByJobNumberInactive.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMidspanFieldingRequestByJobNumberInactive.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMidspanFieldingRequestByJobNumberInactive.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // Add reducers for additional action types here, and handle loadingPole state as needed
    builder.addCase(getMarkerFieldingRequestByJobNumber.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMarkerFieldingRequestByJobNumber.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMarkerFieldingRequestByJobNumber.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get pole details
    builder.addCase(getPoleDetails.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleDetails.fulfilled, (state, action) => {
      state.loadingPole = false;
      state.poleDetails = action.payload.data;
    });
    builder.addCase(getPoleDetails.rejected, (state, action) => {
      state.loadingPole = false;
      Swal.fire({
        title: "error",
        icon: "error",
        text: "Error getting data"
      })
    });

    builder.addCase(getPoleImageLogs.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getPoleImageLogs.fulfilled, (state, action) => {
      state.loadingPole = false;
      state.poleImageLogs = action.payload.data;
    });
    builder.addCase(getPoleImageLogs.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //post
    builder.addCase(postPoleFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(postPoleFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(postPoleFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //post
    builder.addCase(editPoleFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(editPoleFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(editPoleFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //SetImageAttachmentAsMain
    builder.addCase(setImageAttachmentAsMain.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(setImageAttachmentAsMain.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(setImageAttachmentAsMain.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //
    builder.addCase(movePoleAttachment.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(movePoleAttachment.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(movePoleAttachment.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //deletePoleAttachment
    builder.addCase(deletePoleAttachment.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deletePoleAttachment.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deletePoleAttachment.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //
    builder.addCase(generateAllImages.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(generateAllImages.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(generateAllImages.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // //delete

    // builder.addCase(deleteCompany.pending, (state, action) => {
    //   state.loading = true
    // })
    // builder.addCase(deleteCompany.fulfilled, (state, action) => {
    //     state.loading = false
    // })
    // builder.addCase(deleteCompany.rejected, (state, action) => {
    //     state.loading = false
    // })
    // get midspan
    builder.addCase(getMidSpanFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMidSpanFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMidSpanFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get midspan
    builder.addCase(getMidSpanFieldingRequestInactive.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMidSpanFieldingRequestInactive.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMidSpanFieldingRequestInactive.rejected, (state, action) => {
      state.loadingPole = false;
    });
    //post midspan
    builder.addCase(postMidspanFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(postMidspanFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(postMidspanFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // delete midspan
    builder.addCase(deleteMidspanFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteMidspanFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteMidspanFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // delete midspan
    builder.addCase(deleteTempMidspanFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteTempMidspanFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteTempMidspanFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // edit midspan
    builder.addCase(editMidspanFieldingRequest.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(editMidspanFieldingRequest.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(editMidspanFieldingRequest.rejected, (state, action) => {
      state.loadingPole = false;
    });
    // get midspan details
    builder.addCase(getMidspanDetails.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMidspanDetails.fulfilled, (state, action) => {
      state.loadingPole = false;
      state.midspanDetail = action.payload.data;
    });
    builder.addCase(getMidspanDetails.rejected, (state, action) => {
      state.loadingPole = false;
      Swal.fire({
        title: "error",
        icon: "error",
        text: "Error getting data"
      })
    });
    builder.addCase(getMidspanImageLogs.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMidspanImageLogs.fulfilled, (state, action) => {
      state.loadingPole = false;
      state.midspanImageLogs = action.payload.data;
    });
    builder.addCase(getMidspanImageLogs.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(generateMidspanAllImages.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(generateMidspanAllImages.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(generateMidspanAllImages.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMidspanHistory.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMidspanHistory.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMidspanHistory.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(postMarkerFielding.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(postMarkerFielding.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(postMarkerFielding.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(editMarkerFielding.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(editMarkerFielding.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(editMarkerFielding.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMarkerFielding.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMarkerFielding.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMarkerFielding.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(deleteMarkerFielding.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteMarkerFielding.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteMarkerFielding.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(deleteTempMarkerFielding.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteTempMarkerFielding.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteTempMarkerFielding.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getTempDeleteMarkerFielding.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getTempDeleteMarkerFielding.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getTempDeleteMarkerFielding.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(undoDeleteMarkerFielding.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(undoDeleteMarkerFielding.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(undoDeleteMarkerFielding.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(createMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(createMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(createMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMeasureMarkerInactive.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMeasureMarkerInactive.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMeasureMarkerInactive.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMeasureMarkerByJob.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMeasureMarkerByJob.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMeasureMarkerByJob.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMeasureMarkerByJobInactive.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMeasureMarkerByJobInactive.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMeasureMarkerByJobInactive.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMarkerFieldingByJobnumberId.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMarkerFieldingByJobnumberId.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMarkerFieldingByJobnumberId.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMarkerFieldingByJobnumberIdInactive.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMarkerFieldingByJobnumberIdInactive.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMarkerFieldingByJobnumberIdInactive.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(deleteMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(deleteTempMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteTempMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteTempMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(undoDeleteTempMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(undoDeleteTempMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(undoDeleteTempMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(editMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(editMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(editMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(createCommentMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(createCommentMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(createCommentMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getCommentMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getCommentMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getCommentMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(moveMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(moveMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(moveMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMeasureMarkerDetails.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMeasureMarkerDetails.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(getMeasureMarkerDetails.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(getMeasureMarkerImageLogs.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(getMeasureMarkerImageLogs.fulfilled, (state, action) => {
      state.loadingPole = false;
      state.measureMarkerImageLogs = action.payload.data;
    });
    builder.addCase(getMeasureMarkerImageLogs.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(deleteMeasureMarkerAttachment.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteMeasureMarkerAttachment.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteMeasureMarkerAttachment.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(generateMeasureMarkerImages.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(generateMeasureMarkerImages.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(generateMeasureMarkerImages.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(deleteMultipleMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(deleteMultipleMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(deleteMultipleMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });

    builder.addCase(undoDeleteMultipleMeasureMarker.pending, (state, action) => {
      state.loadingPole = true;
    });
    builder.addCase(undoDeleteMultipleMeasureMarker.fulfilled, (state, action) => {
      state.loadingPole = false;
    });
    builder.addCase(undoDeleteMultipleMeasureMarker.rejected, (state, action) => {
      state.loadingPole = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  handleModalHOA,
  handleModalTransformer,
  handleEditPoleInfo,
  setEditPoleInfo,
  // handleModalSpanDirection,
  handleModalAnchor,
  handleModalImage,
  handleModalHistory,
  handleModalSpanAndDirection,
  handleModalRiserVgr,
  // handleSpanData,
  handleSpanMapLines,

  handleAnchorAddType,
  handleAnchorCanvasFences,
  handleAnchorCanvasStreets,
  handleAnchorCanvasDotes,

  handleRiserAddType,
  handleRiserCanvasDotes,
  handleRiserCanvasDotesActive,
  handleRiserCanvasFences,
  setPoleFieldingRequest,
  setListPoleFieldingRequest,
  setListMarkerFieldingRequest,
  setListPoleLoadingRequest,
  setListPoleInventory,
  setListPolePplx,
  setListMeasureMarker,
  setIsShowPoleFieldingRequest,
  setIsShowPoleLoadingRequest,
  setHoaData,
  deleteHoaData,
  setTransformersData,
  setListMidspanFieldingRequest,
  deleteTransformersData,
  setMidspanDetail,
  setPoleType,
  clearPoleDetails,
  setPoleOcalcId
} = poleInformationSlice.actions;

export default poleInformationSlice.reducer;
