
function NavBottom(props: any) {
    return (
        <div className='navbar'>
            <div className="botnav">
            <div className="title">2021 - Utility Fielding</div>
                <ul className="menus">
                    <li className='botmenu'><a href="/disclaimer" target='__blank'>Disclaimer</a></li>
                    <li>|</li>
                    <li className='botmenu'><a href="/privacy-policy" target='__blank'>Privacy Policy</a></li>
                    <li>|</li>
                    <li className='botmenu'><a href="/eula" target='__blank'>EULA</a></li>
                    <li>|</li>
                    <li className='botmenu'><a href="/terms-of-use" target='__blank'>Terms of Use</a></li>
                </ul>
            </div>
        </div>
    );
}

export default NavBottom;