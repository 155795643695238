import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCompany, editCompany, getCompany, postCompany } from "./company.api";

export interface CompanyState {
  listCompany: any;
  company: any;
  loading: boolean;
}
const initialState: CompanyState = {
  listCompany: [],
  company: {},
  loading: false,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, payload) => {
      state.company = payload;
    },
    // increment: (state) => {
    // state.value += 1
    // },
    // decrement: (state) => {
    // state.value -= 1
    // },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    // state.value += action.payload
    // },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getCompany.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.listCompany = action.payload.data;
    });
    builder.addCase(getCompany.rejected, (state, action) => {
      state.loading = false;
    });
    //post
    builder.addCase(postCompany.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postCompany.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postCompany.rejected, (state, action) => {
      state.loading = false;
    });
    //edit
    builder.addCase(editCompany.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editCompany.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editCompany.rejected, (state, action) => {
      state.loading = false;
    });
    //delete
    builder.addCase(deleteCompany.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteCompany.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
