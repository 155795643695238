import React, { Fragment, useEffect, useState } from 'react'
import ModalTemplate from '../../../shared/ModalTemplate'
import FormPole from '../component/FormPole'
// import ModalSpanDirection from '../component/ModalSpanDirection'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import AnchorModal from '../component/AnchorModal'
import ModalRiserVgr from '../component/ModalRiserVgr'
import ImageModal from '../component/ImageModal'
import SpanAndDirectionModal from '../component/SpanAndDirectionModal'
import FormMidspan from '../component/FormMidspan'
import HistoryModal from '../component/HistoryModal'
import FormMidspanImageModal from '../component/FormMidspanImageModal'
import FormMidspanHistoryModal from '../component/FormMidspanHistoryModal'

const PoleInformation = () => {
    const { isModalSpanDirection, isAnchorModal, isImageModal, isSpanAndDirectionModal, isHistoryModal, isModalRiserVgr, poleType } = useSelector((state: RootState) => state.poleInformation)

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        // fixing memory leak
        let isMounted = true;
        let i = setTimeout(() => {
            if (isMounted) {
                setLoaded(true)
                clearInterval(i)
            }
        }, 100)

        return () => {
            isMounted = false;
            clearInterval(i);
        }
    }, []);

    // useEffect(() => {
    //     let i = setTimeout(() => {
    //             setLoaded(true)
    //             clearInterval(i)
    //     }, 100)
    // }, [])

    return (
        <div>
            <div className='pole-information'>
                {/* <ModalTemplate onOpen={isModalSpanDirection} component={()=> <ModalSpanDirection  />}/> */}
                <ModalTemplate onOpen={isAnchorModal} component={() => <AnchorModal />} />
                <ModalTemplate onOpen={isModalRiserVgr} component={() => <ModalRiserVgr />} />
                <ModalTemplate onOpen={isSpanAndDirectionModal} component={() => <SpanAndDirectionModal />} />
                {poleType === "POLE" || poleType === "LOADING" || poleType === "INVENTORY"
                    ? (
                        <Fragment>
                            <ModalTemplate onOpen={isImageModal} component={() => <ImageModal />} />
                            <ModalTemplate onOpen={isHistoryModal} component={() => <HistoryModal />} />
                            <FormPole />
                        </Fragment>
                    )
                    : poleType === "MIDSPAN"
                        ? (
                            <Fragment>
                                <ModalTemplate onOpen={isImageModal} component={() => <FormMidspanImageModal />} />
                                <ModalTemplate onOpen={isHistoryModal} component={() => <FormMidspanHistoryModal />} />
                                <FormMidspan />
                            </Fragment>
                        )
                        : loaded && (
                            <Fragment>
                                <ModalTemplate onOpen={isImageModal} component={() => <ImageModal />} />
                                <ModalTemplate onOpen={isHistoryModal} component={() => <HistoryModal />} />
                                <FormPole />
                            </Fragment>
                        )
                }
            </div>
        </div>
    )
}

export default PoleInformation
