import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../../state'

export const getPoleLoadingType = createAsyncThunk('/Pole/GetPoleLoadingType', async () => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/api/Pole/GetPoleLoadingType',
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getPoleLoading = createAsyncThunk('api/Ocalc/GetTrackerPoleLoadingList', async (params: any) => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/api/Ocalc/GetTrackerPoleLoadingList',
            params: params
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const postPoleLoadingSupportingLink = createAsyncThunk('postPoleLoadingSupportingLink', async (body: any) => {
    try {
        const resAccount = await axios.post(`${baseUrl}/api/Ocalc/SaveSupportingLinkAttachments`, body)
        return resAccount.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postPoleLoadingResultLink = createAsyncThunk('postPoleLoadingResultLink', async (body: any) => {
    try {
        const resAccount = await axios.post(`${baseUrl}/api/Ocalc/SaveResultLinkAttachments`, body)
        return resAccount.data
    }
    catch (error: any) {
        return error.response
    }
})

export const completeLoadingRequest = createAsyncThunk('completeLoadingRequest', async (id: any) => {
    try {
        const resAccount = await axios.put(`${baseUrl}/api/Ocalc/CompletePoleLoading?targetPoleLoadingId=${id}`)
        return resAccount.data
    }
    catch (error: any) {
        return error.response
    }
})