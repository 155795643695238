import { Button, InputAdornment, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { RootState } from "../../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { setTransformersData } from "../poleInformation.reducer";
import { CheckSquare, X } from "react-feather";

function FormTransformer(props: any) {
  const dispatch = useDispatch();

  const { transformersData } = useSelector((state: RootState) => state.poleInformation);

  const [value, setValue] = useState("");

  const handleSave = () => {
    dispatch(setTransformersData([...transformersData, { value: value }]));
    props.onClose();
  };

  return (
    <div className="formproject">
      <h3>Add Transformer Data</h3>
      <form>
        <TextField
          className="inp"
          name="name"
          variant="outlined"
          type="number"
          label="Value (KV)"
          // placeholder="Height of Attachment (KV)"
          size="small"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">KV</InputAdornment>,
          }}
        />
        <div className="wrapbutton">
          <Button type="button" className="cancel" variant="outlined" onClick={props.onClose}>
            <X /> Cancel
          </Button>
          <Button variant="outlined" className="done" onClick={handleSave}>
            <CheckSquare /> Done
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FormTransformer;
