
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../state'

export const postLogin = createAsyncThunk('account/login', async (body:any) => {
    try {
        const logindata = await axios.post(`${baseUrl}/api/Account/Login`, body)
        console.log(logindata, 'axiossss')
        return logindata.data
    } catch (error: any) {
        return error.response
    }
})
