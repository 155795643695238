import { createSlice } from '@reduxjs/toolkit'

export interface MreState {
  value: number,
  listMre : any,
  loading : boolean
}
const initialState: MreState = {
  value: 0,
  listMre : [],
  loading : false
}

export const counterSlice = createSlice({
    name: 'mre',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
    },
})

export default counterSlice.reducer