import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../state'

export const editColorMultiplePole = createAsyncThunk('editColorMultiplePole', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Pole/EditMultiPoleData`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const deleteMultiplePole = createAsyncThunk('deleteMultiplePole', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Pole/DeleteMultiPoleData`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const setActiveMultiplePole = createAsyncThunk('setActiveMultiplePole', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Pole/SetIsActiveMultiPoleData`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const movePole = createAsyncThunk('movePole', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Pole/MoveToAnotherFieldingRequest`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const copyPole = createAsyncThunk('copyPole', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Pole/CopyPole`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const editPoleLocation = createAsyncThunk('pole/editPoleLocation', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Pole/editPoleLocation`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getNotificationList = createAsyncThunk('pole/getNotificationList', async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/Marker/GetMarkerNotifications`, {
            params: {
                userId: localStorage.getItem("id")
            }
        })
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const setMarkerRead = createAsyncThunk('SetMarkerRead', async (id: string) => {
    try {
        const response = await axios.put(`${baseUrl}/api/Marker/SetMarkerRead?targetMarkerId=${id}&userId=${localStorage.getItem("id")}`)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})