import { Card, CardContent, CardHeader, IconButton } from "@material-ui/core";
import { GoogleMap, Marker, Polygon, Polyline } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import mergeImages from 'merge-images'
import pole_color_red from "../../../../assets/icons/pole_color_red.png";
import pole_color_blue from "../../../../assets/icons/pole_color_blue.png";
import pole_color_white from "../../../../assets/icons/pole_color_white.png";
import pole_color_green from "../../../../assets/icons/pole_color_green.png";
import pole_color_black from "../../../../assets/icons/pole_color_black.png";
import pole_color_yellow from "../../../../assets/icons/pole_color_yellow.png";
import pole_color_purple from "../../../../assets/icons/pole_color_purple.png";
import pole_color_orange from "../../../../assets/icons/pole_color_orange.png";
import pole_color_gray from "../../../../assets/icons/pole_color_gray.png";
import pole_color_brown from "../../../../assets/icons/pole_color_brown.png";
import pole_color_cyan from "../../../../assets/icons/pole_color_cyan.png";
import pole_type_full from "../../../../assets/icons/pole_type_full.png";
import pole_type_support from "../../../../assets/icons/pole_type_support.png";
import pole_type_reference from "../../../../assets/icons/pole_type_reference.png";
import pole_full from "../../../../assets/icons/pole_full.png";
import pole_unfill from "../../../../assets/icons/pole_unfill.png";
import pole_transparent from "../../../../assets/icons/pole_transparent.png";
import treeSmall from "../../../../assets/icons/treeSmall.png";
import anchorSmall from "../../../../assets/icons/anchorSmall.png";
import point_red from "../../../../assets/icons/point_red.png";
import point_blue from "../../../../assets/icons/point_blue.png";
import point_white from "../../../../assets/icons/point_white.png";
import point_green from "../../../../assets/icons/point_green.png";
import point_black from "../../../../assets/icons/point_black.png";
import point_yellow from "../../../../assets/icons/point_yellow.png";
import point_purple from "../../../../assets/icons/point_purple.png";
import point_orange from "../../../../assets/icons/point_orange.png";
import point_gray from "../../../../assets/icons/point_gray.png";
import point_brown from "../../../../assets/icons/point_brown.png";
import midspan from "../../../../assets/icons/midspan.png";
import measure_marker_start from "../../../../assets/icons/measure_marker_start.png";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getMarkerFieldingByJobnumberIdInactive, getMeasureMarkerByJobInactive, getMidspanFieldingRequestByJobNumberInactive, getPoleFieldingRequestByJobNumberInactive } from "../../../poleInformation/poleInformation.api";

export default function DeleteditemsMap(props: any) {

    const dispatch = useDispatch()

    const mapStyles = {
        height: "70vh",
        width: "80vw",
    }

    const pointNoteColor: any = {
        Red: point_red,
        Blue: point_blue,
        White: point_white,
        Green: point_green,
        Black: point_black,
        Yellow: point_yellow,
        Purple: point_purple,
        Orange: point_orange,
        Gray: point_gray,
        Brown: point_brown
    }

    const { noteEnums, jobNumberIdActive } = useSelector((state: RootState) => state.sideTab)

    const [icons, setIcons] = useState<any>([])
    const [poles, setPoles] = useState<any>([])
    const [midspans, setMidspans] = useState<any>([])
    const [fieldingRequestMarker, setFieldingRequestMarker] = useState<any>([])
    const [measureMarkers, setMeasureMarkers] = useState<any>([])

    useEffect(() => {
        generateIcon()
        getData()
    }, [])

    const getData = async () => {
        await dispatch<any>(getPoleFieldingRequestByJobNumberInactive(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
                setPoles(res.data)
            })
        await dispatch<any>(getMidspanFieldingRequestByJobNumberInactive(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
                setMidspans(res.data)
            })
        await dispatch<any>(getMarkerFieldingByJobnumberIdInactive(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
                setFieldingRequestMarker(res.data)
            })
        await dispatch<any>(getMeasureMarkerByJobInactive(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
                setMeasureMarkers(res.data)
            })
    }

    const generateIcon = async () => {
        const colorList = [pole_color_white, pole_color_red, pole_color_blue, pole_color_white, pole_color_green, pole_color_black, pole_color_yellow, pole_color_purple, pole_color_orange, pole_color_gray, pole_color_brown, pole_color_cyan]
        const fullList = [pole_transparent, pole_full]
        const unFillList = [pole_transparent, pole_unfill]
        const typeList = [pole_type_full, pole_type_support, pole_type_reference]
        let result: any = []
        for (let c = 0; c < colorList.length; c++) {
            result = [...result, []]
            for (let f = 0; f < fullList.length; f++) {
                result[c] = [...result[c], []]
                for (let u = 0; u < unFillList.length; u++) {
                    result[c][f] = [...result[c][f], []]
                    for (let t = 0; t < typeList.length; t++) {
                        let r = await new Promise(resolve => {
                            mergeImages([colorList[c], fullList[f], unFillList[u], typeList[t]])
                                .then((b64: any) => {
                                    resolve(b64)
                                })
                        })
                        result[c][f][u] = [...result[c][f][u], r]
                    }
                }
            }
        }
        setIcons(result)
    }

    const noteColorId2Name = (id: number) => {
        let markerColors = noteEnums.markerColors
        let markerColor = markerColors.filter((item: any) => { return item.value === id })
        return markerColor[0]?.display
    }

    return (
        <Card>
            <CardHeader
                action={
                    <IconButton onClick={props.onClose}>
                        <X />
                    </IconButton>
                }
                title="Deleted Item Maps"
            />
            <CardContent>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={20}
                    mapTypeId="terrain"
                    center={poles.length ? { lat: parseFloat(poles[0].latitude), lng: parseFloat(poles[0].longitude) } : { lat: -3.745, lng: -38.523 }}
                >
                    {poles.map((res: any, i: number) => {
                        if (icons.length === 0) {
                            return null
                        }
                        return (
                            <Marker
                                key={i}
                                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                                icon={icons[res.color >= 12 ? 0 : res.color][res.fieldingStatus === 2 ? 1 : 0][res.isMandatoryDataFilled === false && res.fieldingStatus === 2 ? 1 : 0][res.type]}
                                title={
                                    "seq: " + res?.poleSequence + "\n" +
                                    (res?.poleNumber || "")
                                }
                            />
                        )
                    })}
                    {midspans.map((res: any, i: number) => {
                        return (
                            <>
                                <Marker
                                    key={i}
                                    position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                                    icon={midspan}
                                    title={"M" + res?.sequence}
                                />
                                {
                                    (res.span !== '' ? JSON.parse(res?.span) : []).map((line: any, lineIndex: number) => {
                                        if (line.color !== 'string') {
                                            return (
                                                <Polyline
                                                    key={lineIndex}
                                                    path={typeof (line.lineData) === "string" ? JSON.parse(line.lineData) : line.lineData}
                                                    options={{
                                                        strokeColor: line.color,
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 7,
                                                        //@ts-ignore
                                                        fillColor: '#FF0000',
                                                        fillOpacity: 0.35,
                                                        clickable: true,
                                                        draggable: false,
                                                        editable: false,
                                                        visible: true,
                                                        radius: 30000,
                                                        paths: line.data,
                                                        zIndex: 1
                                                    }}
                                                />
                                            )
                                        }
                                    })
                                }
                            </>
                        );
                    })}
                    {measureMarkers.map((marker: any) => {
                        return (
                            <Marker
                                position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                                icon={measure_marker_start}
                                title={
                                    "seq: " + marker?.sequence
                                }
                            />
                        )
                    })}
                    {props.notes.map((note: any, i: number) => (
                        (note.markerType === 2
                            ? (
                                <Polygon
                                    key={i}
                                    path={JSON.parse(note.lineData)}
                                    options={{ strokeOpacity: 0.8, strokeColor: noteColorId2Name(note.markerColor), fillColor: noteColorId2Name(note.markerColor) }}
                                />
                            )
                            : null
                        )
                    ))}
                    {props.notes.map((note: any, i: number) => (
                        (note.markerType === 0
                            ? (
                                <Marker
                                    position={{ lat: parseFloat(note.latitude), lng: parseFloat(note.longitude) }}
                                    icon={anchorSmall}
                                />
                            )
                            : (note.markerType === 1
                                ? (
                                    <Marker
                                        position={{ lat: parseFloat(note.latitude), lng: parseFloat(note.longitude) }}
                                        icon={treeSmall}
                                    />
                                )
                                : (note.markerType === 3
                                    ? (
                                        <Polyline
                                            key={i}
                                            path={JSON.parse(note.lineData)}
                                            //@ts-ignore
                                            options={{ strokeOpacity: 0.8, strokeColor: noteColorId2Name(note.markerColor), fillColor: noteColorId2Name(note.markerColor) }}
                                        />
                                    )
                                    : (note.markerType === 4
                                        ? (
                                            <Marker
                                                position={{ lat: parseFloat(note.latitude), lng: parseFloat(note.longitude) }}
                                                icon={note.markerColor === 0 ? pointNoteColor.Red : pointNoteColor[noteColorId2Name(note.markerColor)]}
                                            />
                                        )
                                        : null
                                    )
                                )
                            )
                        )))}
                </GoogleMap>
            </CardContent>
        </Card>
    )
}