import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { handleModalHistory } from "../poleInformation.reducer";
import { getPoleDetails, getPoleFieldingRequestHistory, getPoleImageLogs } from "../poleInformation.api";
import { Typography } from "@material-ui/core";

function HistoryModal(props: any) {

    const dispatch = useDispatch()

    const { poleDetails, poleImageLogs } = useSelector((state: RootState) => state.poleInformation)

    const [data, setData] = useState([])

    useEffect(() => {
        dispatch(getPoleDetails(poleDetails.id))
        dispatch(getPoleImageLogs(poleDetails.id))
        dispatch<any>(getPoleFieldingRequestHistory(poleDetails.id))
            .unwrap()
            .then((response: any) => {
                setData(response.data)
            })
            .catch((error: any) => {
                console.log(error)
            })
    }, [])

    return (
        <div className='modalsketchlib image-modal'>
            <h3>
                History
                <X onClick={() => dispatch(handleModalHistory())} />
            </h3>
            <div className="box">
                <ul>
                    {data.map((d: any) => (
                        <li>
                            {d.userName} at {d.modifiedDate}
                        </li>
                    ))}
                </ul>
            </div>
            <h3>Pole Fielding Image Timer Logs :</h3>
            <div className="box">
                {poleImageLogs.map((log: any, i: number) =>
                    <Typography className="list-item" key={i}>
                        <span className="number">{i + 1}.</span>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Start ( Server Time (UTC-7) ) </td>
                                    <td>:</td>
                                    <td>{log.startDate}</td>
                                </tr>
                                <tr>
                                    <td>Stop ( Server Time (UTC-7) ) </td>
                                    <td>:</td>
                                    <td>{log.endDate}</td>
                                </tr>
                                <tr>
                                    <td>By {log.createdBy}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Typography>
                )}
            </div>
        </div>
    );
}

export default HistoryModal;