
import TopNav from './TopNav';
import BotNav from './BotNav';

function Template(props:any) {
    return (
        <div className='custom-template'>
                <TopNav />
                    {props.component()}
                <BotNav />
        </div>
    );
}

export default Template;