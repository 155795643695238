
import { ButtonGroup, Button, TextField, Grid } from '@material-ui/core';
// import Geocode from "react-geocode";
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox, InfoWindow, Polyline } from '@react-google-maps/api'
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { handleSpanMapLines } from '../../poleInformation.reducer';

import white_blue_pole from "../../../../assets/icons/white_blue_pole.png";
import midspan from "../../../../assets/icons/midspan.png";
import pole_color_red from "../../../../assets/icons/pole_color_red.png";
import pole_color_blue from "../../../../assets/icons/pole_color_blue.png";
import pole_color_white from "../../../../assets/icons/pole_color_white.png";
import pole_color_green from "../../../../assets/icons/pole_color_green.png";
import pole_color_black from "../../../../assets/icons/pole_color_black.png";
import pole_color_yellow from "../../../../assets/icons/pole_color_yellow.png";
import pole_color_purple from "../../../../assets/icons/pole_color_purple.png";
import pole_color_orange from "../../../../assets/icons/pole_color_orange.png";
import pole_color_gray from "../../../../assets/icons/pole_color_gray.png";
import pole_color_brown from "../../../../assets/icons/pole_color_brown.png";
import pole_color_cyan from "../../../../assets/icons/pole_color_cyan.png";
import target from "../../../../assets/icons/target.png";

// var apikey = process.env.REACT_APP_MAP_APIKEY_DEV
const apikey: string = 'AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs'
var randomColor = require('randomcolor'); // import the script

const mapStyles = {
    height: "50vh",
    width: "100%",
}
function SpanMap() {
    const dispatch = useDispatch()

    const { listPoleFieldingRequest, poleDetails, listMidspanFieldingRequest, midspanDetail } = useSelector((state: RootState) => state.poleInformation);
    const { spanMapLines } = useSelector((state: RootState) => state.poleInformation)

    const [coor, setCoor] = useState<any>({})
    const [pathPoly, setPathPoly] = useState<any>({
        length: 0,
        lineData: [],
        color: "",
        image: null,
        imageType: 0
    })
    const [mode, setMode] = useState('')
    const [popText, setPopText] = useState('')
    const [infoCoor, setInfoCoor] = useState<any>(null)
    const [length, setlength] = useState(0)

    useEffect(() => {
        setCoor(midspanDetail.id ? midspanDetail : poleDetails)
    }, [poleDetails, midspanDetail])

    const handleClick = (coord: any) => {
        if (mode === 'polyline') {
            setPathPoly({
                ...pathPoly,
                lineData: [...pathPoly.lineData, { "lat": coord.latLng.lat(), "lng": coord.latLng.lng() }]
            })
        }
    }

    const center = {
        lat: coor.latitude ? parseFloat(coor.latitude) : 0,
        lng: coor.longitude ? parseFloat(coor.longitude) : 0
    }
    useEffect(() => {
        onLoad()
    }, [pathPoly])

    const onLoad: any = (polyline: any) => {
    };


    const handleAddLine = () => {
        setMode('polyline')
        setPathPoly({
            id: spanMapLines.length + 1,
            length: 0,
            color: randomColor({ luminosity: 'bright' }),
            lineData: [{ lat: parseFloat(coor.latitude ? coor.latitude : 0), lng: parseFloat(coor.longitude ? coor.longitude : 0) }]
        })

    }

    const handleSaveLine = () => {
        setMode('')
        let newPathPoly = pathPoly
        newPathPoly.length = length
        dispatch(handleSpanMapLines([...spanMapLines, newPathPoly]))
        setPathPoly({
            id: 0,
            length: 0,
            color: '',
            lineData: []
        })
        setlength(0)
    }
    const handleUndo = () => {
        const removed = pathPoly.lineData
        removed.pop()
        setPathPoly({
            ...pathPoly,
            lineData: [...removed]
        })
    }
    const handleClear = () => {
        setMode('')
        setPathPoly({
            ...pathPoly,
            lineData: []
        })
    }

    const handleLineHoverIn = (e: any, length: string) => {
        setPopText(length)
        setInfoCoor({ "lat": e.latLng.lat(), "lng": e.latLng.lng() })
    }
    const handleLineHoverOut = (e: any) => {
        setInfoCoor(null)
    }

    const colorList = [white_blue_pole, pole_color_red, pole_color_blue, pole_color_white, pole_color_green, pole_color_black, pole_color_yellow, pole_color_purple, pole_color_orange, pole_color_gray, pole_color_brown, pole_color_cyan]

    return (
        <div >
            <div className="header">
                {/* <Grid> */}
                {
                    mode === 'polyline' ? <i><small>Click the map to create a line</small></i> : null
                }
                {
                    mode === 'polyline' ?
                        <>
                            {pathPoly.lineData.length > 1 && <TextField variant="outlined" type="number" label='Length' size="small" style={{ marginRight: 5 }} onChange={(e) => setlength(parseInt(e.target.value))} />}
                            <ButtonGroup variant="contained" className='bgroup'>
                                <Button className='save' onClick={handleSaveLine}>Save</Button>
                                <Button className='undo' onClick={handleUndo}>undo</Button>
                                <Button className='clear' onClick={handleClear}>clear</Button>
                            </ButtonGroup>
                        </>
                        :
                        <Button className='addspan' variant='contained' onClick={handleAddLine}>Add Span</Button>
                }
                {/* </Grid> */}
            </div>

            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={20}
                tilt={0}
                center={center}
                mapTypeId="terrain"
                onClick={handleClick}
                clickableIcons={false}
            >

                <Marker
                    position={{ lat: parseFloat(coor.latitude ? coor.latitude : 0), lng: parseFloat(coor.longitude ? coor.longitude : 0) }}
                    clickable={false}
                    icon={target}
                />

                {
                    listPoleFieldingRequest.map((res: any, i: number) =>
                        <Marker
                            key={i}
                            position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                            clickable={false}
                            icon={colorList[res.color]}
                        />
                    )
                }

                {
                    listMidspanFieldingRequest.map((res: any, i: number) =>
                        <Marker
                            key={i}
                            position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                            clickable={false}
                            icon={midspan}
                        />
                    )
                }

                <Polyline
                    onLoad={onLoad}
                    path={pathPoly.lineData}
                    options={{
                        strokeColor: pathPoly.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 4,
                        //@ts-ignore
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        clickable: true,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: 30000,
                        paths: pathPoly.lineData,
                        zIndex: 1
                    }}
                />

                {
                    spanMapLines.map((res: any, i: any) => (
                        <Polyline
                            key={i}
                            onLoad={onLoad}
                            onClick={() => console.log('click lien')}
                            onMouseOver={(e: any) => handleLineHoverIn(e, res.length)}
                            onMouseOut={handleLineHoverOut}
                            path={res.lineData}
                            options={{
                                strokeColor: res.color,
                                strokeOpacity: 0.8,
                                strokeWeight: 7,
                                //@ts-ignore
                                fillColor: '#FF0000',
                                fillOpacity: 0.35,
                                clickable: true,
                                draggable: false,
                                editable: false,
                                visible: true,
                                radius: 30000,
                                paths: res.data,
                                zIndex: 1
                            }}
                        />
                    ))
                }
                {
                    infoCoor !== null ?
                        <InfoWindow
                            onLoad={onLoad}
                            position={infoCoor}
                        >
                            <div style={{ background: 'white', color: 'red' }}>
                                {popText}
                            </div>
                        </InfoWindow>
                        : null
                }

            </GoogleMap>

        </div>
    );
}

export default SpanMap;