
import { useEffect, useState } from 'react';
import { Button, Container, MenuItem, TextField } from '@material-ui/core';
import ModalTemplate from '../../../../shared/ModalTemplate';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Template from '../../../../shared/Template'
import FormJpa from '../component/FormJpa';
import { useSelector } from 'react-redux';
import { } from '../jpa.api';
import { RootState } from '../../../../app/store';
import LoadingCustom from '../../../../shared/LoadingCustom';
import Swal from 'sweetalert2';
import MUIDataTable from 'mui-datatables'
import Roles from '../../../../shared/Roles';

function Jpa() {

    const { listJpa, loading } = useSelector((state: RootState) => state.jpa)

    const { listProject } = useSelector((state: RootState) => state.sideTab);

    const [modal, setModal] = useState<any>({})

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Are you sure delete this JPA?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    title: 'JPA successfully deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }

    const handleAdd = () => {
        setModal({
            status: true,
            type: 'create',
            data: {}
        })
    }

    const handleEdit = (id: string) => {
        setModal({
            status: true,
            type: 'edit',
            data: listJpa?.filter((item: any) => item.id === id)[0]
        })
    }

    let component = () => (
        <>
            <LoadingCustom isLoading={loading} />
            <Container maxWidth={false} className="conten">
                <ModalTemplate
                    component={() => <FormJpa type={modal.type} data={modal.data} onClose={() => setModal({ status: false })} />}
                    onOpen={modal.status}
                />
                <div className="account">
                    <MUIDataTable
                        title={(
                            <div className="MUIDataTableToolbar-left-23">
                                <div className="MUIDataTableToolbar-titleRoot-27">
                                    <span className="MuiTypography-root MUIDataTableToolbar-titleText-28 MuiTypography-h6" style={{ marginRight: 10 }}>JPA Request</span>
                                    <br />
                                    <TextField
                                        select
                                        variant="outlined"
                                        size="small"
                                        defaultValue={0}
                                    >
                                        {listProject?.map((project: any, i: number) => {
                                            return (
                                                <MenuItem key={i} value={0}>{project.projectNumber}</MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </div>
                            </div>
                        )}
                        data={listJpa}
                        columns={[
                            {
                                name: "lorem",
                                label: "Job Number",
                                options: { filter: true }
                            },
                            {
                                name: "lorem",
                                label: "Fielding Request",
                                options: { filter: true }
                            },
                            {
                                name: "lorem",
                                label: "Fielding Request Type",
                                options: { filter: true }
                            },
                            {
                                name: "lorem",
                                label: "Pole Number",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Fielding Date",
                                options: { filter: true }
                            },
                            {
                                name: "lorem",
                                label: "LatLong",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "FAP Terminal",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Osmose Number",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Other Number",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "GLC",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Year Pole Set",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Pole Condition",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Radio Antena",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Pole Stamp Picture",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Pole Length",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Pole Class",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Species",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "HOA",
                                options: { filter: false }
                            },
                            {
                                name: "lorem",
                                label: "Transformers",
                                options: { filter: false }
                            },
                            {
                                name: "id",
                                label: "Action",
                                options: {
                                    customBodyRender: (value: any) => (
                                        <div className='action-button'>
                                            <Button className="button-edit" size='small' variant='contained' onClick={() => handleEdit(value)}>Edit</Button>
                                            <Button className="button-delete" size='small' variant='contained' onClick={() => handleDelete(value)}>Delete</Button>
                                        </div>
                                    ),
                                    filter: false
                                }
                            }
                        ]}
                        options={{
                            customToolbar: (data: any) => {
                                return (
                                    <Button className="button-add" variant='contained'>Create Loading Request</Button>
                                )
                            },
                            selectableRows: "none",
                            rowsPerPageOptions: [50, 100, 200],
                            rowsPerPage: 50,
                            downloadOptions: {
                                filename: "utility fielding user.csv"
                            },
                        }}
                    />
                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default Jpa;