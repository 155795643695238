import { Button } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../app/store";
import { setCenter } from "../features/dashboard/dashboard.reducer";
import { getPolePplx } from "../features/poleInformation/poleInformation.api";
import { setListPolePplx } from "../features/poleInformation/poleInformation.reducer";
import { baseUrl } from "../state";

export default function TopNapPplx() {

    const dispatch = useDispatch()

    const { listPolePplx } = useSelector((state: RootState) => state.poleInformation)

    const [uploadingStatus, setUploadingStatus] = useState(false)
    const [uploadingValue, setUploadingValue] = useState(0)

    useEffect(() => {
        dispatch<any>(getPolePplx())
            .unwrap()
            .then((response: any) => {
                dispatch(setListPolePplx(response.data))
            })
    }, [])

    const onChangeCsv = async (e: any) => {
        const files = e.target.files
        let data = new FormData();
        for (const i in files) {
            data.append("", files[i])
        }
        data.append("userId", localStorage.getItem('id') || "")
        setUploadingStatus(true)
        try {
            await axios.post(`${baseUrl}/api/pole/importpplx`, data, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadingValue(percentCompleted)
                }
            });
            setUploadingStatus(false)
            dispatch<any>(getPolePplx())
                .unwrap()
                .then((response: any) => {
                    dispatch(setListPolePplx(response.data));
                    let latitude = parseFloat(response.data[0].latitude)
                    let longitude = parseFloat(response.data[0].longitude)
                    dispatch(setCenter({ latitude: latitude, longitude: longitude }))
                })
        }
        catch (error: any) {
            Swal.fire({ icon: "error", title: "error", text: error })
            setUploadingStatus(false)
        }
    }

    return (
        <label htmlFor="button-file">
            <input
                style={{ display: "none" }}
                id="addCsv"
                type="file"
                accept=".pplx"
                onChange={onChangeCsv}
                multiple
            />
            <label htmlFor="addCsv">
                {uploadingStatus
                    ? (
                        <Button className="upload-btn" component="span" variant="outlined" disabled>
                            Uploading ({uploadingValue})%
                        </Button>
                    )
                    : (
                        <Button className="upload-btn" component="span" variant="outlined">
                            Import PPLX
                        </Button>
                    )
                }
            </label>
            <span style={{ marginLeft: 10 }}>{listPolePplx.length} poles imported</span>
        </label>
    )
}