import { Button, MenuItem, TextField, InputAdornment, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { RootState } from "../../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { setHoaData } from "../poleInformation.reducer";
import { CheckSquare, Save, X } from "react-feather";

function FormHOA(props: any) {
  const dispatch = useDispatch();
  const [type, setType] = useState(0);
  const [poleLengthInFeet, setPoleLengthInFeet] = useState(0);
  const [poleLengthInInch, setPoleLengthInInch] = useState(0);
  const { hoaData, poleEnums, poleFieldingRequest, poleType } = useSelector((state: RootState) => state.poleInformation);

  const handleSave = () => {
    dispatch(setHoaData([...hoaData, { type, poleLengthInFeet, poleLengthInInch }]));
    props.onClose();
  };

  return (
    <div className="formproject">
      <h3>Add {poleType === "MIDSPAN" ? "Clearance Height" : "HOA"} Data</h3>
      <form>
        {poleType !== "MIDSPAN" && (
          <>
            <TextField id="select" onChange={(e) => setType(parseInt(e.target.value))} value={type} className="inp" label="Type" variant="outlined" size="small" select>
              {poleEnums.hoaTypes.map((item: any, itemIndex: number) => (
                <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
              ))}
            </TextField>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Terminal"
              size="small"
            />
          </>
        )}
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="number"
              label="Height of Attachment (Feet)"
              // placeholder="Height of Attachment (Feet)"
              size="small"
              value={poleLengthInFeet}
              onChange={(e) => setPoleLengthInFeet(parseInt(e.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position="end">Feet</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="number"
              label="Height of Attachment (Inch)"
              // placeholder="Height of Attachment (Inch)"
              size="small"
              value={poleLengthInInch}
              onChange={(e) => setPoleLengthInInch(parseInt(e.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position="end">Inch</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <div className="wrapbutton">
          <Button type="button" className="cancel" variant="outlined" onClick={props.onClose}>
            <X /> Cancel
          </Button>
          <Button variant="outlined" className="done" onClick={handleSave}>
            <CheckSquare /> Done
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FormHOA;
