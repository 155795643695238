import { createSlice } from '@reduxjs/toolkit'
import { getProfile } from './profile.api'

export interface ProfileState {
  loading: boolean,
}
const initialState: ProfileState = {
  loading: false,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers : (builder) => {
        // get
        builder.addCase(getProfile.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default profileSlice.reducer