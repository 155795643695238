import { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import bgAnchor from '../../../../assets/images/bgAnchor.png'

export default function AnchorCanvasSmall() {
    const {
        anchorCanvasDotes,
        anchorCanvasStreets,
        anchorCanvasFences,
        poleEnums
    } = useSelector((state: RootState) => state.poleInformation)

    const [selected, setSelected] = useState<any>({})

    useLayoutEffect(() => {
        const canvas: any = document.getElementById('canvasAnchorSmall')
        const ctx: any = canvas.getContext("2d")

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        anchorCanvasDotes.forEach((res: any) => {
            ctx.strokeStyle = 'grey'
            ctx.beginPath();
            ctx.arc((res.circleX * 2), (res.circleY * 2), 15, 0, 2 * Math.PI, false);
            ctx.font = '10px, Calibri';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'red';
            ctx.fillText(res.text, (res.circleX * 2), (res.circleY * 2) + 3);
            ctx.closePath();
            ctx.stroke();
        })

        anchorCanvasStreets.forEach((res: any) => {
            ctx.strokeStyle = `${res.stroke}`
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.lineTo((res.points.x1 * 2), (res.points.y1 * 2));
            ctx.lineTo((res.points.x2 * 2), (res.points.y2 * 2));
            ctx.closePath();
            ctx.stroke();
        })

        anchorCanvasFences.forEach((res: any) => {
            ctx.strokeStyle = `${res.stroke}`
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.lineTo((res.points.x1 * 2), (res.points.y1 * 2));
            ctx.lineTo((res.points.x2 * 2), (res.points.y2 * 2));
            ctx.closePath();
            ctx.stroke();
        })

    }, [
        anchorCanvasDotes,
        anchorCanvasStreets,
        anchorCanvasFences
    ])

    let handleClick = (e: any) => {

        const canvas: any = document.getElementById('canvasAnchorSmall')

        const translatedX = (x: any) => {
            var rect = canvas.getBoundingClientRect();
            var factor = canvas.width / rect.width;
            return factor * (x - rect.left);
        }

        const translatedY = (y: any) => {
            var rect = canvas.getBoundingClientRect();
            var factor = canvas.height / rect.height;
            return factor * (y - rect.top);
        }

        let x = translatedX(e.clientX) / 2
        let y = translatedY(e.clientY) / 2
        anchorCanvasDotes.forEach((res: any) => {
            let targetX = res.circleX
            let targetY = res.circleY
            if (targetX > (x - 15) && targetX < (x + 15) && targetY > (y - 15) && targetY < (y + 15)) {
                if (JSON.stringify(selected) !== JSON.stringify(res)) {
                    setSelected(res)
                }
                else {
                    setSelected({})
                }
            }
        })
    }

    return (
        <div className="minimap-container">
            <div className="minimap">
                <canvas
                    id='canvasAnchorSmall'
                    style={{ backgroundImage: `url('${bgAnchor}')`, cursor: 'crosshair' }}
                    width={600}
                    height={400}
                    onClick={handleClick}
                >
                </canvas>
            </div>
            <div className="information">
                {selected.circleX &&
                    <table>
                        <tr>
                            <td>Name</td>
                            <td>:</td>
                            <td>{selected.text}</td>
                        </tr>
                        <tr>
                            <td>Distance From Pole</td>
                            <td>:</td>
                            <td>{selected.distance}</td>
                        </tr>
                        <tr>
                            <td>Anchor Size</td>
                            <td>:</td>
                            <td>{selected.isAnchorSizeUnknown ? "Unknown" : poleEnums.anchorSizes.filter((item: any) => item.value === selected.size)[0].display}</td>
                        </tr>
                        <tr>
                            <td>Anchor Eyes</td>
                            <td>:</td>
                            <td>{selected.isAnchorEyeUnknown ? "Unknown" : poleEnums.anchorEyes.filter((item: any) => item.value === selected.anchorEye)[0].display}</td>
                        </tr>
                        <tr>
                            <td>Picture of Anchor Eyes</td>
                            <td>:</td>
                            <td>{selected.eyesPict}{selected.eyesPict ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td>Condition</td>
                            <td>:</td>
                            <td>{poleEnums.anchorConditions.filter((item: any) => item.value === selected.anchorCondition)[0].display}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "top" }}>Downguy</td>
                            <td style={{ verticalAlign: "top" }}>:</td>
                            <td>{selected.downGuyList.map((dg: any, index: number) => {
                                return (
                                    <table style={{ borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "darkgrey" }}>
                                        <tr>
                                            <td>DG{index + 1}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Condition</td>
                                            <td>:</td>
                                            <td>{poleEnums.downGuyTypes.filter((item: any) => item.value === dg.type)[0].display}</td>
                                        </tr>
                                        <tr>
                                            <td>Size</td>
                                            <td>:</td>
                                            <td>{poleEnums.downGuySizes.filter((item: any) => item.value === dg.size)[0].display}</td>
                                        </tr>
                                        <tr>
                                            <td>Owner</td>
                                            <td>:</td>
                                            <td>{poleEnums.downGuyOwners.filter((item: any) => item.value === dg.owner)[0].display}</td>
                                        </tr>
                                    </table>
                                )
                            })}</td>
                        </tr>
                    </table>
                }
            </div>
        </div>
    )
}
