import { Fragment, useEffect, useState } from "react";
import { Briefcase, Check, ChevronDown, Copy, LogOut, Mail, Scissors, Trash, User } from "react-feather";
import { Button, ClickAwayListener, MenuItem, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { getCompany } from "../features/tracker/company/company.api";
import { setCompany } from "../features/tracker/company/company.reducer";
import { getAccount } from "../features/tracker/account/account.api";
import { getPoleFieldingRequest, getPoleInformationEnums } from "../features/poleInformation/poleInformation.api";
import { generateKmlMultipleFielding, generateKmlMultipleJob, getFieldingRequest, getNoteEnums, getProject } from "../features/sideTab/sideTab.api";
import { handleProjectIdActive, setDetailMenu, setFieldingIdList, setJobDetail, setJobIdList, setNoteList, setLoading, setLoadingRequestIdList, setSidetabActive } from "../features/sideTab/sideTab.reducer";
import ModalTemplate from "./ModalTemplate";
import { setAerialFielding } from "../features/assignFielding/assignFielding.reducer";
import { setListMarkerFieldingRequest, setListMeasureMarker, setListMidspanFieldingRequest, setListPoleFieldingRequest, setListPoleLoadingRequest } from "../features/poleInformation/poleInformation.reducer";
import Roles from "./Roles.js";
import { getAllAssignedJobMapView, getFieldingRequestDetail } from "../features/assignFielding/assignFielding.api";
import { getPoleLoadingType } from "../features/tracker/poleLoading/poleloading.api";
import { handleRightButton, setIsDragSelectActive, setSelectedIdMarkers } from "../features/dashboard/dashboard.reducer";
import LoadingCustom from "./LoadingCustom";
import { editColorMultiplePole, setActiveMultiplePole, movePole, copyPole } from "../features/dashboard/dashboard.api";
import AerialFieldingColorModal from "../features/sideTab/components/jobdetailmenu/AerialFieldingColorModal";
import { baseUrl } from "../state";
import AerialFieldingMoveModal from "../features/sideTab/components/jobdetailmenu/AerialFieldingMoveModal";
import Swal from "sweetalert2";
import AerialFieldingCopyModal from "../features/sideTab/components/jobdetailmenu/AerialFieldingCopyModal";
import TopNapPplx from "./TopNapPplx";

function TopNav(props: any) {

  const loginAccess: boolean = localStorage.getItem('auth') && localStorage.getItem('token') ? true : false

  const dispatch = useDispatch();

  const { listCompany, loading } = useSelector((state: RootState) => state.company);
  const { jobIdList, fieldingIdList, detailMenu, jobNumberIdActive, loadingRequestIdList, statusFieldingRequest } = useSelector((state: RootState) => state.sideTab);
  const { selectedIdMarkers, rightButton } = useSelector((state: RootState) => state.dashboard);
  const { aerialFielding } = useSelector((state: RootState) => state.assignFielding);

  const [popTracker, setPopTracker] = useState<any>(false);
  const [popAccount, setPopAccount] = useState<any>(false);
  const [popUser, setPopUser] = useState<any>(false);
  const [modalChangePoleColor, setModalChangePoleColor] = useState<any>(false);
  const [modalMovePole, setModalMovePole] = useState<any>(false);
  const [modalCopyPole, setModalCopyPole] = useState<any>(false);

  const resetState = () => {
    dispatch(handleProjectIdActive({ id: "" }))
    dispatch(setJobDetail(null));
    dispatch(setDetailMenu({}))
    dispatch(setAerialFielding({}))
    dispatch(setListPoleFieldingRequest([]))
    dispatch(setListPoleLoadingRequest([]))
    dispatch(setListMidspanFieldingRequest([]))
    dispatch(setListMeasureMarker([]))
    dispatch(setListMarkerFieldingRequest([]))
    dispatch(setJobIdList([]))
    dispatch(setFieldingIdList([]))
    dispatch(setLoadingRequestIdList([]))
    dispatch(setNoteList([]))
    dispatch(setSidetabActive({ project: true, jobNumber: false, jobDetail: false, jobDetailMenu: false }))
    if (rightButton) {
      dispatch(handleRightButton());
    }
  }

  const handleLogout = () => {
    resetState()
    localStorage.clear();
    window.location.href = "/home";
  };

  const changeCompany = (value: any) => {
    dispatch(getProject(value))
    localStorage.setItem("companyIdSelected", value)
    dispatch(setCompany(listCompany?.filter((item: any) => item.id === value)));
    resetState()
  };

  useEffect(() => {
    dispatch(getCompany());
    dispatch(getAccount());
    dispatch(getPoleInformationEnums())
    dispatch(getAllAssignedJobMapView())
    dispatch(getPoleLoadingType())
    if (Roles("select_company")) {
      if (!localStorage.getItem("companyIdSelected")) {
        localStorage.setItem("companyIdSelected", "null")
      }
    }
    else {
      let companyId: string = localStorage?.getItem("companyId") || "null"
      localStorage.setItem("companyIdSelected", companyId)
      dispatch(getProject(companyId))
      dispatch(setCompany(listCompany?.filter((item: any) => item.id === companyId)));
    }
    dispatch(getNoteEnums())
  }, []);

  const handleChangeColor = async (color: number) => {
    await dispatch(editColorMultiplePole({
      poleIds: selectedIdMarkers,
      color: color
    }))
    await dispatch(setSelectedIdMarkers([]))
    let poleList: any = []
    for (let i = 0; i < fieldingIdList.length; i++) {
      await dispatch<any>(getPoleFieldingRequest(fieldingIdList[i]))
        .unwrap()
        .then((response: any) => {
          poleList = poleList.concat(response.data)
        })
    }
    dispatch(setListPoleFieldingRequest(poleList));
    setModalChangePoleColor(false)
    dispatch(setIsDragSelectActive(false))
  }

  const handleMove = async (filedingId: number) => {
    await dispatch(movePole({
      poleIds: selectedIdMarkers,
      userId: localStorage.getItem('id'),
      newFieldingRequestId: filedingId
    }))
    await dispatch(setSelectedIdMarkers([]))
    let poleList: any = []
    for (let i = 0; i < fieldingIdList.length; i++) {
      await dispatch<any>(getPoleFieldingRequest(fieldingIdList[i]))
        .unwrap()
        .then((response: any) => {
          poleList = poleList.concat(response.data)
        })
    }
    dispatch<any>(getFieldingRequest({
      jobnumberId: jobNumberIdActive.id,
      status: statusFieldingRequest
    }))
    dispatch(setListPoleFieldingRequest(poleList));
    setModalMovePole(false)
    dispatch(setIsDragSelectActive(false))
  }

  const handleCopy = async (filedingId: number, isWithData: boolean) => {
    await dispatch(copyPole({
      poleIds: selectedIdMarkers,
      userId: localStorage.getItem('id'),
      newFieldingRequestId: filedingId,
      copyFullData: isWithData
    }))
    await dispatch(setSelectedIdMarkers([]))
    let poleList: any = []
    for (let i = 0; i < fieldingIdList.length; i++) {
      await dispatch<any>(getPoleFieldingRequest(fieldingIdList[i]))
        .unwrap()
        .then((response: any) => {
          poleList = poleList.concat(response.data)
        })
    }
    dispatch<any>(getFieldingRequest({
      jobnumberId: jobNumberIdActive.id,
      status: statusFieldingRequest
    }))
    dispatch(setListPoleFieldingRequest(poleList));
    setModalCopyPole(false)
    dispatch(setIsDragSelectActive(false))
  }

  const handleDownloadKml = () => {
    dispatch(setLoading(true))
    dispatch<any>(generateKmlMultipleJob(jobIdList))
      .unwrap()
      .then((res: any) => {
        let arr = res.data.split("/")
        fetch(baseUrl + "/api/Global/Download?path=" + res.data)
          .then((res) => res.blob())
          .then((response) => {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            downloadLink.setAttribute('download', arr[arr.length - 1]);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            dispatch(setLoading(false))
          })
          .catch(() => {
            dispatch(setLoading(false))
          })
      })
  }

  const handleDownloadFieldingKml = () => {
    dispatch(setLoading(true))
    dispatch<any>(generateKmlMultipleFielding(fieldingIdList))
      .unwrap()
      .then((res: any) => {
        let arr = res.data.split("/")
        fetch(baseUrl + "/api/Global/Download?path=" + res.data)
          .then((res) => res.blob())
          .then((response) => {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            downloadLink.setAttribute('download', arr[arr.length - 1]);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            dispatch(setLoading(false))
          })
          .catch(() => {
            dispatch(setLoading(false))
          })
      })
  }

  const handleDeleteMultiple = () => {
    Swal.fire({
      title: 'Are you sure you want to delete this pole?',
      text: 'Deleted pole can be retrieved from Deleted Items.',
      icon: 'warning',
      showCancelButton: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(setActiveMultiplePole({
          poleIds: selectedIdMarkers,
          isActive: false,
          userId: localStorage.getItem('id')
        }))
        dispatch(setSelectedIdMarkers([]))
        let poleList: any = []
        for (let i = 0; i < fieldingIdList.length; i++) {
          await dispatch<any>(getPoleFieldingRequest(fieldingIdList[i]))
            .unwrap()
            .then((response: any) => {
              poleList = poleList.concat(response.data)
            })
        }
        if (Boolean(aerialFielding.id)) {
          dispatch(getFieldingRequestDetail(aerialFielding.id))
        }
        dispatch(setListPoleFieldingRequest(poleList));
        dispatch(setIsDragSelectActive(false))
      }
    })
  }

  return (
    <div className="navbar">
      <ModalTemplate component={() => <AerialFieldingColorModal onSubmit={handleChangeColor} handleCancel={() => setModalChangePoleColor(false)} />} onOpen={modalChangePoleColor} onClose={() => setModalChangePoleColor(false)} />
      <ModalTemplate component={() => <AerialFieldingMoveModal onSubmit={handleMove} handleCancel={() => setModalMovePole(false)} />} onOpen={modalMovePole} onClose={() => setModalMovePole(false)} />
      <ModalTemplate component={() => <AerialFieldingCopyModal onSubmit={handleCopy} handleCancel={() => setModalCopyPole(false)} />} onOpen={modalCopyPole} onClose={() => setModalCopyPole(false)} />
      <LoadingCustom isLoading={loading} />
      <div className="topnav">
        <div className="title">
          <Link to="/" onClick={resetState}>Utility Fielding</Link>
          &nbsp; &nbsp; &nbsp;
          {(Roles("select_company") && (window.location.pathname !== "/account" && window.location.pathname !== "/company")) && (
            <TextField
              select
              variant="outlined"
              size="small"
              defaultValue={"null"}
              value={localStorage.getItem("companyIdSelected")}
              onChange={(e) => changeCompany(e.target.value)}
            >
              <MenuItem value={"null"}>Select Company</MenuItem>
              {listCompany?.map((company: any, companyIndex: number) => (
                <MenuItem key={companyIndex} value={company?.id}>{company?.name}</MenuItem>
              ))}
            </TextField>
          )}
          &nbsp; &nbsp; &nbsp;
          {localStorage.getItem("roles")?.includes("Fielder") && (
            <Fragment>
              &nbsp; &nbsp; &nbsp;
              <Button variant="outlined" onClick={() => window.location.href = `https://profielder.utilityfielding.com/LoginToken?token=${window.localStorage.getItem("token")}`}>Go To Fielding</Button>
            </Fragment>
          )}
          {jobIdList.length >= 2 && <Button variant="outlined" onClick={handleDownloadKml}>Export Jobs to KML</Button>}
          {(detailMenu.id === 1 && jobIdList.length <= 1 && fieldingIdList.length >= 2) && <Button variant="outlined" onClick={handleDownloadFieldingKml}>Export Fielding Request to KML</Button>}
        </div>
        {(Roles("loading_management_feature") && window.location.pathname === '/home' && loadingRequestIdList.length === 0) && (
          <TopNapPplx />
        )}
        <ul className="menus">
          {((detailMenu.id === 1 || detailMenu.id === undefined) && selectedIdMarkers.length > 0 && window.location.pathname.includes("/home")) && (
            <li>
              <div className="pole-loading-button">
                <Button startIcon={<Check />} onClick={() => setModalChangePoleColor(true)}>
                  Change color
                </Button>
                <Button startIcon={<Scissors />} onClick={() => setModalMovePole(true)}>
                  Cut
                </Button>
                <Button startIcon={<Copy />} onClick={() => setModalCopyPole(true)}>
                  Copy
                </Button>
                <Button startIcon={<Trash />} onClick={handleDeleteMultiple}>
                  Delete
                </Button>
              </div>
            </li>
          )}
          {
            (
              Roles("tracker_fielding_request") ||
              Roles("tracker_jpa") ||
              Roles("tracker_mre") ||
              Roles("tracker_tcp") ||
              Roles("tracker_pole_loading")
            ) && (
              <ClickAwayListener onClickAway={() => setPopTracker(false)}>
                <li>
                  <Button className={(['/fieldingrequest', '/jpa', '/mre', '/tcp', '/poleloading'].includes(window.location.pathname) ? "active" : "")} variant="outlined" onClick={() => setPopTracker(!popTracker)}>
                    Tracker <ChevronDown />
                  </Button>
                  {popTracker ? (
                    <div className="list">
                      {Roles("tracker_fielding_request") && (<Link to="/fieldingrequest">Project</Link>)}
                      {/* {Roles("tracker_jpa") && (<Link to="/jpa">JPA</Link>)} */}
                      {/* {Roles("tracker_mre") && (<Link to="/mre">MRE</Link>)} */}
                      {/* {Roles("tracker_tcp") && (<Link to="/tcp">TCP</Link>)} */}
                      {Roles("tracker_pole_loading") && (<Link to="/poleloading">Pole Loading</Link>)}
                    </div>
                  ) : null}
                </li>
              </ClickAwayListener>
            )
          }
          {Roles("assign_fielding") && (
            <li>
              <Link to="/assign-fielding">
                <Button className={(['/assign-fielding'].includes(window.location.pathname) ? "active" : "")} variant="outlined">
                  Assign Fielding
                </Button>
              </Link>
            </li>
          )}
          {Roles("map_view") && (
            <li>
              <Link to="/home">
                <Button className={(['/', '/home'].includes(window.location.pathname) ? "active" : "")} variant="outlined">
                  Map View
                </Button>
              </Link>
            </li>
          )}
          {
            (
              Roles("tracker_account_all") ||
              Roles("tracker_account_filtered") ||
              Roles("tracker_company")
            ) && (
              <ClickAwayListener onClickAway={() => setPopAccount(false)}>
                <li>
                  <Button className={(['/account', '/company'].includes(window.location.pathname) ? "active" : "")} variant="outlined" onClick={() => setPopAccount(!popAccount)}>
                    Manage Account <ChevronDown />
                  </Button>
                  {popAccount ? (
                    <div className="list">
                      {(Roles("tracker_account_all") || Roles("tracker_account_filtered")) && (<Link to="/account">User Account</Link>)}
                      {Roles("tracker_company") && (<Link to="/company">Manage Company</Link>)}
                    </div>
                  ) : null}
                </li>
              </ClickAwayListener>
            )
          }
          {loginAccess && (
            <ClickAwayListener onClickAway={() => setPopUser(false)}>
              <li>
                <User onClick={() => setPopUser(!popUser)} />
                {popUser ? (
                  <div className="list">
                    <span>
                      <i>
                        <Mail />
                        {localStorage.getItem("email")}
                      </i>
                    </span>
                    <span>
                      <i>
                        <Briefcase />
                        {localStorage.getItem("company")}
                      </i>
                    </span>
                    {Roles("profile") && (
                      <Link to={'/profile/' + localStorage.getItem("email")?.split('.')[0]}>View Profile</Link>
                    )}
                    <span className="logout" onClick={handleLogout}>
                      <LogOut /> &nbsp; Logout
                    </span>
                  </div>
                ) : null}
              </li>
            </ClickAwayListener>
          )}
        </ul>
      </div>
    </div>
  );
}

export default TopNav;
