
import { useEffect, useState } from 'react';
import { Button, Container } from '@material-ui/core';
import ModalTemplate from '../../../../shared/ModalTemplate';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Template from '../../../../shared/Template'
import FormTcp from '../component/FormTcp';
import { useSelector } from 'react-redux';
import { } from '../tcp.api';
import { RootState } from '../../../../app/store';
import LoadingCustom from '../../../../shared/LoadingCustom';
import Swal from 'sweetalert2';
import MUIDataTable from 'mui-datatables'

function Tcp() {

    const { listTcp, loading } = useSelector((state: RootState) => state.tcp)

    const [modal, setModal] = useState<any>({})

    useEffect(() => {
    }, [])

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Are you sure delete this TCP?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    title: 'TCP successfully deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }

    const handleAdd = () => {
        setModal({
            status: true,
            type: 'create',
            data: {}
        })
    }

    const handleEdit = (id: string) => {
        setModal({
            status: true,
            type: 'edit',
            data: listTcp?.filter((item: any) => item.id === id)[0]
        })
    }

    let component = () => (
        <>
            <LoadingCustom isLoading={loading} />
            <Container maxWidth={false} className="conten">
                <ModalTemplate
                    component={() => <FormTcp type={modal.type} data={modal.data} onClose={() => setModal({ status: false })} />}
                    onOpen={modal.status}
                />
                <div className="account">
                    <MUIDataTable
                        title={"TCP Request"}
                        data={listTcp}
                        columns={[
                            {
                                name: "lorem",
                                label: "Company",
                                options: {
                                    filter: true
                                }
                            },
                            {
                                name: "lorem",
                                label: "Project",
                                options: {
                                    filter: true
                                }
                            },
                            {
                                name: "lorem",
                                label: "Job Number",
                                options: {
                                    filter: true
                                }
                            },
                            {
                                name: "lorem",
                                label: "Fielding Request",
                                options: {
                                    filter: true
                                }
                            },
                            {
                                name: "lorem",
                                label: "Fielding Request Type",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Pole Number",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Fielding Date",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "LatLong",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "FAP Terminal",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Osmose Number",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Other Number",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "GLC",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Year Pole Set",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Pole Condition",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Radio Antena",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Pole Stamp Picture",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Pole Length",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Pole Class",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Species",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "HOA",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "lorem",
                                label: "Transformers",
                                options: {
                                    filter: false
                                }
                            },
                            {
                                name: "id",
                                label: "Action",
                                options: {
                                    customBodyRender: (value: any) => (
                                        <div className='action-button'>
                                            <Button className="button-edit" size='small' variant='contained' onClick={() => handleEdit(value)}>Edit</Button>
                                            <Button className="button-delete" size='small' variant='contained' onClick={() => handleDelete(value)}>Delete</Button>
                                        </div>
                                    ),
                                    filter: false
                                }
                            }
                        ]}
                        options={{
                            customToolbar: (data: any) => {
                                return (
                                    <Button className="button-add" variant='contained'>Create Loading Request</Button>
                                )
                            },
                            selectableRows: "none",
                            rowsPerPageOptions: [50, 100, 200],
                            rowsPerPage: 50,
                            downloadOptions: {
                                filename: "utility fielding user.csv"
                            },
                        }}
                    />
                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default Tcp;