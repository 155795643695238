import { Button, LinearProgress, Link, MenuItem, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import { deleteTempFieldingRequest, editFieldingRequest, getFieldingRequest, importJsonFielding, postFieldingRequest } from "../../sideTab.api";
import { handleFieldingRequestIdActive, handleLayerActive, handleModalAerialFielding, setFieldingIdList, setJobIdList } from "../../sideTab.reducer";
import { getPoleFieldingRequest } from "../../../poleInformation/poleInformation.api";
import { setAerialFielding, setActiveAerial } from "../../../assignFielding/assignFielding.reducer";
import { File, Save, Trash, X } from "react-feather";
import axios from "axios";
import { setListMarkerFieldingRequest, setListMeasureMarker, setListMidspanFieldingRequest, setListPoleFieldingRequest } from "../../../poleInformation/poleInformation.reducer";
import Roles from "../../../../shared/Roles";
import moment from "moment";
import { setCenter, setZoom } from "../../../dashboard/dashboard.reducer";
import { baseUrl } from "../../../../state";
import Papa from "papaparse";
import ModalTemplate from "../../../../shared/ModalTemplate";
import FormFieldingCsv from "./FormFieldingCsv";

export default function FormFielding(props: any) {
  const dispatch = useDispatch();

  const { modalAerialFielding, projectIdActive, jobNumberIdActive, isLayerActive, fieldingRequestIdActive, statusFieldingRequest } = useSelector((state: RootState) => state.sideTab);
  const { listPoleFieldingRequest, listMidspanFieldingRequest, listMarkerFieldingRequest, listMeasureMarker } = useSelector((state: RootState) => state.poleInformation);

  const [values, setValues] = useState<any>({
    jobNumberId: jobNumberIdActive.id,
    name: "",
    description: "",
    dueDateString: "",
    kmlFilePath: "",
    type: 0,
    fieldingCategory: 0,
    attachmentPaths: [],
    city: ""
  });
  const [localFilesKml, setLocalFilesKml] = useState<any>([])
  const [localFilesAttachment, setLocalFilesAttachment] = useState<any>([])
  const [csvData, setCsvData] = useState<any>([])
  const [jsonData, setJsonData] = useState<any>([])

  useEffect(() => {
    if (modalAerialFielding.formType === "") {
      setValues({
        jobNumberId: jobNumberIdActive.id,
        name: "",
        description: "",
        dueDateString: "",
        kmlFilePath: "",
        type: 0,
        fieldingCategory: 0,
        attachmentPaths: [],
        city: jobNumberIdActive.city
      });
    }
    else {
      setValues({
        ...values,
        jobNumberId: jobNumberIdActive.id,
        name: fieldingRequestIdActive.name,
        description: fieldingRequestIdActive.description,
        dueDateString: fieldingRequestIdActive.dueDate?.split('T')[0],
        type: fieldingRequestIdActive.type,
        fieldingCategory: fieldingRequestIdActive.fieldingCategory,
        city: fieldingRequestIdActive.city
      });
    }
  }, [fieldingRequestIdActive, jobNumberIdActive])

  const handleClose = () => {
    dispatch(handleModalAerialFielding({ isOpen: false, formType: "" }));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "fieldingCategory" && value === 1) {
      setValues({
        ...values,
        [name]: value,
        type: 1
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let attachmentPaths = localFilesAttachment.map((file: any) => {
      return {
        fileName: file.name,
        filePath: file.responseFilePath
      }
    })
    let newValues = {
      ...values,
      dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy"),
      kmlFilePath: (localFilesKml[0]?.responseFilePath ? localFilesKml[0]?.responseFilePath : ""),
      attachmentPaths: (localFilesAttachment.length > 0
        ? attachmentPaths
        : []
      )
    }
    dispatch<any>(postFieldingRequest(newValues))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Create Request Failed!");
        } else {
          dispatch<any>(importJsonFielding({
            fieldingRequestId: res.data.newFieldingRequest.id,
            userId: localStorage.getItem('id'),
            importDto: jsonData
          }))
            .unwrap()
            .then((resImport: any) => {
              console.log(resImport)
              Swal.fire({
                icon: "success",
                title: "New Request Successfully Created, " + (res?.data?.poleCount + resImport?.data?.poleCount) + " Poles Imported",
              }).then(() => {
                handleClose();
                dispatch<any>(getFieldingRequest({
                    jobnumberId: jobNumberIdActive.id,
                    status: statusFieldingRequest
                }))
                dispatch(handleLayerActive({ ...isLayerActive, layerFieldingId: res?.data?.newFieldingRequest?.id, status: true, label: "aerial" }));
                dispatch(handleFieldingRequestIdActive(res?.data?.newFieldingRequest));
                dispatch(setFieldingIdList([res?.data?.newFieldingRequest?.id]));
                dispatch(getPoleFieldingRequest(res?.data?.newFieldingRequest?.id));
                dispatch(setActiveAerial({ payload: res?.data?.newFieldingRequest?.id }));
                dispatch(setAerialFielding({}));
                dispatch(setJobIdList([]))
                if ((res?.data?.poleCount + resImport?.data?.poleCount) > 0) {
                  dispatch<any>(getPoleFieldingRequest(res?.data?.newFieldingRequest?.id))
                    .unwrap()
                    .then((response: any) => {
                      dispatch(setListPoleFieldingRequest(listPoleFieldingRequest.concat(response.data)));
                      dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
                      dispatch(setZoom(13))
                    })
                }
              });
            })

        }
      });
  };

  const handleEdit = (e: any) => {
    e.preventDefault();
    dispatch<any>(editFieldingRequest({
      id: fieldingRequestIdActive.id,
      description: values.description,
      type: values.type,
      city: values.city,
      fieldingCategory: values.fieldingCategory,
      dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy")
    }))
      .unwrap()
      .then((res: any) => {
        console.log(res, "saya resssssss");
        if (res.status >= 400) {
          Swal.fire("Edit Request Failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Request Successfully Edited",
            showConfirmButton: false,
            timer: 1500,
          }).then((res: any) => {
            handleClose();
            dispatch<any>(getFieldingRequest({
                jobnumberId: jobNumberIdActive.id,
                status: statusFieldingRequest
            }))
            dispatch(handleFieldingRequestIdActive({}))
            dispatch(handleLayerActive({}))
          });
        }
      });
  };

  const handleDelete = () => {
    dispatch<any>(deleteTempFieldingRequest(fieldingRequestIdActive.id))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Delete request failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Request Successfully Deleted",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            dispatch<any>(getFieldingRequest({
                jobnumberId: jobNumberIdActive.id,
                status: statusFieldingRequest
            }))
            dispatch(handleLayerActive({}));
            handleClose()
            clearPoleOnId(fieldingRequestIdActive.id)
          });
        }
      })
  }

  const clearPoleOnId = (id: any) => {
    dispatch(setListPoleFieldingRequest(listPoleFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== id)));
    dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== id)));
    dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== id)));
    dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => item?.fieldingRequestId !== id)));
  }

  const onChangeKml = (e: any) => {
    var files = Array.from(e.target.files);
    if (files) {
      let newLocalFiles: any = []
      files.map((file: any) => {
        newLocalFiles.push({ file: file, name: file.name, isUploading: true, uploadProgress: 0, responseFilePath: "" })
      })
      setLocalFilesKml(localFilesKml.concat(newLocalFiles))
    }
  }

  useEffect(() => {
    localFilesKml.map(async (localFile: any) => {
      if (localFile.uploadProgress === 0 && localFile.isUploading === true) {
        let data = new FormData();
        data.append("", localFile.file)
        try {
          let response = await axios.post(`${baseUrl}/api/FieldingRequest/UploadKMLFile`, data, {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              let newLocalFiles: any = []
              localFilesKml.map((localFileProgress: any) => {
                if (localFileProgress.name === localFile.name) {
                  localFileProgress.uploadProgress = percentCompleted
                }
                newLocalFiles.push(localFileProgress)
              })
              setLocalFilesKml(newLocalFiles)
            }
          });
          let newLocalFiles: any = []
          localFilesKml.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
              localFileProgress.responseFilePath = response.data.data.filePath
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesKml(newLocalFiles)
        } catch (error: any) {
          console.log(error);
          let newLocalFiles: any = []
          localFilesKml.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesKml(newLocalFiles)
        }
      }
    })
  }, [localFilesKml])

  const onChangeAttachment = (e: any) => {
    var files = Array.from(e.target.files);
    if (files) {
      let newLocalFiles: any = []
      files.map((file: any) => {
        newLocalFiles.push({ file: file, name: file.name, isUploading: true, uploadProgress: 0, responseFilePath: "" })
      })
      setLocalFilesAttachment(localFilesAttachment.concat(newLocalFiles))
    }
  }

  useEffect(() => {
    localFilesAttachment.map(async (localFile: any) => {
      if (localFile.uploadProgress === 0 && localFile.isUploading === true) {
        let data = new FormData();
        data.append("", localFile.file)
        try {
          let response = await axios.post(`${baseUrl}/api/FieldingRequest/UploadAttachments`, data, {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              let newLocalFiles: any = []
              localFilesAttachment.map((localFileProgress: any) => {
                if (localFileProgress.name === localFile.name) {
                  localFileProgress.uploadProgress = percentCompleted
                }
                newLocalFiles.push(localFileProgress)
              })
              setLocalFilesAttachment(newLocalFiles)
            }
          });
          let newLocalFiles: any = []
          localFilesAttachment.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
              localFileProgress.responseFilePath = response.data.data[0].filePath
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesAttachment(newLocalFiles)
        } catch (error: any) {
          console.log(error);
          let newLocalFiles: any = []
          localFilesAttachment.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesAttachment(newLocalFiles)
        }
      }
    })
  }, [localFilesAttachment])

  const onChangeCsv = (e: any) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: function (results: any) {
          setCsvData(results.data)
        }
      })
    }
  }

  return (
    <div className="formproject">
      <ModalTemplate component={() => <FormFieldingCsv csvData={csvData} setCsvData={setCsvData} setJsonData={setJsonData} />} onOpen={csvData.length} />
      {modalAerialFielding.formType === "edit" ? (
        <>
          <h3>Edit Fielding Request and Date <X onClick={handleClose} /></h3>
          <form onSubmit={handleEdit}>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Name"
              placeholder="Request Fielding"
              size="small"
              value={values.name}
              disabled
            />
            <TextField
              className="inp"
              name="city"
              value={values.city}
              variant="outlined"
              onChange={handleChange}
              type="text"
              label="City & State"
              placeholder="City & State"
              size="small"
            />
            <TextField
              id="select"
              className="inp"
              label="Fielding Category"
              variant="outlined"
              size="small"
              select
              name="fieldingCategory"
              value={values.fieldingCategory}
              onChange={handleChange}
            >
              {[
                { value: 2, name: "Address Validation" },
                { value: 0, name: "Aerial Fielding" },
                { value: 1, name: "Measure Marker" }]
                .map((item: any, itemIndex: number) => (
                  <MenuItem key={itemIndex} value={item.value}>{item.name}</MenuItem>
                ))}
            </TextField>
            <TextField
              id="select"
              className="inp"
              label="Fielding Request Type"
              variant="outlined"
              size="small"
              select
              name="type"
              value={values.type}
              onChange={handleChange}
            >
              {[{ value: 0, name: "Utility Fielding App" }, { value: 1, name: "Pen & Paper" }, { value: 2, name: "MRE Estimation" }].map((item: any, itemIndex: number) => (
                <MenuItem key={itemIndex} value={item.value}>{item.name}</MenuItem>
              ))}
            </TextField>
            <TextField
              multiline
              rows={5}
              className="inp"
              variant="outlined"
              type="text"
              label="Note"
              placeholder="Note"
              size="small"
              name="description"
              value={values.description}
              onChange={handleChange}
            />
            <TextField
              className="inp"
              variant="outlined"
              required
              type="date"
              label="Due Date Fielding"
              name="dueDateString"
              value={values.dueDateString}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }} size="small"
            />

            <div className="wrapbutton">
              {Roles("fielding_request_delete") && (
                <Button type="button" className="delete" variant="outlined" onClick={handleDelete}>
                  <Trash /> Delete
                </Button>
              )}
              <Button type="submit" className="save" variant="outlined">
                <Save /> Save
              </Button>
            </div>
          </form>
        </>
      ) : (
        <>
          <h3>Create New Fielding Request</h3>
          <form onSubmit={handleSubmit}>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Project Name"
              placeholder="Project Name"
              size="small"
              value={projectIdActive.projectNumber}
              disabled
            />
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Job Number"
              value={jobNumberIdActive.name}
              size="small"
              disabled
            />
            <TextField
              className="inp"
              name="city"
              value={values.city}
              variant="outlined"
              onChange={handleChange}
              type="text"
              label="City & State"
              placeholder="City & State"
              size="small"
            />
            <TextField
              id="select"
              className="inp"
              label="Fielding Category"
              variant="outlined"
              size="small"
              select
              name="fieldingCategory"
              value={values.fieldingCategory}
              onChange={handleChange}
            >
              {[
                { value: 2, name: "Address Validation" },
                { value: 0, name: "Aerial Fielding" },
                { value: 1, name: "Measure Marker" }]
                .map((item: any, itemIndex: number) => (
                  <MenuItem key={itemIndex} value={item.value}>{item.name}</MenuItem>
                ))}
            </TextField>
            <TextField
              id="select"
              className="inp"
              label="Fielding Request Type"
              variant="outlined"
              size="small"
              select
              name="type"
              value={values.type}
              onChange={handleChange}
            >
              {[{ value: 0, name: "Utility Fielding App" }, { value: 1, name: "Pen & Paper" }, { value: 2, name: "MRE Estimation" }].map((item: any, itemIndex: number) => (
                <MenuItem key={itemIndex} value={item.value}>{item.name}</MenuItem>
              ))}
            </TextField>
            <TextField className="inp" name="dueDateString" variant="outlined" required type="date" onChange={handleChange} label="Due Date Fielding" InputLabelProps={{ shrink: true }} size="small" />
            <TextField
              multiline
              rows={5}
              className="inp"
              name="description"
              variant="outlined"
              onChange={handleChange}
              type="text"
              label="Note"
              placeholder="Note"
              size="small"
            />
            <input
              accept=".kml"
              style={{ display: "none" }}
              id="button-file"
              type="file"
              disabled
            />
            {localFilesKml.length > 0
              ? (
                <>
                  <div className="file-upload">
                    <File size="35" />
                    <div className="file-info">
                      <span>{localFilesKml[0].name}</span>
                      <LinearProgress variant="determinate" value={localFilesKml[0].uploadProgress} />
                    </div>
                    <div className="file-progress">
                      {localFilesKml[0].isUploading ? <span>{localFilesKml[0].uploadProgress}%</span> : <span>Done</span>}
                    </div>
                  </div>
                  <br />
                </>
              )
              : (
                <>
                  <label htmlFor="button-file">
                    <input
                      style={{ display: "none" }}
                      id="addKml"
                      type="file"
                      accept=".kml"
                      // multiple
                      onChange={onChangeKml}
                    />
                    <label htmlFor="addKml">
                      <Button className="upload-btn" component="span">
                        Import from KML
                      </Button>
                    </label>
                  </label>
                  <br />
                </>
              )
            }
            {jsonData.length > 0 && (<span>{jsonData.length} data loaded, </span>)}
            <label htmlFor="button-file">
              <input
                style={{ display: "none" }}
                id="addCsv"
                type="file"
                accept=".csv"
                onChange={onChangeCsv}
              />
              <label htmlFor="addCsv">
                {jsonData.length > 0
                  ? (
                    <Link component="span">
                      change
                    </Link>
                  )
                  : (
                    <Button className="upload-btn" component="span">
                      Import from CSV
                    </Button>
                  )
                }
              </label>
            </label>
            <br />
            {values.type === 1 && (
              <label htmlFor="button-file">
                <input
                  style={{ display: "none" }}
                  id="addAttachment"
                  type="file"
                  accept=".doc,.pdf,.jpg"
                  multiple
                  onChange={onChangeAttachment}
                />
                <label htmlFor="addAttachment">
                  <Button className="upload-btn" component="span">
                    Upload Form
                  </Button>
                </label>
              </label>
            )}
            {localFilesAttachment.map((file: any, i: number) => (
              <>
                <div className="file-upload">
                  <File size="35" />
                  <div className="file-info">
                    <span>{file.name}</span>
                    <LinearProgress variant="determinate" value={file.uploadProgress} />
                  </div>
                  <div className="file-progress">
                    {file.isUploading ? <span>{file.uploadProgress}%</span> : <span>Done</span>}
                  </div>
                </div>
                <br />
              </>
            ))}
            <div className="wrapbutton">
              <Button type="button" className="cancel" variant="outlined" onClick={handleClose}>
                <X /> Cancel
              </Button>
              <Button type="submit" className="save" variant="outlined">
                <Save /> Save
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
