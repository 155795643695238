import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getPoleFieldingRequest = createAsyncThunk("poleInformation/getPoleFielding", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetListForMapByFieldingRequestId`, {
    params: {
      fieldingRequestId: id,
      isActive: true
    },
  });
  return response.data;
});

export const getPoleFieldingRequestInactive = createAsyncThunk("getPoleFieldingRequestInactive", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetListForMapByFieldingRequestId`, {
    params: {
      fieldingRequestId: id,
      isActive: false
    },
  });
  return response.data;
});

export const getPoleFieldingRequestHistory = createAsyncThunk("poleInformation/getPoleFieldingRequestHistory", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetEditLogs`, {
    params: {
      poleId: id,
    },
  });
  return response.data;
});

export const deletePoleFieldingRequest = createAsyncThunk("poleInformation/deletePoleFielding", async (ids: any) => {
  const response = await axios({
    method: "POST",
    url: baseUrl + "/api/Pole/MultiDelete",
    data: ids
  })
  return response.data;
});

export const getPoleLoadingRequest = createAsyncThunk("poleInformation/getPoleOcalc", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetListByOcalcId?ocalcId=` + id);
  return response.data;
});

export const getPolePplx = createAsyncThunk("poleInformation/getPoleOcalcPplx", async () => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetListForMapByPplx`);
  return response.data;
});

export const getPoleInventory = createAsyncThunk("poleInformation/getPoleInventory", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetListByInventoryId?inventoryId=` + id);
  return response.data;
});

export const getPoleInformationEnums = createAsyncThunk("poleInformation/getPoleEnum", async () => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetPoleInformationEnums`);
  return response.data;
});

export const getPoleFieldingRequestByJobNumber = createAsyncThunk("poleInformation/getPoleByJobNumber", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetListForMapByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: true
    },
  });
  return response.data;
});

export const getPoleFieldingRequestByJobNumberInactive = createAsyncThunk("getPoleFieldingRequestByJobNumberInactive", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetListForMapByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: false
    },
  });
  return response.data;
});

export const getMidspanFieldingRequestByJobNumber = createAsyncThunk("poleInformation/getMidspanByJobNumber", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Midspan/GetListByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: true
    },
  });
  return response.data;
});

export const getMidspanFieldingRequestByJobNumberInactive = createAsyncThunk("getMidspanFieldingRequestByJobNumberInactive", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Midspan/GetListByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: false
    },
  });
  return response.data;
});

export const getMarkerFieldingRequestByJobNumber = createAsyncThunk("poleInformation/getMarkerByJobNumber", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/FieldingRequestMarker/GetListByJobNumberId`, {
    params: {
      jobNumberId: id,
    },
  });
  return response.data;
});

export const getPoleDetails = createAsyncThunk("poleInformation/getPole/details", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetDetails`, {
    params: {
      poleId: id,
    },
  });
  return response.data;
});

export const getPoleImageLogs = createAsyncThunk("poleInformation/GetPoleFieldingImageLogs", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/Pole/GetPoleFieldingImageLogs`, {
    params: {
      poleId: id,
    },
  });
  return response.data;
});

export const postPoleFieldingRequest = createAsyncThunk("poleInformation/postPoleFieldingRequest", async (body: any) => {
  try {
    const resPole = await axios.post(`${baseUrl}/api/Pole/Create`, body);
    return resPole.data;
  } catch (error: any) {
    return error.response;
  }
});

export const editPoleFieldingRequest = createAsyncThunk("poleInformation/editPoleFieldingRequest", async (body: any) => {
  try {
    const resPole = await axios.post(`${baseUrl}/api/Pole/Edit`, body);
    return resPole.data;
  } catch (error: any) {
    return error.response;
  }
});

export const setImageAttachmentAsMain = createAsyncThunk("poleInformation/imageAttachment/SetAsMain", async (id: string) => {
  try {
    const response = await axios({
      method: 'put',
      url: baseUrl + '/api/Pole/SetImageAttachmentAsMain?targetAttachmentId=' + id,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }
  catch (error: any) {
    return error.response;
  }
});

export const movePoleAttachment = createAsyncThunk('poleInformation/movePoleAttachment', async (body: any) => {
  try {
    const resFielding = await axios.post(`${baseUrl}​/api/Pole/MovePoleAttachment`, body)
    return resFielding.data
  }
  catch (error: any) {
    return error.response
  }
})

export const deletePoleAttachment = createAsyncThunk("poleInformation/DeletePoleAttachment", async (id: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: baseUrl + '/api/Pole/DeletePoleAttachment',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        "targetAttachmentIds": [
          id
        ]
      })
    });
    return response.data;
  } catch (error: any) {
    return error.response;
  }
});

export const generateAllImages = createAsyncThunk("poleInformation/pole/allimages", async (id: string) => {
  const response = await axios.get(`https://media.utilityfielding.com/GetAllPolePicturesByPoleId`, {
    params: {
      poleId: id,
    },
  });
  return response.data;
});

export const getMidSpanFieldingRequest = createAsyncThunk("poleInformation/getMidSpanFielding", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MidSpan/GetListByFieldingRequestId`, {
    params: {
      fieldingRequestId: id,
      isActive: true
    },
  });
  return response.data;
});

export const getMidSpanFieldingRequestInactive = createAsyncThunk("getMidSpanFieldingRequestInactive", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MidSpan/GetListByFieldingRequestId`, {
    params: {
      fieldingRequestId: id,
      isActive: false
    },
  });
  return response.data;
});

export const postMidspanFieldingRequest = createAsyncThunk("poleInformation/postMidspanFieldingRequest", async (body: any) => {
  try {
    const resPole = await axios.post(`${baseUrl}/api/MidSpan/Create`, body);
    return resPole.data;
  } catch (error: any) {
    return error.response;
  }
});

export const deleteTempMidspanFieldingRequest = createAsyncThunk("deleteTempMidspanFieldingRequest", async (id: string) => {
  const response = await axios({
    method: "DELETE",
    url: baseUrl + "/api/MidSpan/TempDelete",
    params: {
      targetMidSpanId: id,
      userId: localStorage.getItem("id")
    },
  })
  return response.data;
});

export const deleteMidspanFieldingRequest = createAsyncThunk("poleInformation/deleteMidspanFielding", async (id: string) => {
  const response = await axios({
    method: "DELETE",
    url: baseUrl + "/api/Midspan/Delete",
    params: {
      targetMidSpanId: id,
    },
  })
  return response.data;
});

export const editMidspanFieldingRequest = createAsyncThunk("poleInformation/editMidspanFieldingRequest", async (body: any) => {
  try {
    const response = await axios.post(`${baseUrl}/api/Midspan/Edit`, body);
    return response.data;
  } catch (error: any) {
    return error.response;
  }
});

export const getMidspanDetails = createAsyncThunk("getMidspanDetails", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MidSpan/GetDetails`, {
    params: {
      midSpanId: id,
    },
  });
  return response.data;
});

export const getMidspanImageLogs = createAsyncThunk("getMidspanImageLogs", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MidSpan/GetMidSpanFieldingImageLogs`, {
    params: {
      midSpanId: id,
    },
  });
  return response.data;
});

export const generateMidspanAllImages = createAsyncThunk("generateMidspanAllImages", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MidSpan/DownloadAllImages`, {
    params: {
      targetMidSpanId: id,
    },
  });
  return response.data;
});

export const getMidspanHistory = createAsyncThunk("getMidspanHistory", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MidSpan/GetEditLogs`, {
    params: {
      midSpanId: id,
    },
  });
  return response.data;
});

export const postMarkerFielding = createAsyncThunk('poleInformation/postMarkerFielding', async (body: any) => {
  try {
    const resProject = await axios.post(`${baseUrl}/api/FieldingRequestMarker/Create`, body)
    return resProject.data
  }
  catch (error: any) {
    return error.response
  }
})


export const editMarkerFielding = createAsyncThunk('poleInformation/editMarkerFielding', async (body: any) => {
  try {
    const resProject = await axios.post(`${baseUrl}/api/FieldingRequestMarker/Edit`, body)
    return resProject.data
  }
  catch (error: any) {
    return error.response
  }
})

export const getMarkerFieldingByJobnumberId = createAsyncThunk("getMarkerFieldingByJobnumberId", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/FieldingRequestMarker/GetListByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: true
    },
  });
  return response.data;
});

export const getMarkerFieldingByJobnumberIdInactive = createAsyncThunk("getMarkerFieldingByJobnumberIdInactive", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/FieldingRequestMarker/GetListByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: false
    },
  });
  return response.data;
});

export const getMarkerFielding = createAsyncThunk("poleInformation/getMarkerFielding", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/FieldingRequestMarker/GetListByFieldingRequestId`, {
    params: {
      fieldingRequestId: id,
    },
  });
  return response.data;
});

export const deleteMarkerFielding = createAsyncThunk("poleInformation/deleteMarkerFielding", async (ids: any) => {
  const response = await axios({
    method: "POST",
    url: baseUrl + "/api/FieldingRequestMarker/MultiDelete",
    data: ids
  })
  return response.data;
});

export const deleteTempMarkerFielding = createAsyncThunk("poleInformation/deleteTempMarkerFielding", async (id: string) => {
  const response = await axios({
    method: "DELETE",
    url: baseUrl + "/api/FieldingRequestMarker/TempDelete",
    params: {
      targetMarkerId: id,
      userId: localStorage.getItem("id")
    },
  })
  return response.data;
});

export const getTempDeleteMarkerFielding = createAsyncThunk("poleInformation/getTempDeleteMarkerFielding", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/FieldingRequestMarker/GetTempDeletedList`, {
    params: {
      fieldingRequestId: id,
    },
  });
  return response.data;
});

export const undoDeleteMarkerFielding = createAsyncThunk("poleInformation/undoDeleteMarkerFielding", async (ids: any) => {
  const response = await axios({
    method: "POST",
    url: baseUrl + "/api/FieldingRequestMarker/MultiUndoDelete",
    params: {
      userId: localStorage.getItem("id")
    },
    data: ids
  })
  return response.data;
});

//measure marker

export const createMeasureMarker = createAsyncThunk('createMeasureMarker', async (body: any) => {
  try {
    const response = await axios.post(`${baseUrl}/api/MeasurementMarker/Create`, body)
    return response.data
  }
  catch (error: any) {
    return error.response
  }
})

export const getMeasureMarker = createAsyncThunk("getMeasureMarker", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MeasurementMarker/GetListByFieldingRequestId`, {
    params: {
      fieldingRequestId: id,
      isActive: true
    },
  });
  return response.data;
});

export const getMeasureMarkerInactive = createAsyncThunk("getMeasureMarkerInactive", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MeasurementMarker/GetListByFieldingRequestId`, {
    params: {
      fieldingRequestId: id,
      isActive: false
    },
  });
  return response.data;
});

export const getMeasureMarkerByJob = createAsyncThunk("getMeasureMarkerByJob", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MeasurementMarker/GetListByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: true
    },
  });
  return response.data;
});

export const getMeasureMarkerByJobInactive = createAsyncThunk("getMeasureMarkerByJobInactive", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MeasurementMarker/GetListByJobNumberId`, {
    params: {
      jobNumberId: id,
      isActive: false
    },
  });
  return response.data;
});

export const deleteMeasureMarker = createAsyncThunk("deleteMeasureMarker", async (id: string) => {
  const response = await axios({
    method: "DELETE",
    url: baseUrl + "/api/MeasurementMarker/Delete",
    params: {
      targetMeasurementMarkerId: id,
    },
  })
  return response.data;
});

export const deleteTempMeasureMarker = createAsyncThunk("deleteTempMeasureMarker", async (id: string) => {
  const response = await axios({
    method: "DELETE",
    url: baseUrl + "/api/MeasurementMarker/TempDelete",
    params: {
      measurementMarkerId: id,
      userId: localStorage.getItem("id")
    },
  })
  return response.data;
});

export const undoDeleteTempMeasureMarker = createAsyncThunk("undoDeleteTempMeasureMarker", async (id: string) => {
  const response = await axios({
    method: "DELETE",
    url: baseUrl + "/api/MeasurementMarker/UndoDelete",
    params: {
      measurementMarkerId: id,
      userId: localStorage.getItem("id")
    },
  })
  return response.data;
});

export const editMeasureMarker = createAsyncThunk('editMeasureMarker', async (body: any) => {
  try {
    const response = await axios.post(`${baseUrl}/api/MeasurementMarker/Edit`, body)
    return response.data
  }
  catch (error: any) {
    return error.response
  }
})

export const createCommentMeasureMarker = createAsyncThunk('createCommentMeasureMarker', async (body: any) => {
  try {
    const response = await axios.post(`${baseUrl}/api/MeasurementMarker/CreateComment`, body)
    return response.data
  }
  catch (error: any) {
    return error.response
  }
})

export const getCommentMeasureMarker = createAsyncThunk('getCommentMeasureMarker', async (id: any) => {
  try {
    const response = await axios.get(`${baseUrl}/api/MeasurementMarker/GetComments`, {
      params: {
        measurementMarkerId: id
      }
    })
    return response.data
  } catch (error: any) {
    return error.response
  }
})

export const moveMeasureMarker = createAsyncThunk('moveMeasureMarker', async (body: any) => {
  try {
    const response = await axios.post(`${baseUrl}/api/MeasurementMarker/MoveLocation`, body)
    return response.data
  }
  catch (error: any) {
    return error.response
  }
})

export const getMeasureMarkerDetails = createAsyncThunk("getMeasureMarkerDetails", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MeasurementMarker/GetDetails`, {
    params: {
      targetMeasurementMarkerId: id,
    },
  });
  return response.data;
});

export const getMeasureMarkerImageLogs = createAsyncThunk("getMeasureMarkerImageLogs", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MeasurementMarker/GetMeasurementMarkerFieldingImageLogs`, {
    params: {
      measurementMarkerId: id,
    },
  });
  return response.data;
});

export const deleteMeasureMarkerAttachment = createAsyncThunk('deleteMeasureMarkerAttachment', async (id: string) => {
  try {
    const response = await axios.post(`${baseUrl}/api/MeasurementMarker/DeletePoleAttachment`, { targetAttachmentIds: [id] })
    return response.data
  }
  catch (error: any) {
    return error.response
  }
})

export const generateMeasureMarkerImages = createAsyncThunk("generateMeasureMarkerImages", async (id: string) => {
  const response = await axios.get(`${baseUrl}/api/MeasurementMarker/DownloadAllImages`, {
    params: {
      targetMeasurementMarkerId: id,
    },
  });
  return response.data;
});

export const deleteMultipleMeasureMarker = createAsyncThunk("deleteMultipleMeasureMarker", async (ids: Array<string>) => {
  const response = await axios({
    method: "DELETE",
    url: baseUrl + "/api/MeasurementMarker/MultiDelete",
    data: ids,
  })
  return response.data;
});

export const undoDeleteMultipleMeasureMarker = createAsyncThunk("undoDeleteMultipleMeasureMarker", async (ids: Array<string>) => {
  const response = await axios({
    method: "POST",
    url: baseUrl + "/api/MeasurementMarker/MultiUndoDelete",
    data: ids,
  })
  return response.data;
});