
import { Button, Container } from '@material-ui/core';
import ModalTemplate from '../../../../shared/ModalTemplate';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Template from '../../../../shared/Template'
import FormCompany from '../component/FormCompany';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompany, getCompany } from '../company.api';
import LoadingCustom from '../../../../shared/LoadingCustom';
import Swal from 'sweetalert2';
import MUIDataTable from 'mui-datatables'


function Company() {

    const dispatch = useDispatch()

    const { listCompany, loading } = useSelector((state: RootState) => state.company)

    const [modal, setModal] = useState<any>({})

    useEffect(() => {
        dispatch(getCompany())
    }, [])

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Are you sure you want to delete this company?',
            text: "You won’t be able to undo this change!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch<any>(deleteCompany(id))
                    .unwrap()
                    .then((res: any) => {
                        console.log(res, 'saya resssssss')
                        if (res.status >= 400) {
                            Swal.fire(
                                'Delete Company Failed!',
                                `${res.data.message || Object.values(res.data.errors)}`,
                                'error'
                            )
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'Company Successfully Deleted',
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then(() => {
                                    dispatch(getCompany())
                                })
                        }
                    })

            }
        })

    }

    const handleAdd = () => {
        setModal({
            status: true,
            type: 'create',
            data: {}
        })
    }

    const handleEdit = (id: string) => {
        setModal({
            status: true,
            type: 'edit',
            data: listCompany?.filter((item: any) => item.id === id)[0]
        })
    }

    let component = () => (
        <>
            <LoadingCustom isLoading={loading} />
            <Container maxWidth={false} className="conten">
                <ModalTemplate
                    component={() => <FormCompany type={modal.type} data={modal.data} onClose={() => setModal({ status: false, data: {} })} />}
                    onOpen={modal.status}
                />
                <div className="account">
                    <MUIDataTable
                        title={"Company"}
                        data={listCompany}
                        columns={[
                            {
                                name: "name",
                                label: "Company Name"
                            },
                            {
                                name: "address",
                                label: "Address"
                            },
                            {
                                name: "createdDateString",
                                label: "Created Date"
                            },
                            {
                                name: "id",
                                label: "Action",
                                options: {
                                    customBodyRender: (value: any) => (
                                        <div className='action-button'>
                                            <Button className="button-edit" size='small' variant='contained' onClick={() => handleEdit(value)}>Edit</Button>
                                            <Button className="button-delete" size='small' variant='contained' onClick={() => handleDelete(value)}>Delete</Button>
                                        </div>
                                    ),
                                    filter: false,
                                    download: false
                                }
                            }
                        ]}
                        options={{
                            customToolbar: (data: any) => {
                                return (
                                    <Button className="button-add" variant='contained' onClick={handleAdd}>Create Company</Button>
                                )
                            },
                            selectableRows: "none",
                            rowsPerPageOptions: [50, 100, 200],
                            rowsPerPage: 50,
                            downloadOptions: {
                                filename: "utility fielding - company.csv"
                            },
                            print: false
                        }}
                    />

                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default Company;