import { Button, TextField, MenuItem } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Save, X } from 'react-feather'
import Swal from 'sweetalert2'

export default function FormPoleLoading(props: any) {

    let [formValue, setFormValue] = useState<any>({})

    useEffect(() => {
        if (props.data?.id) {
        }
    }, [props.data])

    const handleChange = (e: any) => {
        let { name, value } = e.target
        setFormValue({
            ...formValue,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        if (props.type === 'create') {
            Swal.fire({
                icon: 'success',
                title: 'Pole loading successfully created',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else if (props.type === 'edit') {
            Swal.fire({
                icon: 'success',
                title: 'Pole loading successfully edited',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <div className='formaccount'>
            <h3>{props.type === 'create' ? "Create" : props.type === 'edit' ? "Edit" : ""} Pole Loading<X onClick={props.onClose} /></h3>
            <form onSubmit={handleSubmit}>
                <TextField id="select" className='inp'
                    label="Lorem"
                    required
                    name='companyId'
                    placeholder='Choose company'
                    onChange={handleChange}
                    value={formValue.companyId}
                    variant="outlined"
                    size='small' select
                >
                    <MenuItem value={"sit"}>{"sit"}</MenuItem>
                    <MenuItem value={"dolor"}>{"dolor"}</MenuItem>
                    <MenuItem value={"amet"}>{"amet"}</MenuItem>
                </TextField>
                <TextField
                    className='inp'
                    name='Impum'
                    onChange={handleChange}
                    value={formValue.firstName}
                    variant="outlined"
                    type='text'
                    label="Ipsum"
                    size='small'
                    required
                />
                <div className="wrapbutton">
                    <Button type='submit' className="save" variant='outlined'><Save />Save</Button>
                </div>
            </form>
        </div>
    )
}


