import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getJobNumber, getProject } from "../sideTab.api";
import Project from "../components/project/Project";
import JobNumber from "../components/jobnumber/JobNumber";
import JobDetail from "../components/jobdetail/JobDetail";
import JobDetailMenu from "../components/jobdetailmenu/JobDetailMenu";
import { setSidetabActive } from "../sideTab.reducer";

function SideTab(props: any) {
  const dispatch = useDispatch();

  const { projectIdActive, jobNumberIdActive, statusJobNumber, sideTabActive, detailMenu } = useSelector((state: RootState) => state.sideTab);

  const [lastSideTabActive, setLastSideTabActive] = useState({})

  useEffect(() => {
    dispatch(getProject(localStorage.getItem("companyIdSelected")));
    if (sideTabActive === undefined) {
      dispatch(setSidetabActive({ project: true, jobNumber: false, jobDetail: false, jobDetailMenu: false }))
    }
  }, []);

  useEffect(() => {
    projectIdActive.id && dispatch(getJobNumber({
      id: projectIdActive.id,
      status: statusJobNumber
    }));
  }, [projectIdActive]);

  useEffect(() => {
    const valuesLast = Object.values(lastSideTabActive)
    const values = Object.values(sideTabActive)
    if (valuesLast.toString() !== values.toString()) {
      const index = valuesLast.map((v, i) => v !== values[i]).indexOf(true)
      const valuesNew = [...values]
      let indexToFalse = -1
      if (valuesNew.filter(v => v).length > 2) {
        indexToFalse = valuesNew.indexOf(true)
        if (index <= indexToFalse) {
          indexToFalse = valuesNew.lastIndexOf(true)
        }
        if (indexToFalse !== -1) valuesNew[indexToFalse] = false
      }
      dispatch(setSidetabActive({
        project: valuesNew[0],
        jobNumber: valuesNew[1],
        jobDetail: valuesNew[2],
        jobDetailMenu: valuesNew[3]
      }))
      setLastSideTabActive(sideTabActive)
    }
  }, [sideTabActive])

  return (
    <div className="sidetab">
      <div className="tab-header-container">
        <div className={`tab-header ${sideTabActive?.project ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, project: !sideTabActive?.project }))}>
          <span>
            Project
          </span>{" "}
        </div>
        <div className={`tab-header ${sideTabActive?.jobNumber && Boolean(projectIdActive.id) ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, jobNumber: !sideTabActive?.jobNumber }))}>
          <span>
            Job Number
          </span>{" "}
        </div>
        <div className={`tab-header ${(sideTabActive?.jobDetail && Boolean(jobNumberIdActive.id)) ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, jobDetail: !sideTabActive?.jobDetail }))}>
          <span>
            Job Detail
          </span>{" "}
        </div>
        {Boolean(detailMenu.id) && (
          <div className={`tab-header ${(sideTabActive?.jobDetailMenu && Boolean(jobNumberIdActive.id)) ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, jobDetailMenu: !sideTabActive?.jobDetailMenu }))}>
            <span>
              {detailMenu.label}
            </span>{" "}
          </div>
        )}
      </div>
      <div className="tab-content-container">
        {sideTabActive?.project && <Project />}
        {(sideTabActive?.jobNumber && Boolean(projectIdActive.id)) && <JobNumber />}
        {(sideTabActive?.jobDetail && Boolean(jobNumberIdActive.id)) && <JobDetail />}
        {(sideTabActive?.jobDetailMenu && Boolean(jobNumberIdActive.id)) && <JobDetailMenu />}
      </div>
    </div>
  );
}

export default SideTab;
