import { FormControlLabel, Checkbox, Tooltip, TextField, MenuItem } from "@material-ui/core";
import moment from "moment";

import shapeNote from "../../../../assets/icons/shapeNote.png";
import tree from "../../../../assets/icons/tree.png";
import anchor from "../../../../assets/icons/anchor.png";
import cableAerial from "../../../../assets/icons/cableAerial.png";
import point from "../../../../assets/icons/point.png";
import { RootState } from "../../../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { handleFieldingIcon, handleLayerActive, handleNoteIcon, setStatusNotes } from "../../sideTab.reducer";
import { setCenter, setNotePathModal, setNotePointModal, setNoteShapeModal, setNoteToShow } from "../../../dashboard/dashboard.reducer"
import { useEffect, useState } from "react";
import pencilBlack from "../../../../assets/icons/pencilBlack.png";
import pencilGreen from "../../../../assets/icons/pencilGreen.png";
import { Info } from "react-feather";

function Notes() {
  const dispatch = useDispatch();
  const { noteIcon, isLayerActive, noteList, noteEnums, statusNotes } = useSelector((state: RootState) => state.sideTab);
  const { noteToShow } = useSelector((state: RootState) => state.dashboard);

  const [typesSorted, setTypesSorted] = useState([])

  useEffect(() => {
    let types = JSON.parse(JSON.stringify(noteEnums?.markerTypes))
    types.sort((a: any, b: any) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    })
    setTypesSorted(types)
  }, [noteEnums])

  const handleChange = (e: number) => {
    dispatch(handleNoteIcon(e))
  }

  let parseDate = (d: any) => {
    let date = moment(new Date(d)).format("MM-DD-yyyy hh:mm:ss");
    return date
  }

  const handleClickNote = (note: any) => {
    if (note?.lineData != '') {
      let coord = JSON.parse(note.lineData)
      if (coord[0]) {
        dispatch(setCenter({ latitude: coord[0].lat, longitude: coord[0].lng }))
      }
    }
    else {
      dispatch(setCenter({ latitude: note?.latitude, longitude: note?.longitude }))
    }
  }

  const handleCheck = (e: any) => {
    let name = parseInt(e.target.name)
    let checked = e.target.checked
    let noteToShowNew: any = []
    if (name === 0) {
      noteToShowNew = noteToShow?.filter((item: any) => item !== 0 && item !== 1);
    }
    else {
      noteToShowNew = noteToShow?.filter((item: any) => item !== name);
    }
    if (checked) {
      if (name === 0) {
        noteToShowNew = noteToShowNew.concat([0, 1])
      }
      else {
        noteToShowNew = [...noteToShowNew, name]
      }
    }
    dispatch(setNoteToShow(noteToShowNew))
  }

  const handleClickPoly = (note: any) => {
    dispatch(setNoteShapeModal({ status: true, data: note }))
  }

  const handleCLickLine = (note: any) => {
    dispatch(setNotePathModal({ status: true, data: note }))
  }

  const handleCLickPoint = (note: any) => {
    dispatch(setNotePointModal({ status: true, data: note }))
  }

  const handleClickStar = () => {
    dispatch(handleFieldingIcon(null))
    if (!isLayerActive.status) {
      dispatch(handleNoteIcon(0))
    }
    else {
      dispatch(handleNoteIcon(null))
    }
    dispatch(handleLayerActive({ ...isLayerActive, note: true, status: !isLayerActive.status }))
  }

  return (
    <div className="note">
      <div className="wrap-fil">
        <h4>Notes on Map</h4>
        <FormControlLabel control={<Checkbox color="primary" name="0" checked={noteToShow.includes(0) || noteToShow.includes(1)} onChange={handleCheck} />} label="Symbol" />
        <br />
        <FormControlLabel control={<Checkbox color="primary" name="2" checked={noteToShow.includes(2)} onChange={handleCheck} />} label="Shape" />
        <br />
        <FormControlLabel control={<Checkbox color="primary" name="3" checked={noteToShow.includes(3)} onChange={handleCheck} />} label="Line" />
        <br />
        <FormControlLabel control={<Checkbox color="primary" name="4" checked={noteToShow.includes(4)} onChange={handleCheck} />} label="Point" />
      </div>
      <div className="markicon">
        <h4>
          <span>Add Note to Map</span>
          <img height={18} src={isLayerActive.note && isLayerActive.status ? pencilGreen : pencilBlack} alt="star" onClick={handleClickStar} />
        </h4>
        {isLayerActive.note && isLayerActive.status ? (
          <div className="wrapmark">
            {typesSorted?.map((type: any, i: number) => {
              let icon = [anchor, tree, shapeNote, cableAerial, point]
              return (
                <Tooltip title={type.display}>
                  <img className={noteIcon === type.value ? "active" : ""} src={icon[type.value]} alt="mark" onClick={() => handleChange(type.value)} />
                </Tooltip>
              )
            })}
          </div>
        ) : (
          <span>Select the pencil to add, edit, and remove notes to map</span>
        )}
      </div>
      <br />
      <TextField className="inp" variant="outlined" size="small" select value={statusNotes} onChange={e => dispatch(setStatusNotes(parseInt(e.target.value)))}>
        <MenuItem value={0}>Active</MenuItem>
        <MenuItem value={1}>Completed</MenuItem>
      </TextField>
      <div className="note-list">
        <h4>Shape Notes</h4>
        {noteList.filter((item: any) => item.status === statusNotes).map((note: any, index: number) =>
          (note.markerType === 2 && noteToShow.includes(2)) && (
            <div className="note-container" key={index} onClick={() => handleClickNote(note)}>
              <span className="note-header">{note.name}<Info onClick={() => handleClickPoly(note)} /></span>
              <span className="note-content">{parseDate(note.createdDate)}</span>
            </div>
          ))}
      </div>
      <div className="note-list">
        <h4>Line Notes</h4>
        {noteList.filter((item: any) => item.status === statusNotes).map((note: any, index: number) =>
          (note.markerType === 3 && noteToShow.includes(3)) && (
            <div className="note-container" key={index} onClick={() => handleClickNote(note)}>
              <span className="note-header">{note.name}<Info onClick={() => handleCLickLine(note)} /></span>
              <span className="note-content">{parseDate(note.createdDate)}</span>
            </div>
          ))}
      </div>
      <div className="note-list">
        <h4>Point Notes</h4>
        {noteList.filter((item: any) => item.status === statusNotes).map((note: any, index: number) =>
          (note.markerType === 4 && noteToShow.includes(4)) && (
            <div className="note-container" key={index} onClick={() => handleClickNote(note)}>
              <span className="note-header">{note.name}<Info onClick={() => handleCLickPoint(note)} /></span>
              <span className="note-content">{parseDate(note.createdDate)}</span>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Notes;
