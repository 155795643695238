import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



function PDFViewer(props:any) {

    const [numPages, setNumPages] = useState<number | null>(null)

    const onDocumentLoadSuccess = ({ numPages }:any) => {
        setNumPages(numPages);
      }
    return (

            <Document 
                file={props.pdf}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode='svg'
            >
                {/* <Page pageNumber={2} /> */}
                {Array.from(new Array(numPages), (el, index) => (
                    <Page  key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}

            </Document>


    );
}

export default PDFViewer;