
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../state';

export const getProfile = createAsyncThunk("profile/getProfile", async (key: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Account/GetProfile`, {
            params: {
                searchKey: key,
            },
        });
        return response.data;
    }
    catch (error: any) {
        return error.response
    }
});
