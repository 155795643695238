import { createSlice } from '@reduxjs/toolkit'

export interface JpaState {
  value: number,
  listJpa : any,
  loading : boolean
}
const initialState: JpaState = {
  value: 0,
  listJpa : [],
  loading : false
}

export const counterSlice = createSlice({
    name: 'jpa',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
    },
})

export default counterSlice.reducer