import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { handleLayerActive, handleLoadingRequestIdActive, handleModalLoadingRequest, setLoadingRequestIdList } from "../../sideTab.reducer";
import { useEffect, useState } from "react";
import { RootState } from "../../../../app/store";
import { Save, Trash, X } from "react-feather";
import { deleteLoadingRequest, editLoadingRequest, getLoadingRequest, postLoadingRequest } from "../../sideTab.api";
import { setIsDragSelectActive, setSelectedIdMarkers } from "../../../dashboard/dashboard.reducer";
import moment from "moment";
import { setIsShowPoleFieldingRequest, setIsShowPoleLoadingRequest, setListPoleLoadingRequest } from "../../../poleInformation/poleInformation.reducer";
import { getPoleLoadingRequest } from "../../../poleInformation/poleInformation.api";
import { Autocomplete } from "@material-ui/lab";

export default function LoadingRequestForm(props: any) {

  const dispatch = useDispatch();

  const { projectIdActive, jobNumberIdActive, modalLoadingRequest, loadingRequestIdActive } = useSelector((state: RootState) => state.sideTab);
  const { selectedIdMarkers } = useSelector((state: RootState) => state.dashboard);
  const { listPoleLoadingRequest } = useSelector((state: RootState) => state.poleInformation);
  const { listAccount } = useSelector((state: RootState) => state.account);

  const [values, setValues] = useState<any>({});
  const [calcSpecmanagers, setCalcSpecManagers] = useState<any>([])

  useEffect(() => {
    if (modalLoadingRequest.formType === "edit") {
      let poleNumber = listPoleLoadingRequest.length
      let completedIn = 3
      if (poleNumber > 10) {
        completedIn = 3 + Math.ceil((poleNumber - 10) / 5)
      }
      let dateNow = new Date()
      let completedDate = null
      let count = 0
      while (count < completedIn) {
        completedDate = new Date(dateNow.setDate(dateNow.getDate() + 1));
        if (completedDate.getDay() != 0 && completedDate.getDay() != 6) {
          count++;
        }
      }
      let y = completedDate?.getFullYear()
      let mPlus = (completedDate?.getMonth() || 0) + 1
      let m = mPlus < 10 ? "0" + mPlus : mPlus
      let d = ((completedDate?.getDate() || 0) < 10 ? "0" + completedDate?.getDate() : completedDate?.getDate())
      setValues({
        ...values,
        dueDateString: (y + '-' + m + '-' + d),
        description: loadingRequestIdActive.description
      })
    }
    else if (modalLoadingRequest.formType === "edit-detail") {
      setValues({
        dueDateString: loadingRequestIdActive.dueDate?.split('T')[0],
        description: loadingRequestIdActive.description
      })
      let assignedCalcSpecManagerIds = loadingRequestIdActive.assignedCalcSpecManagerIds ? loadingRequestIdActive.assignedCalcSpecManagerIds.split(', ') : []
      let filteredAccount = listAccount.filter((item: any) => assignedCalcSpecManagerIds.includes(item.id))
      setCalcSpecManagers(filteredAccount)
    }
    else {
      setValues({
        ...values,
        jobNumberId: jobNumberIdActive?.id,
        description: "",
        poleIds: selectedIdMarkers
      })
    }
  }, [jobNumberIdActive, selectedIdMarkers])

  useEffect(() => {
  }, [selectedIdMarkers])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClose = () => {
    dispatch(handleModalLoadingRequest({ isOpen: false, formType: "" }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch<any>(postLoadingRequest({
      ...values,
      dueDateString: moment(new Date()).format("MM/DD/yyyy"),
      calcSpecManagerIds: calcSpecmanagers.map((item: any) => item.id)
    }))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Create Loading Requests failed!");
        }
        else {
          Swal.fire({
            icon: "success",
            title: "Loading request successfully created",
            showConfirmButton: false,
            timer: 1500,
          })
          handleClose()
          dispatch(setIsShowPoleFieldingRequest(false))
          dispatch(setIsShowPoleLoadingRequest(true))
          dispatch(setLoadingRequestIdList([res?.data?.id]))
          dispatch<any>(getPoleLoadingRequest(res?.data?.id))
            .unwrap()
            .then((response: any) => {
              dispatch(setListPoleLoadingRequest(response.data));
            })
          dispatch(setIsDragSelectActive(false))
          dispatch(setSelectedIdMarkers([]))
          dispatch(getLoadingRequest(jobNumberIdActive.id));
          dispatch(
            handleLayerActive({
              layerJobNumberId: "",
              layerFieldingId: "",
              layerLoadingId: res.id,
              note: false,
              status: true,
              label: "loading"
            })
          )
          dispatch(handleLoadingRequestIdActive(res.data));
        }
      })
  };

  const handleDelete = () => {
    dispatch<any>(deleteLoadingRequest(loadingRequestIdActive.id))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Delete loading request failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Loading request successfully deleted",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            dispatch(getLoadingRequest(jobNumberIdActive.id));
            dispatch(handleLayerActive({}));
            handleClose()
            clearPoleOnId(loadingRequestIdActive.id)
          });
        }
      })
  }

  const clearPoleOnId = (id: any) => {
    let poleLoadingRequest: any = listPoleLoadingRequest?.filter((item: any) => item?.ocalcId != id);
    dispatch(setListPoleLoadingRequest(poleLoadingRequest));
  }

  const handleEdit = (e: any) => {
    e.preventDefault();
    dispatch<any>(editLoadingRequest({
      id: loadingRequestIdActive.id,
      description: values.description,
      dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy"),
      calcSpecManagerIds: calcSpecmanagers.map((item: any) => item.id),
      calcSpecIds: loadingRequestIdActive.assignedCalcSpecIds ? loadingRequestIdActive.assignedCalcSpecIds.split(', ') : []
    }))
      .unwrap()
      .then((res: any) => {
        console.log(res, "saya resssssss");
        if (res.status >= 400) {
          Swal.fire("Edit Loading Request failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Loading request successfully edited",
            showConfirmButton: false,
            timer: 1500,
          }).then((res: any) => {
            handleClose();
            dispatch(getLoadingRequest(jobNumberIdActive.id));
            dispatch(handleLoadingRequestIdActive({}))
            dispatch(handleLayerActive({}))
          });
        }
      });
  };

  return (
    <div className="formproject">
      {modalLoadingRequest.formType === "edit-detail" ? (
        <>
          <h3>Detail Loading Request<X onClick={handleClose} /></h3>
          <form onSubmit={handleEdit}>
            <TextField
              className="inp"
              variant="outlined"
              label="Project"
              size="small"
              disabled
              value={jobNumberIdActive.project}
            />
            <TextField
              className="inp"
              variant="outlined"
              label="Job Number"
              size="small"
              disabled
              value={jobNumberIdActive.name}
            />
            <TextField
              className="inp"
              name="description"
              variant="outlined"
              type="text"
              label="Description"
              size="small"
              multiline
              value={values?.description}
              onChange={handleChange}
            />
            <TextField
              className="inp"
              variant="outlined"
              type="number"
              label="Quantity"
              size="small"
              disabled
              value={listPoleLoadingRequest.length}
            />
            <TextField
              className="inp"
              variant="outlined"
              type="date"
              label="Completion Date"
              name="dueDateString"
              value={values?.dueDateString}
              onChange={handleChange}
            />
            <Autocomplete
              multiple
              options={listAccount.filter((item: any) => item.companyId === localStorage.getItem("companyIdSelected") && item.roles.includes("CalcSpecManager"))}
              getOptionLabel={(item => item.email)}
              value={calcSpecmanagers}
              onChange={(e, value) => setCalcSpecManagers(value)}
              className="inp"
              renderInput={(params) => <TextField {...params} label="Loading Management" size="small" variant="outlined" />}
            />
            <br />
            <div className="wrapbutton">
              <Button type="button" className="delete" variant="outlined" onClick={handleDelete}>
                <Trash /> Delete
              </Button>
              <Button type="submit" className="save" variant="outlined">
                <Save /> Save
              </Button>
            </div>
          </form>
        </>
      ) : modalLoadingRequest.formType === "edit"
        ? (<>
          <h3>Edit Loading Request<X onClick={handleClose} /></h3>
          <form onSubmit={handleEdit}>
            <TextField
              className="inp"
              name="description"
              variant="outlined"
              type="text"
              label="Description"
              size="small"
              multiline
              value={values?.description}
              onChange={handleChange}
            />
            <TextField
              className="inp"
              variant="outlined"
              type="number"
              label="Quantity"
              size="small"
              disabled
              value={listPoleLoadingRequest.length}
            />
            <TextField
              className="inp"
              variant="outlined"
              type="date"
              label="Completion Date"
              name="dueDateString"
              value={values?.dueDateString}
              onChange={handleChange}
            />
            <div className="wrapbutton">
              <Button type="submit" className="save" variant="outlined">
                <Save /> Save
              </Button>
            </div>
          </form>
        </>
        )
        : (<>
          <h3>Create New Loading Request<X onClick={handleClose} /></h3>
          <form onSubmit={handleSubmit}>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Project Name"
              placeholder="Project Name"
              size="small"
              disabled
              value={projectIdActive?.projectNumber}
            />
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Job Number"
              size="small"
              disabled
              value={jobNumberIdActive?.name}
            />
            <TextField
              className="inp"
              name="description"
              variant="outlined"
              type="text"
              label="Description"
              size="small"
              multiline
              value={values?.description}
              onChange={handleChange}
            />
            <Autocomplete
              multiple
              options={listAccount.filter((item: any) => item.companyId === localStorage.getItem("companyIdSelected") && item.roles.includes("CalcSpecManager"))}
              getOptionLabel={(item => item.email)}
              value={calcSpecmanagers}
              onChange={(e, value) => setCalcSpecManagers(value)}
              className="inp"
              renderInput={(params) => <TextField {...params} label="Loading Management" size="small" variant="outlined" />}
            />
            <div className="wrapbutton">
              <Button className="save" type="submit" variant="outlined">
                Save
              </Button>
            </div>
          </form>
        </>
        )}
    </div>
  );
}
