
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../state'

export const postProject = createAsyncThunk('project/postProject', async (body: any) => {
    try {
        const resProject = await axios.post(`${baseUrl}/api/Project/Create`, body)
        return resProject.data
    }
    catch (error: any) {
        return error.response
    }
})

export const editProject = createAsyncThunk('project/editProject', async (body: any) => {
    try {
        const resProject = await axios.post(`${baseUrl}/api/Project/Edit`, body)
        return resProject.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getProject = createAsyncThunk('project/getProject', async (companyId: any) => {
    if (companyId === "null") {
        let data: any = []
        return data
    }
    else {
        const email = localStorage.getItem("email");
        const userId = localStorage.getItem("id");
        try {
            const response = await axios({
                method: 'get',
                url: baseUrl + '/api/Project/GetListAndSharedProject?companyId=' + companyId + '&email=' + email + '&userId=' + userId,
                headers: {}
            })
            return response.data
        }
        catch (error: any) {
            return error.response
        }
    }
})

export const deleteProject = createAsyncThunk('project/deleteProject', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Project/Delete`, {
            params: {
                targetProjectId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

//jobnumber
export const postJobNumber = createAsyncThunk('jobnumber/postJobNumber', async (body: any) => {
    try {
        const resJobNumber = await axios.post(`${baseUrl}/api/JobNumber/Create`, body)
        return resJobNumber.data
    }
    catch (error: any) {
        return error.response
    }
})

export const editJobNumber = createAsyncThunk('jobnumber/edtJobNumber', async (body: any) => {
    try {
        const resJobNumber = await axios.post(`${baseUrl}/api/JobNumber/Edit`, body)
        return resJobNumber.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getJobNumber = createAsyncThunk('jobnumber/JobNumberActivebyId', async (data: any) => {
    const userId = localStorage.getItem("id");
    try {
        const response = await axios.get(`${baseUrl}/api/JobNumber/GetListByProjectId`, {
            params: {
                projectId: data.id,
                statusValue: data.status,
                userId: userId
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getJobNumberChoose = createAsyncThunk('getJobNumberChoose', async (data: any) => {
    const userId = localStorage.getItem("id");
    try {
        const response = await axios.get(`${baseUrl}/api/JobNumber/GetListByProjectId`, {
            params: {
                projectId: data.id,
                statusValue: data.status,
                userId: userId
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteJobNumber = createAsyncThunk('jobnumber/deleteJobNumber', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/JobNumber/Delete`, {
            params: {
                targetJobNumberId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getJobNumberDetails = createAsyncThunk('jobnumber/getJobNumberDetails', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/JobNumber/GetDetails`, {
            params: {
                jobNumberId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const generateKmlJob = createAsyncThunk('jobnumber/generateKmlJob', async (id: any) => {
    try {
        const resJobNumber = await axios.get(`${baseUrl}/api/JobNumber/ExportKML`, {
            params: {
                targetJobNumberId: id
            }
        })
        return resJobNumber.data
    }
    catch (error: any) {
        return error.response
    }
})

export const generateKmlFielding = createAsyncThunk('jobnumber/generateKmlFielding', async (id: any) => {
    try {
        const resJobNumber = await axios.get(`${baseUrl}/api/FieldingRequest/ExportKML`, {
            params: {
                targetFieldingRequestId: id
            }
        })
        return resJobNumber.data
    }
    catch (error: any) {
        return error.response
    }
})

export const generatePictureJob = createAsyncThunk('jobnumber/generatePictureJob', async (id: any) => {
    try {
        const resJobNumber = await axios({
            method: 'get',
            url: `https://media.utilityfielding.com/GetAllPolePictures`,
            params: {
                jobNumberId: id
            }
        })
        return resJobNumber.data
    }
    catch (error: any) {
        return error.response
    }
})

export const generateKmlMultipleJob = createAsyncThunk('jobnumber/generateKmlMultipleJob', async (body: any) => {
    try {
        const resJobNumber = await axios.post(`${baseUrl}/api/JobNumber/ExportKMLFromMultiJob`, body)
        return resJobNumber.data
    }
    catch (error: any) {
        return error.response
    }
})

export const generateKmlMultipleFielding = createAsyncThunk('jobnumber/generateKmlMultipleFielding', async (body: any) => {
    try {
        const resJobNumber = await axios.post(`${baseUrl}/api/fieldingrequest/ExportKMLFromMultiFieldingRequest`, body)
        return resJobNumber.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getJobNumberAttachmentList = createAsyncThunk('jobnumber/getJobNumberAttachmentList', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/JobNumber/GetJobNumberAttachmentList`, {
            params: {
                jobNumberId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteJobNumberAttachment = createAsyncThunk('jobnumber/deleteJobNumberAttachment', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/JobNumber/DeleteJobNumberAttachment`, {
            params: {
                jobNumberAttachmentId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const completeJobNumber = createAsyncThunk('jobnumber/completeJobNumber', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/api/JobNumber/CompleteJobNumber?targetJobNumberId=' + id,
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const completeFieldingRequest = createAsyncThunk('jobnumber/completeFieldingRequest', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/api/FieldingRequest/CompleteFieldingRequest?targetFieldingRequestId=' + id,
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const reactiveFieldingRequest = createAsyncThunk('jobnumber/reactiveFieldingRequest', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/api/FieldingRequest/ReactiveFielding?targetFieldingRequestId=' + id,
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const reactiveJobNumber = createAsyncThunk('jobnumber/reactiveJobNumber', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/api/JobNumber/ReactiveJobNumber?targetJobNumberId=' + id,
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const archiveJobNumber = createAsyncThunk('jobnumber/archiveJobNumber', async (id: any) => {
    try {
        const response = await axios({
            method: 'put',
            url: baseUrl + '/api/JobNumber/ArchiveJobNumber?targetJobNumberId=' + id,
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

//areial fielding
export const postFieldingRequest = createAsyncThunk('project/postFielding', async (body: any) => {
    try {
        const resFielding = await axios.post(`${baseUrl}​/api/FieldingRequest/Create`, body)
        return resFielding.data
    }
    catch (error: any) {
        console.log(error)
        return error.response
    }
})

export const editFieldingRequest = createAsyncThunk('project/editFielding', async (body: any) => {
    try {
        const resFielding = await axios.post(`${baseUrl}​/api/FieldingRequest/Edit`, body)
        return resFielding.data
    }
    catch (error: any) {
        console.log(error)
        return error.response
    }
})

export const getFieldingRequest = createAsyncThunk('project/GetListByJobNumberId', async (data: any) => {
    const userId = localStorage.getItem("id");
    try {
        const response = await axios.get(`${baseUrl}/api/FieldingRequest/GetListByJobNumberId`, {
            params: {
                jobNumberId: data.jobnumberId,
                userId: userId,
                status: data.status
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getFieldingRequestIncludeDeleted = createAsyncThunk('getFieldingRequestIncludeDeleted', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/FieldingRequest/GetListByDeletedItems`, {
            params: {
                jobNumberId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getFieldingRequestDeleted = createAsyncThunk('project/getFieldingRequestDeleted', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/FieldingRequest/GetTempDeletedList`, {
            params: {
                jobNumberId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getFieldingRequestChoose = createAsyncThunk('getFieldingRequestChoose', async (id: any) => {
    const userId = localStorage.getItem("id");
    try {
        const response = await axios.get(`${baseUrl}/api/FieldingRequest/GetListByJobNumberId`, {
            params: {
                jobNumberId: id,
                userId: userId
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getFieldingRequestId = createAsyncThunk('project/GetListIdByJobNumberId', async (id: any) => {
    const userId = localStorage.getItem("id");
    try {
        const response = await axios.get(`${baseUrl}/api/FieldingRequest/GetListByJobNumberId`, {
            params: {
                jobNumberId: id,
                userId: userId
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteTempFieldingRequest = createAsyncThunk('project/deleteTempFieldingRequest', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/FieldingRequest/TempDelete`, {
            params: {
                targetFieldingRequestId: id,
                userId: localStorage.getItem("id")
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const fieldingrequestUndoDelete = createAsyncThunk('fieldingrequestUndoDelete', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/FieldingRequest/UndoDelete`, {
            params: {
                targetFieldingRequestId: id,
                userId: localStorage.getItem("id")
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteFieldingRequest = createAsyncThunk('project/deleteFielding', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/FieldingRequest/Delete`, {
            params: {
                targetFieldingRequestId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getFieldingRequestAttachmentList = createAsyncThunk('api/FieldingRequest/GetFieldingRequestAttachmentList', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/FieldingRequest/GetFieldingRequestAttachmentList`, {
            params: {
                fieldingRequestId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getFieldingRequestAttachmentLogs = createAsyncThunk('api/FieldingRequest/getFieldingRequestAttachmentLogs', async (data: any, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Pole/GetPoleFieldingImageLogsByFieldingRequestId`, data)
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response)
    }
})

export const deleteFieldingRequestAttachment = createAsyncThunk('api/FieldingRequest/DeleteFieldingRequestAttachment', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/FieldingRequest/DeleteFieldingRequestAttachment`, {
            params: {
                fieldingRequestAttachmentId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteFieldingRequestAttachmentMultiple = createAsyncThunk('api/FieldingRequest/DeleteFieldingRequestAttachmentMultiple', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/FieldingRequest/MultiDeleteFieldingRequestAttachment`, body)
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const generateAllDocument = createAsyncThunk("api/FieldingRequest/DownloadAllDocuments", async (id: string, { rejectWithValue }) => {
    try {
        const response = await axios.get(`https://media.utilityfielding.com/GetAllFieldingRequestDocuments`, {
            params: {
                fieldingRequestId: id,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response)
    }
});

export const importJsonFielding = createAsyncThunk('/api/FieldingRequest/ImportJson', async (body: any) => {
    try {
        const res = await axios.post(`${baseUrl}/api/FieldingRequest/ImportJson`, body)
        return res.data
    }
    catch (error: any) {
        return error.response
    }
})

//pole inventory

export const postInventory = createAsyncThunk('postInventory', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}​/api/Inventory/Create`, body)
        return response.data
    }
    catch (error: any) {
        console.log(error)
        return error.response
    }
})

export const editInventory = createAsyncThunk('editInventory', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}​/api/Inventory/Edit`, body)
        return response.data
    }
    catch (error: any) {
        console.log(error)
        return error.response
    }
})

export const getInventorytByJobNumberId = createAsyncThunk('getInventorytByJobNumberId', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Inventory/GetListByJobNumberId`, {
            params: {
                jobNumberId: id,
                userId: localStorage.getItem("id")
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteInventory = createAsyncThunk('deleteInventory', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Inventory/Delete`, {
            params: {
                targetInventoryId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

//note marker
export const postNoteMarker = createAsyncThunk('project/postNoteMarker', async (body: any) => {
    try {
        const resProject = await axios.post(`${baseUrl}/api/Marker/Create`, body)
        return resProject.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getNoteListByJobNumberId = createAsyncThunk('note/getNoteListByJobNumberId', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Marker/GetListByJobNumberId`, {
            params: {
                jobNumberId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getNoteListDeleted = createAsyncThunk('note/getNoteListDeleted', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Marker/GetTempDeletedList`, {
            params: {
                jobNumberId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteNoteMarker = createAsyncThunk('note/deleteNoteMarker', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Marker/Delete`, {
            params: {
                targetMarkerId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const requestDeleteNoteMarker = createAsyncThunk('note/requestDeleteNoteMarker', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Marker/RequestDelete`, {
            params: {
                targetMarkerId: id,
                userId: localStorage.getItem("id")
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const rejectRequestDeleteNoteMarker = createAsyncThunk('note/rejectRequestDeleteNoteMarker', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Marker/RejectRequestDelete`, {
            params: {
                targetMarkerDeleteRequestId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteTempNoteMarker = createAsyncThunk('note/deleteTempNoteMarker', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Marker/TempDelete`, {
            params: {
                targetMarkerId: id,
                userId: localStorage.getItem("id")
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteUndoNoteMarker = createAsyncThunk('note/deleteUndoNoteMarker', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Marker/UndoDelete`, {
            params: {
                targetMarkerId: id,
                userId: localStorage.getItem("id")
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const editNoteMarker = createAsyncThunk('note/editNoteMarker', async (body: any) => {
    try {
        const resProject = await axios.post(`${baseUrl}/api/Marker/Edit`, body)
        return resProject.data
    }
    catch (error: any) {
        return error.response
    }
})


export const editNoteLocation = createAsyncThunk('note/editNoteLocation', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Marker/EditLocation`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getNoteEnums = createAsyncThunk('api/Marker/GetMarkerEnums', async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/Marker/GetMarkerEnums`)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const createComment = createAsyncThunk('/api/Marker/CreateComment', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Marker/CreateComment`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getNoteChat = createAsyncThunk('/api/Marker/GetComments', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Marker/GetComments`, {
            params: {
                markerId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const getNoteDeleteRequests = createAsyncThunk('/api/Marker/GetMarkerDeleteRequests', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Marker/GetMarkerDeleteRequests`, {
            params: {
                markerId: id,
                userId: localStorage.getItem("id")
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const completeMarker = createAsyncThunk('/api/Marker/CompleteMarker', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Marker/CompleteMarker`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

//loading request
export const postLoadingRequest = createAsyncThunk('ocalc/postOcalc', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Ocalc/Create`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const editLoadingRequest = createAsyncThunk('api/Ocalc/Edit', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/Ocalc/Edit`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const getLoadingRequest = createAsyncThunk('ocalc/getOcalc', async (id: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Ocalc/GetListByJobNumberId`, {
            params: {
                jobNumberId: id
            }
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const deleteLoadingRequest = createAsyncThunk('api/Ocalc/Delete', async (id: any) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/Ocalc/Delete`, {
            params: {
                targetOcalcId: id
            }
        })
        console.log(response)
        return response.data
    } catch (error: any) {
        return error.response
    }
})

export const addPolesToLoadingRequest = createAsyncThunk('ocalc/addPolesToLoadingRequest', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/ocalc/addpoles`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const removePolesToLoadingRequest = createAsyncThunk('ocalc/removePolesToLoadingRequest', async (body: any) => {
    try {
        const response = await axios.post(`${baseUrl}/api/ocalc/removepoles`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})