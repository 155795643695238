import { Box, Button, CircularProgress, LinearProgress, Typography } from "@material-ui/core";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Download, Star, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { baseUrl } from "../../../state";
import { setLoading } from "../../sideTab/sideTab.reducer";
import { generateMidspanAllImages, getMidspanDetails } from "../poleInformation.api";
import { handleModalImage } from "../poleInformation.reducer";

export default function FormMidspanImage(props: any) {

    const dispatch = useDispatch()

    const { isEditPoleInfo, midspanDetail } = useSelector((state: RootState) => state.poleInformation)

    const [localImages, setLocalImages] = useState<any>([]);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [downloadImages, setDownloadImages] = useState<any>({
        isDownloading: false,
        progressValue: 70
    })

    useEffect(() => {
        if (localImages.length > 0 && localImages.every((item: any) => item.uploadProgress === 0)) {
            uploadProgress()
        }
        else if (localImages.length === 0 && isImageUploading === true) {
            setIsImageUploading(false)
        }
    }, [localImages])

    const onChangeImage = async (files: any) => {
        if (files) {
            setIsImageUploading(true)
            let x: any = []
            files.map((file: any) => {
                x.push({
                    name: file.name,
                    objectUrl: URL.createObjectURL(file),
                    uploadProgress: 0,
                    file: file
                })
            })
            setLocalImages(x)
        }
    };

    const uploadProgress = async () => {
        let data = new FormData();
        data.append("midSpanId", midspanDetail?.id)
        data.append("userId", localStorage.getItem('id') || "")
        data.append("", localImages[0].file)
        try {
            await axios.post(`${baseUrl}/api/Midspan/UploadImageAttachments`, data, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setLocalImages(
                        localImages.map((lastImage: any) => {
                            if (lastImage.name === localImages[0].name) {
                                return {
                                    ...lastImage,
                                    uploadProgress: percentCompleted
                                }
                            }
                            else {
                                return lastImage
                            }
                        })
                    )
                }
            })
            dispatch<any>(getMidspanDetails(midspanDetail?.id))
                .unwrap()
                .then(() => {
                    setLocalImages(localImages.filter((item: any) => item.name !== localImages[0].name))
                })
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleDownloadAllImage = () => {
        dispatch(setLoading(true))
        dispatch<any>(generateMidspanAllImages(midspanDetail?.id))
            .unwrap()
            .then((res: any) => {
                dispatch(setLoading(false))
                let url = res.data
                axios({
                    url: url,
                    method: "GET",
                    responseType: "blob",
                    onDownloadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setDownloadImages({
                            isDownloading: true,
                            progressValue: percentCompleted
                        })
                    }
                })
                    .then(response => {
                        const downloadLink = document.createElement('a')
                        downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
                        downloadLink.setAttribute('download', url.split('/')[url.split('/').length - 1])
                        document.body.appendChild(downloadLink)
                        downloadLink.click()
                        downloadLink.remove()
                        setDownloadImages({
                            isDownloading: false,
                            progressValue: 0
                        })
                    })
            })
    }

    const handleDeleteAttachment = async (id: any) => {
        // await dispatch(deletePoleAttachment(id))
        dispatch<any>(getMidspanDetails(midspanDetail?.id))
            .unwrap()
            .then((response: any) => {
                props.setImages(response?.data?.imageList)
            })
    }

    return (
        <Fragment>
            <div className="header-content">
                <h4>Image</h4>
                <div>
                    {!isEditPoleInfo && (
                        <Button className="add" component="span" variant="contained" size="small" onClick={handleDownloadAllImage}>
                            <Download />
                            {downloadImages.isDownloading
                                ? "Downloading " + downloadImages.progressValue + "%"
                                : "Download All"
                            }
                        </Button>
                    )}
                    {downloadImages.isDownloading && (
                        <LinearProgress style={{ width: "100%" }} variant="determinate" value={downloadImages.progressValue} />
                    )}
                    <Typography variant="caption">
                        Download WinRAR <a href="https://www.win-rar.com/download.html" target={"_blank"}>here</a> to open downloaded pictures file
                    </Typography>
                </div>
            </div>
            {isEditPoleInfo && (!isImageUploading
                ? (
                    <Dropzone onDrop={onChangeImage} accept={"image/*"}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="dropzone-container">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Drag and drop some images here, or click to select images</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )
                : (
                    <div className="dropzone-container">
                        <p>Uploading, {localImages.length} image left</p>
                    </div>
                ))}
            <div className="image-content">
                {props.images?.map((image: any, index: number) => (
                    image.isDefault &&
                    <div key={index} className="image-container" style={{ cursor: isEditPoleInfo ? 'auto' : 'pointer' }} onClick={() => !isEditPoleInfo && dispatch(handleModalImage(image.id))}>
                        <img src={image.filePath ? (image.filePath.includes(".com") ? image.filePath + '?width=100' : baseUrl + "/" + image.filePath) : image} alt="image" />
                        {isEditPoleInfo
                            ? (
                                <div className='attach-delete-icon' onClick={() => handleDeleteAttachment(image.id)}>
                                    <Trash2 />
                                </div>
                            ) : (
                                image.isDefault ?
                                    <div className='attach-star-icon' onClick={() => handleDeleteAttachment(image.id)}>
                                        <Star color='gold' style={{ fill: 'gold' }} />
                                    </div> : null
                            )
                        }
                    </div>
                ))}
                {props.images?.map((image: any, index: number) => (
                    !image.isDefault &&
                    <div key={index} className="image-container" style={{ cursor: isEditPoleInfo ? 'auto' : 'pointer' }} onClick={() => !isEditPoleInfo && dispatch(handleModalImage(image.id))}>
                        <img src={image.filePath ? (image.filePath.includes(".com") ? image.filePath + '?width=100' : baseUrl + "/" + image.filePath) : image} alt="image" />
                        {isEditPoleInfo
                            ? (
                                <div className='attach-delete-icon' onClick={() => handleDeleteAttachment(image.id)}>
                                    <Trash2 />
                                </div>
                            ) : null
                        }
                    </div>
                ))}
                {localImages?.map((image: any, index: number) => (
                    <div key={index} className="image-container">
                        <div className="progress-container">
                            <img src={image.objectUrl} alt="image" />
                            <div className='attach-delete-icon'>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <CircularProgress variant="determinate" value={image.uploadProgress} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            component="div"
                                        >{`${image.uploadProgress}%`}</Typography>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}