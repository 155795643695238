
import App_Privacy_Policy from '../../assets/pdf/App_Privacy_Policy.pdf'
import PDFViewer from '../../shared/PDFViewer';



function PrivacyPolicy(props:any) {
    return <PDFViewer pdf={App_Privacy_Policy} />;
}

export default PrivacyPolicy;