import App_Terms_and_Conditions from '../../assets/pdf/App_Terms_and_Conditions.pdf'
import PDFViewer from '../../shared/PDFViewer';



function TermsOfUse(props:any) {
    return <PDFViewer pdf={App_Terms_and_Conditions} />;
}

export default TermsOfUse;

