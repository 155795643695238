
import React, { useState } from "react";
import { Container, InputAdornment, TextField, Button, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import './loginPage.scss'
import emailIcon from '../../../assets/icons/mail.svg'
import passwordIcon from '../../../assets/icons/password.svg'
import logoutil from '../../../assets/images/logoutil.png'
import bglogin from '../../../assets/images/bglogin.svg'
import bg2 from '../../../assets/images/bg2.svg'
import { postLogin } from "../login.api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import LoadingCustom from "../../../shared/LoadingCustom";
import Swal from "sweetalert2";
import Roles from "../../../shared/Roles";

const LoginPage = () => {

    const [values, setValues] = useState<any>({
        userName: '',
        password: ''
    })
    const [isRememberMe, setIsRememberMe] = useState(false)
    const dispatch = useDispatch()
    const { loading } = useSelector((state: RootState) => state.login)
    const handleChange = ((e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    })

    const handleSubmit = (e: any) => {
        dispatch<any>(postLogin(values))
            .unwrap()
            .then((res: any) => {
                if (res.status >= 400) {
                    Swal.fire(
                        'Login Failed!',
                        `${res.data || Object.values(res.data.errors)}`,
                        'error'
                    )
                }
                else {
                    localStorage.setItem('auth', 'true')
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('id', res.data.user.id)
                    localStorage.setItem('email', res.data.user.userName)
                    localStorage.setItem('company', res.data.user.companyName)
                    localStorage.setItem('companyId', res.data.user.companyId)
                    localStorage.setItem('roles', (res.data.user.roles).toString())
                    if (isRememberMe) {
                        localStorage.setItem('born', '1')
                    }
                    else {
                        localStorage.setItem('born', String(new Date().getTime()))
                    }
                    if (res.data.user.roles.length === 1 && res.data.user.roles[0] === 'Fielder') {
                        window.location.href = `https://profielder.utilityfielding.com/LoginToken?token=${res.data.token}`
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Successful Login!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                            .then(() => {
                                window.location.href = String(Roles("home"))
                            })
                    }
                }
            })
        e.preventDefault()
    }
    return (
        <div className="login" style={{ backgroundImage: `url(${bglogin}), url(${bg2})` }}>
            <LoadingCustom isLoading={loading} />
            <Container >
                <Grid container className='box'>
                    <Grid item xs={12} md={7}>
                        <div className="wrp-logo">
                            <img className='logo' src={logoutil} alt="logo" />
                            <br />
                            <span>
                                Underground and Aerial Field Inspection and Design
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div className="wrapform">

                            <form onSubmit={handleSubmit}>
                                <p className="title">Login to your account</p>
                                <TextField
                                    className='inp'
                                    name='userName'
                                    variant="outlined"
                                    type='email'
                                    // label="Email" 
                                    placeholder="Email"
                                    value={values.userName}
                                    onChange={handleChange}
                                    size='small'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={emailIcon} alt="mail" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    className='inp'
                                    name='password'
                                    variant="outlined"
                                    type='password'
                                    size='small'
                                    value={values.password}
                                    onChange={handleChange}
                                    // label="Email" 
                                    placeholder="Password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={passwordIcon} alt="pass" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <FormControlLabel
                                    className="remember"
                                    control={
                                        <Checkbox checked={isRememberMe} onChange={(e, value) => setIsRememberMe(value)} />
                                    }
                                    label="Remember me"
                                />
                                <Button type='submit'>Sign in</Button>
                                <div className="foot">
                                    <a href="/disclaimer" target='__blank'>Disclaimer</a>
                                    <a href="/privacy-policy" target='__blank'>Privacy Policy</a>
                                    <a href="/eula" target='__blank'>EULA</a>
                                    <a href="/terms-of-use" target='__blank'>Terms of Use</a>
                                </div>

                            </form>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default LoginPage;