import React, { useState, useEffect } from 'react';

const useForm = () => {
    const [values, setValues] = useState<any>({
        groundLine : '',
        year : '',
        poleCondition : '',
        poleLength : '',
        poleClass : '',
        species : '',
    })

    const [errors, setErrors] = useState<any>({})

    const handleChange = (e: any) =>{
        setValues({
            ...values,
            [e.target.name] : e.target.value
        })
    }

    useEffect(() => {
        validateError()
    }, [values])

    const validateError = ()=>{
        const errorObj:any = {}
        if(!values.groundLine){
            errorObj.groundLine = 'Required'
        }
        if(!values.year){
            errorObj.year = 'Required'
        }
        if(!values.poleCondition){
            errorObj.poleCondition = 'Required'
        }
        if(!values.poleLength){
            errorObj.poleLength = 'Required'
        }
        if(!values.poleClass){
            errorObj.poleClass = 'Required'
        }
        if(!values.species){
            errorObj.species = 'Required'
        }
        return setErrors(errorObj)
    }
    
    const handleSubmit =(e:any)=>{
        // if(JSON.stringify(errors).length !== 0){
        //     validateError()
        //     console.log(errors)

        // }
        e.preventDefault()
    }


    return {values, handleChange, handleSubmit, errors}
}

export default useForm;