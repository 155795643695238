import { createSlice } from '@reduxjs/toolkit'
import { completeLoadingRequest, getPoleLoading, getPoleLoadingType, postPoleLoadingResultLink, postPoleLoadingSupportingLink } from './poleloading.api';

export interface PoleLoadingState {
  value: number,
  loading: boolean,
  typeList: any,
  data: any
}
const initialState: PoleLoadingState = {
  value: 0,
  loading: false,
  typeList: [],
  data: {}
}

export const counterSlice = createSlice({
  name: 'PoleLoading',
  initialState,
  reducers: {
    // setTypeSelected: (state, action) => {
    //   state.typeSelected = action.payload;
    // }
  },
  extraReducers: (builder) => {
    //get type list
    builder.addCase(getPoleLoadingType.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPoleLoadingType.fulfilled, (state, action) => {
      state.loading = false;
      state.typeList = action.payload.data;
    });
    builder.addCase(getPoleLoadingType.rejected, (state, action) => {
      state.loading = false;
    });
    //get list
    builder.addCase(getPoleLoading.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPoleLoading.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getPoleLoading.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(postPoleLoadingSupportingLink.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postPoleLoadingSupportingLink.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postPoleLoadingSupportingLink.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(postPoleLoadingResultLink.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postPoleLoadingResultLink.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postPoleLoadingResultLink.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(completeLoadingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(completeLoadingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(completeLoadingRequest.rejected, (state, action) => {
      state.loading = false;
    });
  },
})

export const {
  // setTypeSelected
} = counterSlice.actions;

export default counterSlice.reducer