import { Button, Checkbox, Tooltip } from "@material-ui/core";
import { useEffect } from "react";
import { Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import pencilBlack from "../../../../assets/icons/pencilBlack.png";
import pencilGreen from "../../../../assets/icons/pencilGreen.png";
import { handleLayerActive, handleFieldingIcon, setJobIdList, handleNoteIcon, handleInventoryFormModal, handleInventoryActive, handleInventoryIdList } from "../../sideTab.reducer";
import { RootState } from "../../../../app/store";
import { getInventorytByJobNumberId } from "../../sideTab.api";
import { getPoleFieldingRequest, getPoleInventory } from "../../../poleInformation/poleInformation.api";
import { setListPoleFieldingRequest, setListPoleInventory } from "../../../poleInformation/poleInformation.reducer";
import ModalTemplate from "../../../../shared/ModalTemplate";
import { setCenter, setZoom } from "../../../dashboard/dashboard.reducer";
import InventoryForm from "./InventoryForm";

function PoleInventory() {
  const dispatch = useDispatch();

  const { isLayerActive, jobNumberIdActive, jobIdList, inventoryList, inventoryFormModal, inventoryIdList } = useSelector((state: RootState) => state.sideTab);
  const { aerialFielding } = useSelector((state: RootState) => state.assignFielding);
  const { listPoleFieldingRequest, listPoleInventory } = useSelector((state: RootState) => state.poleInformation);
  const { zoom } = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    dispatch(getInventorytByJobNumberId(jobNumberIdActive.id));
  }, [jobNumberIdActive]);

  const handleStarActive = (res: any) => {
    dispatch(handleNoteIcon(null))
    if (isLayerActive.layerFieldingId) {
      dispatch(handleLayerActive({}))
      dispatch(handleFieldingIcon(null));
      dispatch(handleInventoryActive(res));
    }
    else {
      dispatch(
        handleLayerActive({
          layerJobNumberId: "",
          layerFieldingId: res.id,
          note: false,
          status: true,
          label: "inventory"
        })
      )
      dispatch(handleFieldingIcon(0));
      dispatch(handleInventoryActive(res));
      if (!inventoryIdList.includes(res.id)) {
        dispatch(handleInventoryIdList([...inventoryIdList, res.id]));
        dispatch<any>(getPoleFieldingRequest(res.id))
          .unwrap()
          .then((response: any) => {
            dispatch(setListPoleFieldingRequest(listPoleFieldingRequest.concat(response.data)));
          })
      }
    }
  };

  let handleChangeCheckbox = async (e: any, inventory: any) => {
    if (e.target.checked) {
      let array: any = [...inventoryIdList];
      array.push(inventory.id);
      dispatch(handleInventoryIdList(array));
      await dispatch<any>(getPoleInventory(inventory.id))
        .unwrap()
        .then((response: any) => {
          dispatch(setListPoleInventory(listPoleInventory.concat(response.data)));
          dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
          if (zoom < 16) {
            dispatch(setZoom(16))
          }
        })
    }
    else {
      let array: any = [...inventoryIdList];
      let removeItem = array.indexOf(inventory.id);
      if (removeItem !== -1) {
        array.splice(removeItem, 1);
        dispatch(handleInventoryIdList(array));
      }
      let poleInventory: any = listPoleInventory?.filter((item: any) => item?.inventoryId !== inventory.id);
      dispatch(setListPoleInventory(poleInventory));
      dispatch(setCenter({ latitude: poleInventory[0]?.latitude, longitude: poleInventory[0]?.longitude }))
      let jobIdListNew: any = jobIdList?.filter((item: any) => item !== inventory.jobNumberId)
      dispatch(setJobIdList(jobIdListNew))
    }
  };

  return (
    <>
      <ModalTemplate component={() => <InventoryForm />} onOpen={inventoryFormModal.isOpen} />
      <Button onClick={() => dispatch(handleInventoryFormModal({ isOpen: true, formType: "" }))}>
        <Plus /> Pole Inventory
      </Button>
      <ul>
        {inventoryList.map((res: any, i: any) => (
          <li key={i} className="projnumberlist" style={{ backgroundColor: (res.id === aerialFielding.id ? "#cbced2" : "") }}>
            <div>
              <Tooltip title="view pole">
                <Checkbox className="customcheck" color="primary" checked={inventoryIdList.includes(res.id)} onChange={(e: any) => handleChangeCheckbox(e, res)} />
              </Tooltip>
            </div>
            <Tooltip title="show details">
              <span>{res.name}</span>
            </Tooltip>
            <Tooltip title="edit/activate"><img src={isLayerActive.layerFieldingId === res.id && isLayerActive.status ? pencilGreen : pencilBlack} alt="star" onClick={() => handleStarActive(res)} /></Tooltip>
          </li>
        ))}
      </ul>
    </>
  );
}

export default PoleInventory;
