import { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import backgroundCanvas from '../../../../assets/images/bgcanvas.png'

export default function RiserCanvasSmall() {
    const {
        riserCanvasDotes,
        riserCanvasFences,
        poleEnums
    } = useSelector((state: RootState) => state.poleInformation)

    const [selected, setSelected] = useState<any>({})

    const typeInitial = ["T", "C", "O", "P"]
    const valueInitial = ["1in", "2in", "3in", "4in", "VGR"]

    useLayoutEffect(() => {
        const canvas: any = document.getElementById('canvasRiserSmall')
        const ctx: any = canvas.getContext("2d")

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        riserCanvasDotes.forEach((res: any) => {
            ctx.strokeStyle = 'grey'
            ctx.beginPath();
            ctx.arc((res.shapeX * 2), (res.shapeY * 2), 15, 0, 2 * Math.PI, false);
            ctx.font = '10px, Calibri';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'red';
            ctx.fillText(res.generalRVGRSequence + "." + typeInitial[res.type] + "R - " + valueInitial[res.value], (res.textX * 2), (res.textY * 2) + 3);
            ctx.closePath();
            ctx.stroke();
        })

        riserCanvasFences.forEach((res: any) => {
            ctx.strokeStyle = `${res.stroke}`
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.lineTo((res.points.x1 * 2), (res.points.y1 * 2));
            ctx.lineTo((res.points.x2 * 2), (res.points.y2 * 2));
            ctx.closePath();
            ctx.stroke();
        })

    }, [
        riserCanvasDotes,
        riserCanvasFences
    ])

    let handleClick = (e: any) => {

        const canvas: any = document.getElementById('canvasRiserSmall')

        const translatedX = (x: any) => {
            var rect = canvas.getBoundingClientRect();
            var factor = canvas.width / rect.width;
            return factor * (x - rect.left);
        }

        const translatedY = (y: any) => {
            var rect = canvas.getBoundingClientRect();
            var factor = canvas.height / rect.height;
            return factor * (y - rect.top);
        }

        let x = translatedX(e.clientX) / 2
        let y = translatedY(e.clientY) / 2
        riserCanvasDotes.forEach((res: any) => {
            let targetX = res.shapeX
            let targetY = res.shapeY
            if (targetX > (x - 15) && targetX < (x + 15) && targetY > (y - 15) && targetY < (y + 15)) {
                if (JSON.stringify(selected) !== JSON.stringify(res)) {
                    setSelected(res)
                }
                else {
                    setSelected({})
                }
            }
        })
    }

    return (
        <div className="minimap-container">
            <div className="minimap">
                <canvas
                    id='canvasRiserSmall'
                    style={{ backgroundImage: `url('${backgroundCanvas}')`, cursor: 'crosshair' }}
                    width={600}
                    height={400}
                    onClick={handleClick}
                >
                </canvas>
            </div>
            <div className="information">
            {selected.shapeX &&
                <table>
                    <tr>
                        <td>Name</td>
                        <td>:</td>
                        <td>{selected.generalRVGRSequence + "." + typeInitial[selected.type] + "R - " + valueInitial[selected.value]}</td>
                    </tr>
                    <tr>
                        <td>Size of Riser</td>
                        <td>:</td>
                        <td>{poleEnums.riserVgrTypes.filter((item: any) => item.value === selected.value)[0].display}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>:</td>
                        <td>{poleEnums.downGuyOwners.filter((item: any) => item.value === selected.type)[0].display}</td>
                    </tr>
                </table>
            }
        </div>
        </div>
    )
}
