
import { Button, Container, Grid, LinearProgress } from '@material-ui/core';
import Template from '../../../shared/Template';
import QRCode from "react-qr-code";
import ProfileMap from '../component/ProfileMap';
import empty_profile from "../../../assets/images/empty_profile.png";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile } from '../profile.api';
import axios from 'axios';
import { baseUrl } from '../../../state';

function Profile() {

    const loginAccess: boolean = localStorage.getItem('auth') && localStorage.getItem('token') ? true : false

    const dispatch = useDispatch()

    const [profile, setProfile] = useState<any>({})
    const [isOnProgress, setIsOnProgress] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(75)

    const getProfileData = () => {
        let pathName = window.location.pathname
        let keyArr = pathName.split('/')
        let key = keyArr[(keyArr.length - 1)]
        dispatch<any>(getProfile(key))
            .unwrap()
            .then((res: any) => {
                setProfile(res.data)
            })
    }

    useState(() => {
        getProfileData()
    })

    const onChangeImage = async (e: any) => {
        setIsOnProgress(true)
        setUploadProgress(0)
        let data = new FormData();
        data.append("userId", profile?.id)
        data.append("file", e.target.files[0])
        try {
            await axios.post(`${baseUrl}/api/Account/UploadProfilePicture`, data, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadProgress(percentCompleted)
                }
            });
            setIsOnProgress(false)
            getProfileData()
        } catch (error: any) {
            console.log(error);
        }
    }

    let component = () => (
        <>
            <Container maxWidth={false} className="profile">
                <div className="box">
                    <h1>Profile</h1>
                    <Grid container className='contain'>
                        <Grid item md={3} className='left'>
                            <div className="box">
                                <img src={profile?.profilePicturePath ? baseUrl + "/api/Global/Download?path=" + profile?.profilePicturePath : empty_profile} alt="image" />
                                {loginAccess && (
                                    <label htmlFor="button-file">
                                        <input
                                            style={{ display: "none" }}
                                            id="addKml"
                                            type="file"
                                            accept="image/*"
                                            onChange={onChangeImage}
                                        />
                                        <label htmlFor="addKml">
                                            <Button className="upload-btn" variant='outlined' component="span">
                                                Change Picture
                                            </Button>
                                            {isOnProgress && <LinearProgress variant="determinate" value={uploadProgress} />}
                                        </label>
                                    </label>
                                )}
                                <h2>{profile?.firstName} {profile?.lastName}</h2>
                                <h3>{profile?.email}</h3>
                                <QRCode value={profile?.barcodeUrl ? profile?.barcodeUrl : ""} />
                            </div>
                        </Grid>
                        <Grid item md={9} className='right'>
                            <div className="box">
                                <h1>Hi, I'm {profile?.firstName}</h1>
                                {profile?.lastLocation && <ProfileMap lastLocation={profile?.lastLocation} />}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default Profile;