import { X } from "react-feather";

export default function AerialFieldingDownloadLogsModal(props: any) {

  return (
    <div className="formproject">
      <h3>Download Logs<X onClick={props.onClose} /></h3>
      <ul>
        {props.data.mobileDownloadLogs.map((data: any, i: number) => (
          <li key={i}>
            {data.userName} at {data.downloadedDate}
          </li>
        ))}
      </ul>
    </div >
  )
}
