
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../../state'

export const getTrackerFieldingRequest = createAsyncThunk('api/FieldingRequest/GetTrackerFieldingRequest', async (params: any) => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/api/FieldingRequest/GetTrackerFieldingRequest',
            params : params
        })
        return response.data
    } catch (error: any) {
        return error.response
    }
})