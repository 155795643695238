import { Button, LinearProgress, MenuItem, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import { getFieldingRequest, postFieldingRequest } from "../../sideTab.api";
import { getPoleFieldingRequest } from "../../../poleInformation/poleInformation.api";
import { File, Save, X } from "react-feather";
import axios from "axios";
import { setListPoleFieldingRequest } from "../../../poleInformation/poleInformation.reducer";
import moment from "moment";
import { setCenter, setZoom } from "../../../dashboard/dashboard.reducer";
import { baseUrl } from "../../../../state";

export default function AerialFieldingMoveModalAdd(props: any) {
  const dispatch = useDispatch();

  const { projectIdActive, statusFieldingRequest } = useSelector((state: RootState) => state.sideTab);
  const { listPoleFieldingRequest } = useSelector((state: RootState) => state.poleInformation);

  const [values, setValues] = useState<any>({
    jobNumberId: "",
    name: "",
    description: "",
    dueDateString: "",
    kmlFilePath: "",
    type: 0,
    fieldingCategory: 1,
    attachmentPaths: [],
    city: ""
  });
  const [localFilesKml, setLocalFilesKml] = useState<any>([])
  const [localFilesAttachment, setLocalFilesAttachment] = useState<any>([])

  useEffect(() => {
    setValues({
      ...values,
      jobNumberId: props.jobnumberId
    })
  }, [props.jobnumberId])

  const handleClose = () => {
    props.onClose()
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const searchCity = async () => {
    const geocoder = new google.maps.Geocoder()
    let response = await geocoder.geocode({ address: values.city })
    console.log(response)
    let lat = response.results[0].geometry.location.lat();
    let lng = response.results[0].geometry.location.lng();
    dispatch(setCenter({ latitude: lat, longitude: lng }))
    dispatch(setZoom(13))
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let attachmentPaths = localFilesAttachment.map((file: any) => {
      return {
        fileName: file.name,
        filePath: file.responseFilePath
      }
    })
    let newValues = {
      ...values,
      dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy"),
      kmlFilePath: (localFilesKml[0]?.responseFilePath ? localFilesKml[0]?.responseFilePath : ""),
      attachmentPaths: (localFilesAttachment.length > 0
        ? attachmentPaths
        : []
      )
    }
    dispatch<any>(postFieldingRequest(newValues))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Create Fielding Request Failed!");
        }
        else {
          if (res?.data?.poleCount > 0) {
            dispatch<any>(getPoleFieldingRequest(res?.data?.newFieldingRequest?.id))
              .unwrap()
              .then((response: any) => {
                dispatch(setListPoleFieldingRequest(listPoleFieldingRequest.concat(response.data)));
                dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
                dispatch(setZoom(13))
              })
          }
          else {
            searchCity()
          }
          Swal.fire({
            icon: "success",
            title: "New Fielding Request Successfully Created, " + res?.data?.poleCount + " Poles Imported",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            handleClose();
            dispatch<any>(getFieldingRequest({
              jobnumberId: props.jobnumberId,
              status: statusFieldingRequest
            }))
            props.setFieldingRequestId(res.data.newFieldingRequest.id)
          });
        }
      });
  }

  const onChangeKml = (e: any) => {
    var files = Array.from(e.target.files);
    if (files) {
      let newLocalFiles: any = []
      files.map((file: any) => {
        newLocalFiles.push({ file: file, name: file.name, isUploading: true, uploadProgress: 0, responseFilePath: "" })
      })
      setLocalFilesKml(localFilesKml.concat(newLocalFiles))
    }
  }

  useEffect(() => {
    localFilesKml.map(async (localFile: any) => {
      if (localFile.uploadProgress === 0 && localFile.isUploading === true) {
        let data = new FormData();
        data.append("", localFile.file)
        try {
          let response = await axios.post(`${baseUrl}/api/FieldingRequest/UploadKMLFile`, data, {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              let newLocalFiles: any = []
              localFilesKml.map((localFileProgress: any) => {
                if (localFileProgress.name === localFile.name) {
                  localFileProgress.uploadProgress = percentCompleted
                }
                newLocalFiles.push(localFileProgress)
              })
              setLocalFilesKml(newLocalFiles)
            }
          });
          let newLocalFiles: any = []
          localFilesKml.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
              localFileProgress.responseFilePath = response.data.data.filePath
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesKml(newLocalFiles)
        } catch (error: any) {
          console.log(error);
          let newLocalFiles: any = []
          localFilesKml.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesKml(newLocalFiles)
        }
      }
    })
  }, [localFilesKml])

  const onChangeAttachment = (e: any) => {
    var files = Array.from(e.target.files);
    if (files) {
      let newLocalFiles: any = []
      files.map((file: any) => {
        newLocalFiles.push({ file: file, name: file.name, isUploading: true, uploadProgress: 0, responseFilePath: "" })
      })
      setLocalFilesAttachment(localFilesAttachment.concat(newLocalFiles))
    }
  }

  useEffect(() => {
    localFilesAttachment.map(async (localFile: any) => {
      if (localFile.uploadProgress === 0 && localFile.isUploading === true) {
        let data = new FormData();
        data.append("", localFile.file)
        try {
          let response = await axios.post(`${baseUrl}/api/FieldingRequest/UploadAttachments`, data, {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              let newLocalFiles: any = []
              localFilesAttachment.map((localFileProgress: any) => {
                if (localFileProgress.name === localFile.name) {
                  localFileProgress.uploadProgress = percentCompleted
                }
                newLocalFiles.push(localFileProgress)
              })
              setLocalFilesAttachment(newLocalFiles)
            }
          });
          let newLocalFiles: any = []
          localFilesAttachment.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
              localFileProgress.responseFilePath = response.data.data[0].filePath
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesAttachment(newLocalFiles)
        } catch (error: any) {
          console.log(error);
          let newLocalFiles: any = []
          localFilesAttachment.map((localFileProgress: any) => {
            if (localFileProgress.name === localFile.name) {
              localFileProgress.isUploading = false
            }
            newLocalFiles.push(localFileProgress)
          })
          setLocalFilesAttachment(newLocalFiles)
        }
      }
    })
  }, [localFilesAttachment])

  return (
    <div className="formproject">
      <h3>Create New Fielding Request</h3>
      <form onSubmit={handleSubmit}>
        <TextField
          className="inp"
          name="name"
          variant="outlined"
          type="text"
          label="Project Name"
          placeholder="Project Name"
          size="small"
          value={projectIdActive.projectNumber}
          disabled
        />
        <TextField
          className="inp"
          name="name"
          variant="outlined"
          type="text"
          label="Job Number"
          value={props.jobnumberName}
          size="small"
          disabled
        />
        <TextField
          className="inp"
          name="city"
          value={values.city}
          variant="outlined"
          onChange={handleChange}
          type="text"
          label="City & State"
          placeholder="City & State"
          size="small"
        />
        <TextField
          id="select"
          className="inp"
          label="Fielding Category"
          variant="outlined"
          size="small"
          select
          name="fieldingCategory"
          value={values.fieldingCategory}
          onChange={handleChange}
        >
          {[
            { value: 2, name: "Address Validation" },
            { value: 0, name: "Aerial Fielding" },
            { value: 1, name: "Measure Marker" }]
            .map((item: any, itemIndex: number) => (
              <MenuItem key={itemIndex} value={item.value}>{item.name}</MenuItem>
            ))}
        </TextField>
        <TextField
          id="select"
          className="inp"
          label="Fielding Request Type"
          variant="outlined"
          size="small"
          select
          name="type"
          defaultValue={0}
          onChange={handleChange}
        >
          {[{ value: 0, name: "Utility Fielding App" }, { value: 1, name: "Pen & Paper" }, { value: 2, name: "MRE Estimation" }].map((item: any, itemIndex: number) => (
            <MenuItem key={itemIndex} value={item.value}>{item.name}</MenuItem>
          ))}
        </TextField>
        <TextField className="inp" name="dueDateString" variant="outlined" required type="date" onChange={handleChange} label="Due Date Fielding" InputLabelProps={{ shrink: true }} size="small" />
        <TextField
          multiline
          rows={5}
          className="inp"
          name="description"
          variant="outlined"
          onChange={handleChange}
          type="text"
          label="Note"
          placeholder="Note"
          size="small"
        />
        <input
          accept=".kml"
          style={{ display: "none" }}
          id="button-file"
          type="file"
          disabled
        />
        {localFilesKml.length > 0
          ? (
            <>
              <div className="file-upload">
                <File size="35" />
                <div className="file-info">
                  <span>{localFilesKml[0].name}</span>
                  <LinearProgress variant="determinate" value={localFilesKml[0].uploadProgress} />
                </div>
                <div className="file-progress">
                  {localFilesKml[0].isUploading ? <span>{localFilesKml[0].uploadProgress}%</span> : <span>Done</span>}
                </div>
              </div>
              <br />
            </>
          )
          : (
            <>
              <label htmlFor="button-file">
                <input
                  style={{ display: "none" }}
                  id="addKml"
                  type="file"
                  accept=".kml"
                  // multiple
                  onChange={onChangeKml}
                />
                <label htmlFor="addKml">
                  <Button className="upload-btn" component="span">
                    Import from KML
                  </Button>
                </label>
              </label>
              <br />
            </>
          )
        }
        {values.type === 1 && (
          <label htmlFor="button-file">
            <input
              style={{ display: "none" }}
              id="addAttachment"
              type="file"
              accept=".doc,.pdf,.jpg"
              multiple
              onChange={onChangeAttachment}
            />
            <label htmlFor="addAttachment">
              <Button className="upload-btn" component="span">
                Upload Form
              </Button>
            </label>
          </label>
        )}
        <div className="wrapbutton">
          <Button type="button" className="cancel" variant="outlined" onClick={handleClose}>
            <X /> Cancel
          </Button>
          <Button type="submit" className="save" variant="outlined">
            <Save /> Save
          </Button>
        </div>
      </form>
    </div>
  )
}
