import React, { useEffect } from 'react';
import '../src/assets/styles/App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Redirect } from 'react-router-dom';

import LoginPage from './features/login/containers/LoginPage';
import Dashboard from './features/dashboard/containers/Dashboard';
import Disclaimer from './features/additionalPages/Disclaimer';
import PrivacyPolicy from './features/additionalPages/PrivacyPolicy';
import Eula from './features/additionalPages/Eula';
import TermsOfUse from './features/additionalPages/TermsOfUse';
import Account from './features/tracker/account/container/Account';
import Company from './features/tracker/company/container/Company';
import PoleLoading from './features/tracker/poleLoading/container/PoleLoading';
import Jpa from './features/tracker/jpa/container/Jpa';
import Mre from './features/tracker/mre/container/Mre';
import AssignFielding from './features/assignFielding/container/AssignFielding';
import Profile from './features/profile/container/Profile';
import JobNumberPdf from './features/additionalPages/JobNumberPdf';
import Tcp from './features/tracker/tcp/container/Tcp';
import FieldingRequest from './features/tracker/fieldingRequest/container/FieldingRequest';
import Roles from './shared/Roles';

const checkAccess = () => {
  if (localStorage.getItem('auth') && localStorage.getItem('token')) {
    let born = localStorage.getItem("born")
    if (born) {
      if (born === '1') {
      }
      else {
        let bornValue = parseInt(born)
        let relative = (new Date().getTime()) - bornValue
        if (relative > 86400000) {
          localStorage.removeItem('companyIdSelected')
          localStorage.removeItem('id')
          localStorage.removeItem('auth')
          localStorage.removeItem('roles')
          localStorage.removeItem('company')
          localStorage.removeItem('email')
          localStorage.removeItem('token')
          localStorage.removeItem('companyId')
          localStorage.removeItem('born')
          return false
        }
      }
    }
    else {
      localStorage.removeItem('companyIdSelected')
      localStorage.removeItem('id')
      localStorage.removeItem('auth')
      localStorage.removeItem('roles')
      localStorage.removeItem('company')
      localStorage.removeItem('email')
      localStorage.removeItem('token')
      localStorage.removeItem('companyId')
      localStorage.removeItem('born')
      return false
    }
    return true
  }
  return false
}

const loginAccess = checkAccess()

const PrivateRoute = ({ component, isAuth, ...rest }: any) => {
  const routeComponent = (props: any) => (
    isAuth
      ? React.createElement(component, props)
      : <Redirect to={{ pathname: '/' }} />
  );
  return <Route {...rest} render={routeComponent} />;
};

const UnPrivateRoute = ({ component, isAuth, ...rest }: any) => {
  let url: string = String(Roles("home"))
  const routeComponent = (props: any) => (
    isAuth
      ? <Redirect to={{ pathname: url }} />
      : React.createElement(component, props)
  );
  return <Route {...rest} render={routeComponent} />;
};

function App() {

  useEffect(() => {
    if (window.location.host === "office.utilityfielding.com") {
      window.open("https://utilityfielding.com", "_self")
    }
  }, [])

  return (
    <Router>
      <Switch>
        <UnPrivateRoute isAuth={loginAccess} exact path='/' component={LoginPage} />
        <PrivateRoute isAuth={loginAccess} exact path='/home' component={Dashboard} />
        <PrivateRoute isAuth={loginAccess} exact path='/account' component={Account} />
        <PrivateRoute isAuth={loginAccess} exact path='/company' component={Company} />
        <PrivateRoute isAuth={loginAccess} exact path='/assign-fielding' component={AssignFielding} />
        <PrivateRoute isAuth={true} exact path='/profile/*' component={Profile} />
        <PrivateRoute isAuth={loginAccess} exact path='/poleloading' component={PoleLoading} />
        <PrivateRoute isAuth={loginAccess} exact path='/jpa' component={Jpa} />
        <PrivateRoute isAuth={loginAccess} exact path='/mre' component={Mre} />
        <PrivateRoute isAuth={loginAccess} exact path='/tcp' component={Tcp} />
        <PrivateRoute isAuth={loginAccess} exact path='/fieldingrequest' component={FieldingRequest} />
        <PrivateRoute isAuth={true} exact path='/disclaimer' component={Disclaimer} />
        <PrivateRoute isAuth={true} exact path='/privacy-policy' component={PrivacyPolicy} />
        <PrivateRoute isAuth={true} exact path='/eula' component={Eula} />
        <PrivateRoute isAuth={true} exact path='/terms-of-use' component={TermsOfUse} />
        <PrivateRoute isAuth={loginAccess} exact path='/jobnumberpdf' component={JobNumberPdf} />
        <PrivateRoute isAuth={loginAccess} exact path='*' component={() => <p>404 | Page not found..!</p>} />
      </Switch>
    </Router>
  );
}

export default App;
