
import { Button, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { RootState } from '../../../../app/store';
import { baseUrl } from '../../../../state';
import { deleteJobNumberAttachment, getJobNumberAttachmentList } from '../../sideTab.api';

function SupportingDoc() {

    const dispatch = useDispatch()

    const { jobNumberIdActive, attachmentList } = useSelector((state: RootState) => state.sideTab);

    const [localFiles, setLocalFiles] = useState<any>([])
    const [localFilesNew, setLocalFilesNew] = useState(false)
    const [localFilesUploaded, setLocalFilesUploaded] = useState<any>([])

    const onChangeFile = (e: any) => {
        var files = Array.from(e.target.files);
        if (files) {
            let newLocalFiles: any = []
            let newLocalFilesUploaded: any = []
            files.map((file: any) => {
                newLocalFiles.push({ file: file, name: file.name, uploadProgress: 0 })
                newLocalFilesUploaded.push(false)
            })
            setLocalFiles(newLocalFiles)
            setLocalFilesNew(true)
            setLocalFilesUploaded(newLocalFilesUploaded)
        }
    }

    useEffect(() => {
        if (localFilesNew) {
            setLocalFilesNew(false)
            localFiles.map(async (localFile: any, localFileIndex: number) => {
                let data = new FormData();
                data.append("jobNumberId", jobNumberIdActive.id)
                data.append("", localFile.file)
                try {
                    await axios.post(`${baseUrl}/api/JobNumber/UploadAttachments`, data, {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            let newLocalFiles: any = []
                            let found = false
                            localFiles.map((localFileProgress: any) => {
                                if (localFileProgress.name === localFile.name) {
                                    localFileProgress.uploadProgress = percentCompleted
                                    found = true
                                }
                                newLocalFiles.push(localFileProgress)
                            })
                            if (!found) {
                                newLocalFiles.push(localFile)
                            }
                            setLocalFiles(newLocalFiles)
                        }
                    });
                    Swal.fire({
                        icon: "success",
                        title: localFile.name + " successfully uploaded",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    let newLocalFilesUploaded = localFilesUploaded
                    newLocalFilesUploaded[localFileIndex] = true
                    setLocalFilesUploaded(newLocalFilesUploaded)
                    if (newLocalFilesUploaded.every((item: any) => { return item })) {
                        setLocalFiles([])
                        dispatch(getJobNumberAttachmentList(jobNumberIdActive.id))
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: "error",
                        title: "Failed uploading " + localFile.name,
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    console.log(error);
                    setLocalFiles([])
                }
            })
        }
    }, [localFilesNew, localFiles, localFilesUploaded])

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Are You Sure You Want To Delete This Document?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch<any>(deleteJobNumberAttachment(id))
                    .unwrap()
                    .then((res: any) => {
                        if (res.status >= 400) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error Delete Document',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'Document Successfully Deleted',
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then(() => {
                                    dispatch(getJobNumberAttachmentList(jobNumberIdActive.id))
                                })
                        }
                    })
            }
        })
    }

    return (
        <>
            <input
                accept="image/*,.doc,.docx,.xml,.kml,.pdf,.pplx"
                style={{ display: 'none' }}
                id="button-file"
                type="file"
                multiple
                onChange={onChangeFile}
            />
            <div className="notice">
                <small>*Only pdf, word, powerpoint, jpg, png, excel, pplx and KML file can be uploaded</small>
            </div>

            {localFiles.length > 0
                ? <label htmlFor="button-file">
                    <Button className='upload-btn' component="span" disabled>
                        Uploading {parseInt(localFiles.reduce((total: any, file: any) => { return (file.uploadProgress + total) / localFiles.length }, 0))}%
                    </Button>
                    <LinearProgress variant="determinate" value={localFiles.reduce((total: any, file: any) => { return (file.uploadProgress + total) / localFiles.length }, 0)} />
                </label>
                : <label htmlFor="button-file">
                    <Button className='upload-btn' component="span">
                        Upload
                    </Button>
                </label>
            }
            <div className="supporting-list">
                {attachmentList.map((attachment: any, index: number) => {
                    return (
                        <div key={index} className="supporting-container" >
                            <a href={attachment.filePath.includes(".com") ? attachment.filePath : baseUrl + "/" + attachment.filePath} target="_blank">{attachment.fileName}</a>
                            <Trash2 onClick={() => handleDelete(attachment.id)} />
                        </div>
                    )
                })}
            </div>
        </>
    );
}

export default SupportingDoc;