import { Button, Grid, InputAdornment, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Edit2, Eye, Plus, RefreshCw, Save, Trash, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import Swal from "sweetalert2";
import { RootState } from "../../../app/store";
import ModalTemplate from "../../../shared/ModalTemplate";
import { handleRightButton } from "../../dashboard/dashboard.reducer";
import { deleteTempMidspanFieldingRequest, editMidspanFieldingRequest, getMidspanDetails, getMidSpanFieldingRequest } from "../poleInformation.api";
import { deleteHoaData, handleEditPoleInfo, handleModalHistory, handleModalHOA, handleModalSpanAndDirection, handleSpanMapLines, setHoaData, setListMidspanFieldingRequest } from "../poleInformation.reducer";
import FormHOA from "./FormHOA";
import FormMidspanAttachment from "./FormMidspanAttachment";
import FormMidspanImage from "./FormMidspanImage";
import SpanMapSmall from "./spanMap/SpanMapSmall";

export default function FormMidspan(props: any) {

  const dispatch = useDispatch()

  const { isEditPoleInfo, listMidspanFieldingRequest, midspanDetail, hoaData, modalFormHOA, spanMapLines } = useSelector((state: RootState) => state.poleInformation);
  const { statusJobNumber } = useSelector((state: RootState) => state.sideTab);

  const [values, setValues] = useState<any>({
    sequence: "",
    latitude: "",
    longitude: "",
    street: "",
    notes: ""
  })
  const [images, setImages] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    setValues(midspanDetail)
    if (Boolean(midspanDetail.id)) {
      dispatch(setHoaData(midspanDetail?.clearanceHeightList))
      setImages(midspanDetail?.imageList)
      setFiles(midspanDetail?.documentList)
      let spanArr = midspanDetail?.span !== '' ? JSON.parse(midspanDetail?.span) : []
      let spanArrNew: any = []
      spanArr.map((span: any) => {
        spanArrNew.push({
          ...span,
          lineData: typeof (span.lineData) === "string" ? JSON.parse(span.lineData) : span.lineData
        })
      })
      dispatch(handleSpanMapLines(spanArrNew))
    }
  }, [midspanDetail])

  const handleChangeValues = (e: any) => {
    let { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const handleReload = () => {
    dispatch(getMidspanDetails(midspanDetail?.id))
  };

  const variantInput = isEditPoleInfo ? "outlined" : "standard";

  const handleEditSwitch = () => {
    if (statusJobNumber > 0) {
      Swal.fire({
        title: 'This fielding is completed.',
        text: 'Do you wish to continue edit?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'are you sure you want to change the data?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(handleEditPoleInfo())
            }
          })
        }
      })
    }
    else {
      dispatch(handleEditPoleInfo())
    }
  }

  const handleDeletePole = (id: any) => {
    dispatch<any>(deleteTempMidspanFieldingRequest(id))
      .unwrap()
      .then((res: any) => {
        dispatch(handleRightButton());
        let poleFieldingRequest: any = listMidspanFieldingRequest?.filter((item: any) => item?.id != id);
        dispatch(setListMidspanFieldingRequest(poleFieldingRequest));
        Swal.fire({
          icon: "success",
          title: "Midspan successfully deleted",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: "Failed delete midspan",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleCancel = () => {
    setValues(midspanDetail)
    dispatch(handleEditPoleInfo())
  }

  const handleEditPole = (e: any) => {
    e.preventDefault()
    dispatch<any>(editMidspanFieldingRequest({
      ...values,
      clearanceHeightList: hoaData,
      span: JSON.stringify(spanMapLines),
      userId: localStorage.getItem("id")
    }))
      .unwrap()
      .then((res: any) => {
        dispatch(handleEditPoleInfo())
        Swal.fire({
          icon: "success",
          title: "Midspan successfully edited",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch<any>(getMidSpanFieldingRequest(values.fieldingRequestId))
          .unwrap()
          .then((res: any) => {
            let listPoleFieldingRequestNew: any = listMidspanFieldingRequest?.filter((item: any) => item?.fieldingRequestId != values.fieldingRequestId)
            dispatch(setListMidspanFieldingRequest(listPoleFieldingRequestNew.concat(res.data)));
          })
      })
      .catch((err: any) => {
        console.log("error in request", err);
      });
  }

  return (
    <div className="boxform">
      <form>
        <Grid container spacing={2}>
          <Grid item sm={12} className="header">
            <div className="title">
              <h3>Information</h3>
              <TextField id="select" className="headinp" variant={variantInput} disabled={!isEditPoleInfo} size="small" select value={0}>
                <MenuItem value={0}>Midspan</MenuItem>
              </TextField>
            </div>
            {!isEditPoleInfo ? (
              <div className="wrapbutton">
                <div>
                  <span style={{ fontSize: "small", cursor: "pointer" }} onClick={() => dispatch(handleModalHistory())}>Last updated by:</span> <br />
                  <span style={{ fontSize: "small", cursor: "pointer" }} onClick={() => dispatch(handleModalHistory())}>{midspanDetail.lastModifiedUsername} {midspanDetail.lastModifiedDate}</span>
                </div>
                <Tooltip title="Reload pole info">
                  <Button className="reload" variant="outlined" onClick={handleReload}>
                    <RefreshCw /> Reload
                  </Button>
                </Tooltip>
                <Button className="delete" variant="outlined" onClick={() => handleDeletePole(midspanDetail.id)}>
                  <Trash /> Delete
                </Button>
                <Button className="edit" variant="outlined" onClick={handleEditSwitch}>
                  <Edit2 /> Edit
                </Button>
              </div>
            ) : null}
          </Grid>

          <Grid item sm={12}>
            <TextField
              className="inp"
              label={"Midspan Sequence"}
              size="small"
              name="sequence"
              value={values?.sequence}
              onChange={handleChangeValues}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{ marginBottom: 4 }}>M</InputAdornment>,
              }}
              disabled={!isEditPoleInfo}
            />
          </Grid>

          <Grid item sm={6}>
            <TextField
              className="inp"
              variant={variantInput}
              name="latitude"
              value={values?.latitude}
              onChange={handleChangeValues}
              disabled={!isEditPoleInfo}
              label="Latitude"
              size="small"
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              className="inp"
              variant={variantInput}
              name="longitude"
              value={values?.longitude}
              onChange={handleChangeValues}
              disabled={!isEditPoleInfo}
              label="Longitude"
              size="small"
            />
          </Grid>

          <Grid item sm={12}>
            <TextField
              className="inp"
              variant={variantInput}
              name="street"
              value={values?.street}
              onChange={handleChangeValues}
              disabled={!isEditPoleInfo}
              label="Street Name"
              size="small"
            />
          </Grid>

          <Grid item sm={12}>
            <TextField
              multiline
              rows={2}
              className="inp"
              variant={variantInput}
              name="notes"
              value={values?.notes}
              onChange={handleChangeValues}
              disabled={!isEditPoleInfo}
              label="Note"
              size="small"
            />
          </Grid>

          <Grid item sm={12}>
            <div className="header-content">
              <h4>Clearance Height</h4>
              {isEditPoleInfo ? (
                <Button className="add" variant="contained" size="small" onClick={() => dispatch(handleModalHOA())}>
                  {" "}
                  <Plus /> Add Clearance Height
                </Button>
              ) : null}
            </div>
            <ModalTemplate onOpen={modalFormHOA} component={() => <FormHOA onClose={() => dispatch(handleModalHOA())} />} />
            <Table className="table-form">
              <Thead>
                <Tr>
                  <Th>Size (Feet)</Th>
                  <Th>Size (Inch)</Th>
                  {isEditPoleInfo ? <Th>Action</Th> : null}
                </Tr>
              </Thead>
              <Tbody>
                {hoaData?.map((item: any, index: any) => (
                  (item?.poleLengthInFeet && item?.poleLengthInInch)
                    ? (
                      <Tr key={index}>
                        <Td>{item?.poleLengthInFeet}</Td>
                        <Td>{item?.poleLengthInInch}</Td>
                        {isEditPoleInfo ? (
                          <Td>
                            <div className="centered">
                              <Button variant="contained" onClick={() => dispatch(deleteHoaData(index))} size="small">
                                Delete
                              </Button>
                            </div>
                          </Td>
                        ) : null}
                      </Tr>
                    )
                    : null
                ))}
              </Tbody>
            </Table>
          </Grid>
          <Grid item sm={12}>
            <FormMidspanImage images={images} setImages={setImages} />
          </Grid>
          <Grid item sm={12}>
            <FormMidspanAttachment files={files} setFiles={setFiles} />
          </Grid>
          {isEditPoleInfo
            ? (
              <Grid item sm={6}>
                <Button className="view" variant="contained" size="small" onClick={() => dispatch(handleModalSpanAndDirection())}>
                  {" "}
                  <Eye /> View span and direction
                </Button>
              </Grid>
            )
            : (
              <Grid item sm={12}>
                <div className="header-content">
                  <h4>Span and Direction</h4>
                </div>
                <div className="figsketch-map">
                  <SpanMapSmall />
                </div>
              </Grid>
            )
          }
          {isEditPoleInfo ? (
            <Grid item sm={12}>
              <div className="wrapbutton">
                <Button type="button" className="cancel" variant="outlined" onClick={handleCancel}>
                  <X /> Cancel
                </Button>
                <Button type="submit" className="save" style={{ marginLeft: 10 }} variant="outlined" onClick={handleEditPole}>
                  <Save /> Save
                </Button>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </form>
    </div>
  );
}