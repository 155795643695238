
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../../state'

export const getAccount = createAsyncThunk('account/getAccount', async () => {
    const response = await axios.get(`${baseUrl}/api/Account/GetList`)
    // console.log(response)
    return response.data
})

export const postAccount = createAsyncThunk('account/postAccount', async (body:any) => {
    try {
        const resAccount = await axios.post(`${baseUrl}/api/Account/Create`, body)
        return resAccount.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const editAccount = createAsyncThunk('account/editAccount', async (body:any) => {
    try {
        const resAccount = await axios.post(`${baseUrl}/api/Account/Edit`, body)
        return resAccount.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const getFieldingManagers = createAsyncThunk('account/getFieldingManagers', async (id : any) => {
    const response = await axios.get(`${baseUrl}/api/Account/GetFieldingManagers`,{
        params : {
            companyId : id
        }
    })
    // console.log(response)
    return response.data
})


export const deleteAccount = createAsyncThunk('account/deleteAccount', async (id:string) => {
    try {
        const resDeleteAccount = await axios.delete(`${baseUrl}/api/Account/Delete`, {
            params : {
                targetUserId : id
            }
        })
        return resDeleteAccount
    } 
    catch (error:any) {
        return error.response
    }
})

export const accountResetPassword = createAsyncThunk('account/accountResetPassword', async (body:any) => {
    try {
        const resAccount = await axios.post(`${baseUrl}/api/Account/ResetPassword`, body)
        return resAccount.data
    } 
    catch (error:any) {
        return error.response
    }
})