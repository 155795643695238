import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AerialFielding from "./AerialFielding";
import Export from "./Export";
import Notes from "./Notes";
import LoadingRequest from "./LoadingRequest";
import SupportingDoc from "./SupportingDoc";
import Inventory from "./Inventory";
import { Typography } from "@material-ui/core";

export default function JobDetailMenu() {

    const { detailMenu, jobNumberIdActive } = useSelector((state: RootState) => state.sideTab);

    return (
        <div className="wraptab detailmenu">
            <div>
                <Typography variant="subtitle1" align="center" style={{ fontWeight: "bold" }}>{detailMenu.label} for</Typography>
                <Typography variant="subtitle1" align="center">Job Number:</Typography>
                <Typography variant="subtitle1" align="center">{jobNumberIdActive.name}</Typography>
                {
                    detailMenu.id === 1
                        ? <AerialFielding />
                        : detailMenu.id === 2
                            ? <Notes />
                            : detailMenu.id === 3
                                ? <Export />
                                : detailMenu.id === 4
                                    ? <SupportingDoc />
                                    : detailMenu.id === 5
                                        ? <LoadingRequest />
                                        : detailMenu.id === 7
                                            ? <Inventory />
                                            : null
                }
            </div>
        </div>
    );
};