import { Container, Button, TextField, MenuItem } from '@material-ui/core';
import Template from '../../../shared/Template';
import { RootState } from '../../../app/store';
import { useSelector, useDispatch } from 'react-redux';
import LoadingCustom from '../../../shared/LoadingCustom';
import MUIDataTable from 'mui-datatables'
import ModalTemplate from '../../../shared/ModalTemplate';
import { handleIsChooseFielderModal, handleIsMapModal, handleIsMapModalFielding, setStatusSelected } from '../assignFielding.reducer';
import { useEffect } from 'react';
import { getAllAssignedJob } from '../assignFielding.api';
import MapModal from '../component/MapModal';
import ChooseFielderModal from '../component/ChooseFielderModal';
import { getMarkerFielding, getMidSpanFieldingRequest, getPoleFieldingRequest } from '../../poleInformation/poleInformation.api';
import { setListMarkerFieldingRequest, setListMidspanFieldingRequest, setListPoleFieldingRequest } from '../../poleInformation/poleInformation.reducer';
import MapModalFielding from '../component/MapModalFielding';

function AssignFielding() {

    const dispatch = useDispatch()

    const { isMapModal, isMapModalFielding, loading, statusSelected, assignFieldingList, isChooseFielderModal } = useSelector((state: RootState) => state.assignFielding)

    const handleChangeStatus = (e: any) => {
        dispatch(setStatusSelected(e.target.value))
    }

    useEffect(() => {
        dispatch(getAllAssignedJob(statusSelected))
    }, [statusSelected])

    const handleIsChooseFielder = (id: String) => {
        dispatch(handleIsChooseFielderModal(assignFieldingList.filter((item: any) => item.fieldingRequestId === id)[0]))
    }

    const handleDetail = async (id: any) => {
        dispatch(handleIsMapModalFielding())
        await dispatch<any>(getMidSpanFieldingRequest(id))
            .unwrap()
            .then((response: any) => {
                dispatch(setListMidspanFieldingRequest(response.data))
            })
        await dispatch<any>(getPoleFieldingRequest(id))
            .unwrap()
            .then((response: any) => {
                dispatch(setListPoleFieldingRequest(response.data));
            })
        await dispatch<any>(getMarkerFielding(id))
            .unwrap()
            .then((response: any) => {
                dispatch(setListMarkerFieldingRequest(response.data));
            })
    }

    const component = () => {
        return (
            <>
                <LoadingCustom isLoading={loading} />
                <ModalTemplate component={() => <MapModal />} onOpen={isMapModal} />
                <ModalTemplate component={() => <MapModalFielding />} onOpen={isMapModalFielding} />
                <ModalTemplate component={() => <ChooseFielderModal />} onOpen={isChooseFielderModal} />
                <div className="assign-fielding">
                    <div className="container">
                        <div style={{ margin: "10px 30px" }}>
                            <MUIDataTable
                                title={(
                                    <div className="MUIDataTableToolbar-left-23">
                                        <div className="MUIDataTableToolbar-titleRoot-27">
                                            <span className="MuiTypography-root MUIDataTableToolbar-titleText-28 MuiTypography-h6" style={{ marginRight: 10 }}>Assign Fielding</span>
                                            <TextField
                                                select
                                                variant="outlined"
                                                size="small"
                                                defaultValue={statusSelected}
                                                onChange={handleChangeStatus}
                                            >
                                                <MenuItem value={0}>Active</MenuItem>
                                                <MenuItem value={1}>Complete</MenuItem>
                                                <MenuItem value={2}>Archived</MenuItem>
                                            </TextField>
                                        </div>
                                    </div>
                                )}
                                data={assignFieldingList}
                                columns={[
                                    {
                                        name: "project",
                                        label: "Project Name"
                                    },
                                    {
                                        name: "jobNumber",
                                        label: "Job Number"
                                    },
                                    {
                                        name: "fieldingRequest",
                                        label: "Fielding Request"
                                    },
                                    {
                                        name: "city",
                                        label: "City & State"
                                    },
                                    {
                                        name: "requestQty",
                                        label: "Request"
                                    },
                                    {
                                        name: "fielderRequestQty",
                                        label: "Added"
                                    },
                                    {
                                        name: "fieldedQty",
                                        label: "Fielded Quantity"
                                    },
                                    {
                                        name: "createdDateString",
                                        label: "Date Assign"
                                    },
                                    {
                                        name: "fieldingRequestDueDateString",
                                        label: "Fielding Request Due Date"
                                    },
                                    {
                                        name: "fieldingRequestId",
                                        label: "Fielder",
                                        options: {
                                            customBodyRender: (value: any) => (
                                                <>
                                                    <span>{assignFieldingList.filter((d: any) => d.fieldingRequestId === value)[0].assignedFielders}</span>, <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleIsChooseFielder(value)}>Choose Fielder</span>
                                                </>
                                            ),
                                            filter: false
                                        }
                                    },
                                    {
                                        name: "lastAssignedDateString",
                                        label: "Date Assigned"
                                    },
                                    {
                                        name: "Action",
                                        label: "",
                                        options: {
                                            customBodyRender: (value: any) => (
                                                <>
                                                    <Button color='primary' variant='outlined' onClick={() => handleDetail(value)}>Map Location</Button>
                                                </>
                                            ),
                                            filter: false
                                        }
                                    }
                                ]}
                                options={{
                                    customToolbar: (data: any) => {
                                        return (
                                            <Button className="button-add" variant='contained' onClick={() => dispatch(handleIsMapModal())}>View Jobs in Map</Button>
                                        )
                                    },
                                    selectableRows: "none",
                                    rowsPerPageOptions: [50, 100, 200],
                                    rowsPerPage: 50,
                                    downloadOptions: {
                                        filename: "utility fielding - assign fielding.csv"
                                    },
                                    print: false,
                                    responsive: false
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return <Template component={component} />
}

export default AssignFielding;