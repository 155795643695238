import { Checkbox, FormControlLabel, Button, InputAdornment, TextField, Grid, MenuItem } from '@material-ui/core';
import { useState } from 'react';
import { CheckSquare, Plus, Trash2, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { handleAnchorAddType, handleAnchorCanvasDotes, handleAnchorCanvasFences, handleAnchorCanvasStreets, handleModalAnchor } from '../poleInformation.reducer';
import AnchorCanvas from './canvas/AnchorCanvas';

function AnchorModal(props: any) {
    const dispatch = useDispatch()

    const { anchorAddType, anchorCanvasDotes, poleEnums } = useSelector((state: RootState) => state.poleInformation)

    const [active, setActive] = useState(0)

    const updateAnchor = (e: any) => {
        let newAnchorCanvasDotes = JSON.parse(JSON.stringify(anchorCanvasDotes))
        let name = e.target.name
        let value = (name === "isAnchorSizeUnknown" || name === "isAnchorEyeUnknown" ? e.target.checked : parseInt(e.target.value))
        newAnchorCanvasDotes.map((anchor: any, index: number) => {
            if (anchor.text === anchorCanvasDotes[active].text) {
                if (name === 'eyesPict') {
                    newAnchorCanvasDotes[index][name] = Boolean(value) ? true : false
                }
                else {
                    newAnchorCanvasDotes[index][name] = value
                }
                dispatch(handleAnchorCanvasDotes(newAnchorCanvasDotes))
            }
        })
    }

    const deleteAnchor = () => {
        let newAnchorCanvasDotes = JSON.parse(JSON.stringify(anchorCanvasDotes))
        newAnchorCanvasDotes.splice(active, 1)
        dispatch(handleAnchorCanvasDotes(newAnchorCanvasDotes))
    }

    const addDownGuy = () => {
        let newAnchorCanvasDotes = JSON.parse(JSON.stringify(anchorCanvasDotes))
        let dgData = {
            size: 0,
            owner: 0,
            isInsulated: true,
            hoa: 0,
            type: 0
        }
        newAnchorCanvasDotes[active].downGuyList.push(dgData)
        dispatch(handleAnchorCanvasDotes(newAnchorCanvasDotes))
    }

    const updateDownGuy = (e: any, i: number) => {
        let newAnchorCanvasDotes = JSON.parse(JSON.stringify(anchorCanvasDotes))
        let name = e.target.name
        let value = parseInt(e.target.value)
        newAnchorCanvasDotes[active].downGuyList[i][name] = value
        dispatch(handleAnchorCanvasDotes(newAnchorCanvasDotes))
    }

    const deleteDownGuy = (i: number) => {
        let newAnchorCanvasDotes = JSON.parse(JSON.stringify(anchorCanvasDotes))
        newAnchorCanvasDotes[active].downGuyList.splice(i, 1)
        dispatch(handleAnchorCanvasDotes(newAnchorCanvasDotes))
    }

    const clearStreets = () => {
        dispatch(handleAnchorCanvasStreets([]))
    }

    const clearFences = () => {
        dispatch(handleAnchorCanvasFences([]))
    }

    return (
        <div className='modalsketchlib'>
            <h3>
                Anchor
                <X onClick={() => dispatch(handleModalAnchor())} />
            </h3>
            <div className="box">
                <div className="figsketch">
                    <AnchorCanvas />
                </div>
                <i style={{ color: 'red' }}>
                    <small>
                        {
                            anchorAddType === 'anchorDot' ?
                                'Click canvas to Add Anchor !' :
                                anchorAddType === 'anchorStreet' ?
                                    'Touch and drag to lay line indicate Street' :
                                    anchorAddType === 'anchorFence' ?
                                        'Touch and drag to lay line indicate Fence'
                                        : null
                        }
                    </small>
                </i>
                <div className="anchorbut">
                    <Button variant='contained' onClick={() => dispatch(handleAnchorAddType('anchorDot'))}>Add anchor</Button>
                    <div className='cb'>
                        <Button variant='contained' onClick={() => dispatch(handleAnchorAddType('anchorStreet'))}>add street</Button>
                        <Button variant='contained' onClick={() => dispatch(handleAnchorAddType('anchorFence'))}>add fence</Button>
                    </div>
                </div>
                <div className="anchorclear">
                    <Button variant='contained' onClick={clearStreets}>clear street</Button>
                    <Button variant='contained' onClick={clearFences}>clear fence</Button>
                </div>
                <br />
                <form >
                    {anchorCanvasDotes?.length > 0 &&
                        <Grid container spacing={2}>
                            <Grid item sm={10}>
                                <TextField id="select" className='inp' label="Select Active Anchor" value={active} variant='outlined' size='small' select onChange={(e) => setActive(parseInt(e.target.value))}>
                                    {anchorCanvasDotes.map((val: any, index: number) => {
                                        return <MenuItem key={index} value={index}>{val.text}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item sm={2}>
                                <Button fullWidth variant="contained" style={{ backgroundColor: "red", color: "white" }} onClick={deleteAnchor}><Trash2 /> Delete</Button>
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    type="number"
                                    className='inp'
                                    variant='outlined'
                                    label="Distance from Pole"
                                    size='small'
                                    name='distance'
                                    value={anchorCanvasDotes[active]?.distance}
                                    onChange={updateAnchor}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                Feet
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField id="select" className='inp' label="Anchor Size" variant='outlined' size='small' select name='size' value={anchorCanvasDotes[active]?.size} onChange={updateAnchor}>
                                    {poleEnums?.anchorSizes?.map((val: any) => {
                                        return (
                                            <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item sm={6}>
                                <TextField id="select" className='inp' label="Anchor Eyes" variant='outlined' size='small' select name='anchorEye' value={anchorCanvasDotes[active]?.anchorEye} onChange={updateAnchor}>
                                    {poleEnums?.anchorEyes?.map((val: any) => {
                                        return (
                                            <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item sm={6}>
                                <TextField id="select" className='inp' label="Picture of Anchor Eyes" variant='outlined' size='small' select name='eyesPict' value={anchorCanvasDotes[active]?.eyesPict ? 1 : 0} onChange={updateAnchor}>
                                    {["No", "Yes"].map((val, index) => {
                                        return (
                                            <MenuItem key={index} value={index}>{val}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item sm={6}>
                                <TextField id="select" className='inp' label="Condition" variant='outlined' size='small' select name='anchorCondition' value={anchorCanvasDotes[active]?.anchorCondition} onChange={updateAnchor}>
                                    {poleEnums?.anchorConditions?.map((val: any) => {
                                        return (
                                            <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item sm={12}>
                                <Button variant='contained' className="dynformbut" onClick={addDownGuy}><Plus />add DG</Button>
                            </Grid>
                            {anchorCanvasDotes[active]?.downGuyList.map((dg: any, dgIndex: number) => {
                                return (
                                    <Grid key={dgIndex} item sm={12}>
                                        <div className="dynform">
                                            <div className="head"><h4>DG{dgIndex + 1}</h4> <Trash2 onClick={() => deleteDownGuy(dgIndex)} /> </div>
                                            <TextField id="select" className='inp' label="DG Condition" variant='outlined' size='small' select name='type' value={dg?.type} onChange={(e) => updateDownGuy(e, dgIndex)}>
                                                {poleEnums?.downGuyTypes?.map((val: any) => {
                                                    return (
                                                        <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                            <TextField id="select" className='inp' label="DG Size" variant='outlined' size='small' select name='size' value={dg?.size} onChange={(e) => updateDownGuy(e, dgIndex)}>
                                                {poleEnums?.downGuySizes?.map((val: any) => {
                                                    return (
                                                        <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                            <TextField id="select" className='inp' label="DG Owner" variant='outlined' size='small' select name='owner' value={dg?.owner} onChange={(e) => updateDownGuy(e, dgIndex)}>
                                                {poleEnums?.downGuyOwners?.map((val: any) => {
                                                    return (
                                                        <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                            <TextField
                                                className='inp'
                                                variant='outlined'
                                                label="HOA"
                                                size='small'
                                                name='hoa'
                                                value={dg?.hoa}
                                                onChange={(e) => updateDownGuy(e, dgIndex)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            Feet
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <TextField id="select" className='inp' label="Insulated" variant='outlined' size='small' select name='insulated' value={(dg?.insulated ? 1 : 0)} onChange={(e) => updateDownGuy(e, dgIndex)}>
                                                {["No", "Yes"].map((val, index) => {
                                                    return (
                                                        <MenuItem key={index} value={index}>{val}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                    <br />
                    <Grid item sm={12}>
                        <div className="wrapbutton">
                            <Button variant='outlined' className="create" onClick={() => dispatch(handleModalAnchor())} ><CheckSquare /> Done</Button>
                        </div>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default AnchorModal;