function Roles(menuName) {
    let rules = {
        fielder: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 0,
            project_add: 0,
            project_edit: 0,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 0,
            jobnumber_edit: 0,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 0,
            fielding_request_add_pole: 0,
            fielding_request_edit: 0,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 1,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        fieldingmanager: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 0,
            project_add: 0,
            project_edit: 0,
            project_delete: 0,
            jobnumber_view: 0,
            jobnumber_add: 0,
            jobnumber_edit: 0,
            jobnumber_delete: 0,
            fielding_request_view: 0,
            fielding_request_add: 0,
            fielding_request_add_pole: 0,
            fielding_request_edit: 0,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 0,
            tracker_pole_loading: 0,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 1,
            map_view: 0,
            profile: 0,
            loading_management_feature: 0,
            home: "/assign-fielding"
        },
        engineer: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        fieldingrequestsimple: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 0,
            fielding_request_edit: 1,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        fieldersimple: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 0,
            fielding_request_edit: 1,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        companyadmin: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 1,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 1,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 1,
            fielding_request_complete: 0,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 1,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        projectmanager: {
            select_company: 0,
            project_form_share_email: 1,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 1,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 1,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 1,
            fielding_request_complete: 1,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        projectteam: {
            select_company: 0,
            project_form_share_email: 1,
            project_form_asign_manager: 1,
            project_add: 0,
            project_edit: 1,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 1,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 1,
            fielding_request_complete: 1,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        cscf: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 0,
            project_add: 0,
            project_edit: 0,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 0,
            jobnumber_edit: 0,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 0,
            fielding_request_add_pole: 0,
            fielding_request_edit: 0,
            fielding_request_delete: 0,
            fielding_request_complete: 1,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 1,
            tracker_account_option_cscf: 1,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        csca: {
            select_company: 1,
            project_form_share_email: 1,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 1,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 1,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 1,
            fielding_request_complete: 1,
            tracker_fielding_request: 1,
            tracker_pole_loading: 1,
            tracker_account_all: 1,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 1,
            tracker_account_option_cscf: 1,
            tracker_company: 1,
            assign_fielding: 1,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        calcspec: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 0,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/fieldingrequest"
        },
        calcspecmanager: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 0,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 1,
            home: "/poleloading"
        },
        loadingqc: {
            select_company: 0,
            project_form_share_email: 0,
            project_form_asign_manager: 1,
            project_add: 1,
            project_edit: 1,
            project_delete: 0,
            jobnumber_view: 1,
            jobnumber_add: 1,
            jobnumber_edit: 1,
            jobnumber_delete: 0,
            fielding_request_view: 1,
            fielding_request_add: 1,
            fielding_request_add_pole: 1,
            fielding_request_edit: 1,
            fielding_request_delete: 0,
            fielding_request_complete: 0,
            tracker_fielding_request: 0,
            tracker_pole_loading: 1,
            tracker_account_all: 0,
            tracker_account_filtered: 0,
            tracker_account_option_csca: 0,
            tracker_account_option_cscf: 0,
            tracker_company: 0,
            assign_fielding: 0,
            map_view: 1,
            profile: 0,
            loading_management_feature: 0,
            home: "/poleloading"
        }
    }

    let roles = localStorage.getItem("roles")
    let roleArr = roles?.split(',') || []

    if (menuName === "home") {
        let url = ""
        for (var i = 0; i < roleArr.length; i++) {
            url = rules[roleArr[i].toLowerCase()][menuName]
            if (url === "/fieldingrequest") break
        }
        return url
    }
    else {
        let isAllow = false;
        for (var i = 0; i < roleArr.length; i++) {
            isAllow = rules[roleArr[i].toLowerCase()][menuName] ? true : false
            if (isAllow) break
        }
        return isAllow
    }
}

export default Roles;