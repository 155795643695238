import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { completeJobNumber, deleteFieldingRequest, deleteJobNumber, deleteJobNumberAttachment, deleteNoteMarker, deleteProject, editFieldingRequest, editJobNumber, editNoteLocation, editNoteMarker, editProject, getFieldingRequest, getJobNumber, getJobNumberAttachmentList, getNoteListByJobNumberId, getLoadingRequest, getProject, postFieldingRequest, postJobNumber, postNoteMarker, postLoadingRequest, postProject, getNoteEnums, deleteLoadingRequest, getFieldingRequestAttachmentList, deleteFieldingRequestAttachment, editLoadingRequest, reactiveJobNumber, archiveJobNumber, generateAllDocument, completeFieldingRequest, addPolesToLoadingRequest, removePolesToLoadingRequest, deleteTempNoteMarker, getNoteListDeleted, deleteUndoNoteMarker, getFieldingRequestAttachmentLogs, createComment, getNoteChat, completeMarker, importJsonFielding, getJobNumberChoose, getFieldingRequestChoose, requestDeleteNoteMarker, getNoteDeleteRequests, rejectRequestDeleteNoteMarker, generateKmlJob, generateKmlFielding, generateKmlMultipleJob, generateKmlMultipleFielding, deleteFieldingRequestAttachmentMultiple, getInventorytByJobNumberId, postInventory, editInventory, deleteInventory, deleteTempFieldingRequest, getFieldingRequestDeleted, getFieldingRequestIncludeDeleted, fieldingrequestUndoDelete, reactiveFieldingRequest, getJobNumberDetails } from "./sideTab.api";

export interface SideTab {
  isLayerActive: any;
  noteIcon: any;
  fieldingIcon: any;
  modalProject: boolean;
  modalJobNumber: boolean;
  modalAerialFielding: any;
  modalLoadingRequest: any;
  formType: string;
  formTypeJobNumber: string;
  // formTypeAerialFielding : string,
  loading: boolean;
  listProject: any;
  projectIdActive: any;
  statusJobNumber: any;
  listJobNumber: any;
  jobNumberIdActive: any;
  statusFieldingRequest: any;
  listFieldingRequest: any;
  listFieldingRequestIncludeDeleted: any;
  listFieldingRequestDeleted: any;
  listLoadingRequest: any;
  fieldingRequestIdActive: any;
  loadingRequestIdActive: any;
  notesActive: boolean;
  fieldingIdList: any;
  loadingRequestIdList: any;
  jobIdList: any;
  sideTabActive: any;
  jobDetail: any;
  detailMenu: any;
  noteList: any,
  noteListDeleted: any,
  attachmentList: any,
  fieldingAttachmentList: any,
  fieldingAttachmentLogs: any,
  noteEnums: any,
  chatList: any,
  statusNotes: number,
  requestDeleteList: any,
  inventoryList: any,
  inventoryFormModal: any,
  inventoryActive: any,
  inventoryIdList: any,
}

const initialState: SideTab = {
  isLayerActive: { layerJobNumberId: "", layerFieldingId: "", note: false, label: "" }, //label= jobnumber /aerial / note
  noteIcon: null,
  fieldingIcon: null,
  modalProject: false,
  modalJobNumber: false,
  modalAerialFielding: { isOpen: false, formType: "" },
  modalLoadingRequest: { isOpen: false, formType: "" },
  formType: "", //info | edit | default is new
  formTypeJobNumber: "", //info | edit | default is new
  // formTypeAerialFielding : '', //info | edit | default is new,
  loading: false,
  listProject: [],
  projectIdActive: { id: "", name: "" },
  statusJobNumber: 0,
  listJobNumber: [],
  jobNumberIdActive: { id: "", name: "" },
  statusFieldingRequest: "0",
  listFieldingRequest: [],
  listFieldingRequestIncludeDeleted: [],
  listFieldingRequestDeleted: [],
  listLoadingRequest: [],
  fieldingRequestIdActive: { id: "", name: "" },
  loadingRequestIdActive: { id: "", name: "" },
  notesActive: false,
  fieldingIdList: [],
  loadingRequestIdList: [],
  jobIdList: [],
  sideTabActive: { project: true, jobNumber: false, jobDetail: false, jobDetailMenu: false },
  jobDetail: null,
  detailMenu: {},
  noteList: [],
  noteListDeleted: [],
  attachmentList: [],
  fieldingAttachmentList: [],
  fieldingAttachmentLogs: [],
  noteEnums: {},
  chatList: [],
  statusNotes: 0,
  requestDeleteList: [],
  inventoryList: [],
  inventoryFormModal: { isOpen: false, formType: "" },
  inventoryActive: { id: "", name: "" },
  inventoryIdList: []
};

export const sideTabSlice = createSlice({
  name: "sideTab",
  initialState,
  reducers: {
    handleLayerActive: (state, action: PayloadAction<any>) => {
      state.isLayerActive = action.payload;
    },
    handleNoteIcon: (state, action: PayloadAction<any>) => {
      state.noteIcon = action.payload;
    },
    handleFieldingIcon: (state, action: PayloadAction<any>) => {
      state.fieldingIcon = action.payload;
    },
    /// modals
    handleModalProject: (state) => {
      state.modalProject = !state.modalProject;
    },
    handleModalJobNumber: (state) => {
      state.modalJobNumber = !state.modalJobNumber;
    },
    handleModalAerialFielding: (state, action: PayloadAction<any>) => {
      state.modalAerialFielding = action.payload;
    },
    handleModalLoadingRequest: (state, action: PayloadAction<any>) => {
      state.modalLoadingRequest = action.payload;
    },
    ///
    handleFormType: (state, action: PayloadAction<string>) => {
      state.formType = action.payload;
    },
    handleFormTypeJobNumber: (state, action: PayloadAction<string>) => {
      state.formTypeJobNumber = action.payload;
    },
    ///
    handleProjectIdActive: (state, action: PayloadAction<any>) => {
      state.projectIdActive = action.payload;
    },
    handleJobNumberIdActive: (state, action: PayloadAction<any>) => {
      state.jobNumberIdActive = action.payload;
    },
    handleFieldingRequestIdActive: (state, action: PayloadAction<any>) => {
      state.fieldingRequestIdActive = action.payload;
    },
    handleLoadingRequestIdActive: (state, action: PayloadAction<any>) => {
      state.loadingRequestIdActive = action.payload;
    },
    handleNotesActive: (state) => {
      state.notesActive = true;
    },
    handleNotesDeactive: (state) => {
      state.notesActive = false;
    },
    setFieldingList: (state, action) => {
      state.listFieldingRequest = action.payload || [];
    },
    setFieldingIdList: (state, action) => {
      state.fieldingIdList = action.payload;
    },
    setLoadingRequestIdList: (state, action) => {
      state.loadingRequestIdList = action.payload;
    },
    setStatusJobNumber: (state, action) => {
      state.statusJobNumber = action.payload;
    },
    setStatusFieldingRequest: (state, action) => {
      state.statusFieldingRequest = action.payload;
    },
    setListJobNumber: (state, action) => {
      state.listJobNumber = action.payload;
    },
    setJobIdList: (state, action) => {
      state.jobIdList = action.payload;
    },
    setSidetabActive: (state, action) => {
      state.sideTabActive = action.payload;
    },
    setJobDetail: (state, action) => {
      state.jobDetail = action.payload;
    },
    setDetailMenu: (state, action) => {
      state.detailMenu = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNoteList: (state, action) => {
      state.noteList = action.payload;
    },
    setNoteListDeleted: (state, action) => {
      state.noteListDeleted = action.payload;
    },
    clearChatList: (state) => {
      state.chatList = []
      state.requestDeleteList = []
    },
    setStatusNotes: (state, action) => {
      state.statusNotes = action.payload
    },
    handleInventoryFormModal: (state, action: PayloadAction<any>) => {
      state.inventoryFormModal = action.payload;
    },
    handleInventoryActive: (state, action: PayloadAction<any>) => {
      state.inventoryActive = action.payload;
    },
    handleInventoryIdList: (state, action: PayloadAction<any>) => {
      state.inventoryIdList = action.payload;
    }
  },
  extraReducers: (builder) => {
    //post project
    builder.addCase(postProject.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postProject.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postProject.rejected, (state, action) => {
      state.loading = false;
    });
    //edit project
    builder.addCase(editProject.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editProject.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editProject.rejected, (state, action) => {
      state.loading = false;
    });
    //get project list
    builder.addCase(getProject.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.status === 200 || action.payload.status === true) {
        state.listProject = action.payload.data;
      }
    });
    builder.addCase(getProject.rejected, (state, action) => {
      state.loading = false;
    });
    //delete project
    builder.addCase(deleteProject.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteProject.rejected, (state, action) => {
      state.loading = false;
    });
    //post jnumber
    builder.addCase(postJobNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postJobNumber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postJobNumber.rejected, (state, action) => {
      state.loading = false;
    });
    //edit jnumber
    builder.addCase(editJobNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editJobNumber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editJobNumber.rejected, (state, action) => {
      state.loading = false;
    });
    //get jobnumber active list
    builder.addCase(getJobNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobNumber.fulfilled, (state, action) => {
      state.loading = false;
      state.listJobNumber = action.payload.data;
    });
    builder.addCase(getJobNumber.rejected, (state, action) => {
      state.loading = false;
    });
    //untuk pilih move dan copy
    builder.addCase(getJobNumberChoose.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobNumberChoose.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getJobNumberChoose.rejected, (state, action) => {
      state.loading = false;
    });
    //delete jnumber
    builder.addCase(deleteJobNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteJobNumber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteJobNumber.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(getJobNumberDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobNumberDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.jobNumberIdActive = action.payload.data;
    });
    builder.addCase(getJobNumberDetails.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(generateKmlJob.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateKmlJob.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(generateKmlJob.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(generateKmlFielding.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateKmlFielding.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(generateKmlFielding.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(generateKmlMultipleJob.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateKmlMultipleJob.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(generateKmlMultipleJob.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(generateKmlMultipleFielding.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateKmlMultipleFielding.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(generateKmlMultipleFielding.rejected, (state, action) => {
      state.loading = false;
    });
    //attachment
    builder.addCase(getJobNumberAttachmentList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobNumberAttachmentList.fulfilled, (state, action) => {
      state.loading = false;
      state.attachmentList = action.payload.data;
    });
    builder.addCase(getJobNumberAttachmentList.rejected, (state, action) => {
      state.loading = false;
    });
    //delete jnumber attachment
    builder.addCase(deleteJobNumberAttachment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteJobNumberAttachment.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteJobNumberAttachment.rejected, (state, action) => {
      state.loading = false;
    });
    //complete jnumber
    builder.addCase(completeJobNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(completeJobNumber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(completeJobNumber.rejected, (state, action) => {
      state.loading = false;
    });
    //complete fieldingrequest
    builder.addCase(completeFieldingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(completeFieldingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(completeFieldingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //complete fieldingrequest
    builder.addCase(reactiveFieldingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(reactiveFieldingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(reactiveFieldingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //reactive jnumber
    builder.addCase(reactiveJobNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(reactiveJobNumber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(reactiveJobNumber.rejected, (state, action) => {
      state.loading = false;
    });
    //archive jnumber
    builder.addCase(archiveJobNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(archiveJobNumber.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(archiveJobNumber.rejected, (state, action) => {
      state.loading = false;
    });
    // get filding req
    builder.addCase(getFieldingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFieldingRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.listFieldingRequest = action.payload.data;
    });
    builder.addCase(getFieldingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    // get filding req
    builder.addCase(getFieldingRequestIncludeDeleted.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFieldingRequestIncludeDeleted.fulfilled, (state, action) => {
      state.loading = false;
      state.listFieldingRequestIncludeDeleted = action.payload.data;
    });
    builder.addCase(getFieldingRequestIncludeDeleted.rejected, (state, action) => {
      state.loading = false;
    });
    // get filding req deleted
    builder.addCase(getFieldingRequestDeleted.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFieldingRequestDeleted.fulfilled, (state, action) => {
      state.loading = false;
      state.listFieldingRequestDeleted = action.payload.data;
    });
    builder.addCase(getFieldingRequestDeleted.rejected, (state, action) => {
      state.loading = false;
    });
    // untuk pilih move pole
    builder.addCase(getFieldingRequestChoose.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFieldingRequestChoose.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getFieldingRequestChoose.rejected, (state, action) => {
      state.loading = false;
    });
    //post filding req
    builder.addCase(postFieldingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postFieldingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postFieldingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //post filding req
    builder.addCase(editFieldingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editFieldingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editFieldingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //delete temp filding req
    builder.addCase(deleteTempFieldingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteTempFieldingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteTempFieldingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(fieldingrequestUndoDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fieldingrequestUndoDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fieldingrequestUndoDelete.rejected, (state, action) => {
      state.loading = false;
    });
    //delete filding req
    builder.addCase(deleteFieldingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteFieldingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteFieldingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //get filding req attachment
    builder.addCase(getFieldingRequestAttachmentList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFieldingRequestAttachmentList.fulfilled, (state, action) => {
      state.loading = false;
      state.fieldingAttachmentList = action.payload.data;
    });
    builder.addCase(getFieldingRequestAttachmentList.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(getFieldingRequestAttachmentLogs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFieldingRequestAttachmentLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.fieldingAttachmentLogs = action.payload.data;
    });
    builder.addCase(getFieldingRequestAttachmentLogs.rejected, (state, action: any) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        html: action.payload?.data
      })
    });
    //delete fielding req attachment
    builder.addCase(deleteFieldingRequestAttachment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteFieldingRequestAttachment.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteFieldingRequestAttachment.rejected, (state, action) => {
      state.loading = false;
    });
    //delete fielding req attachment multiple
    builder.addCase(deleteFieldingRequestAttachmentMultiple.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteFieldingRequestAttachmentMultiple.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteFieldingRequestAttachmentMultiple.rejected, (state, action) => {
      state.loading = false;
    });
    //generate download all
    builder.addCase(generateAllDocument.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateAllDocument.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(generateAllDocument.rejected, (state, action: any) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        html: action.payload?.data
      })
    });
    //
    builder.addCase(importJsonFielding.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(importJsonFielding.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(importJsonFielding.rejected, (state, action: any) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        html: action.payload?.data
      })
    });
    //
    builder.addCase(postInventory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postInventory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postInventory.rejected, (state, action: any) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        html: action.payload?.data
      })
    });
    //
    builder.addCase(editInventory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editInventory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editInventory.rejected, (state, action: any) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        html: action.payload?.data
      })
    });
    //
    builder.addCase(getInventorytByJobNumberId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getInventorytByJobNumberId.fulfilled, (state, action) => {
      state.loading = false;
      state.inventoryList = action.payload.data;
    });
    builder.addCase(getInventorytByJobNumberId.rejected, (state, action: any) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        html: action.payload?.data
      })
    });
    builder.addCase(deleteInventory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteInventory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteInventory.rejected, (state, action: any) => {
      state.loading = false
    });
    //crete note marker
    builder.addCase(postNoteMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postNoteMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postNoteMarker.rejected, (state, action) => {
      state.loading = false;
    });
    // get notes
    builder.addCase(getNoteListByJobNumberId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNoteListByJobNumberId.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getNoteListByJobNumberId.rejected, (state, action) => {
      state.loading = false;
    });
    // get notes
    builder.addCase(getNoteListDeleted.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNoteListDeleted.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getNoteListDeleted.rejected, (state, action) => {
      state.loading = false;
    });
    // delete note
    builder.addCase(deleteNoteMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteNoteMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteNoteMarker.rejected, (state, action) => {
      state.loading = false;
    });
    // delete note
    builder.addCase(requestDeleteNoteMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(requestDeleteNoteMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(requestDeleteNoteMarker.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(rejectRequestDeleteNoteMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(rejectRequestDeleteNoteMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(rejectRequestDeleteNoteMarker.rejected, (state, action) => {
      state.loading = false;
    });
    // delete note
    builder.addCase(deleteTempNoteMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteTempNoteMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteTempNoteMarker.rejected, (state, action) => {
      state.loading = false;
    });
    // delete note
    builder.addCase(deleteUndoNoteMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteUndoNoteMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteUndoNoteMarker.rejected, (state, action) => {
      state.loading = false;
    });
    //edit note marker
    builder.addCase(editNoteMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editNoteMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editNoteMarker.rejected, (state, action) => {
      state.loading = false;
    });
    //edit note location
    builder.addCase(editNoteLocation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editNoteLocation.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editNoteLocation.rejected, (state, action) => {
      state.loading = false;
    });
    //get note enums
    builder.addCase(getNoteEnums.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNoteEnums.fulfilled, (state, action) => {
      state.loading = false;
      state.noteEnums = action.payload.data;
    });
    builder.addCase(getNoteEnums.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(createComment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createComment.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createComment.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(getNoteChat.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNoteChat.fulfilled, (state, action) => {
      state.loading = false;
      state.chatList = action.payload.data;
    });
    builder.addCase(getNoteChat.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(getNoteDeleteRequests.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNoteDeleteRequests.fulfilled, (state, action) => {
      state.loading = false;
      state.requestDeleteList = action.payload.data;
    });
    builder.addCase(getNoteDeleteRequests.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(completeMarker.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(completeMarker.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(completeMarker.rejected, (state, action) => {
      state.loading = false;
    });
    //post loading request
    builder.addCase(postLoadingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postLoadingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postLoadingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //edit loading request
    builder.addCase(editLoadingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editLoadingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editLoadingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    // get loading request
    builder.addCase(getLoadingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLoadingRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.listLoadingRequest = action.payload.data;
    });
    builder.addCase(getLoadingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //delete loading req
    builder.addCase(deleteLoadingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteLoadingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteLoadingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(addPolesToLoadingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addPolesToLoadingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addPolesToLoadingRequest.rejected, (state, action) => {
      state.loading = false;
    });
    //
    builder.addCase(removePolesToLoadingRequest.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removePolesToLoadingRequest.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removePolesToLoadingRequest.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  handleLayerActive,
  handleNoteIcon,
  handleFieldingIcon,
  handleModalProject,
  handleModalJobNumber,
  handleModalAerialFielding,
  handleModalLoadingRequest,
  handleFormType,
  handleFormTypeJobNumber,
  handleProjectIdActive,
  handleJobNumberIdActive,
  handleNotesActive,
  handleNotesDeactive,
  handleFieldingRequestIdActive,
  handleLoadingRequestIdActive,
  setFieldingList,
  setFieldingIdList,
  setLoadingRequestIdList,
  setStatusJobNumber,
  setStatusFieldingRequest,
  setListJobNumber,
  setJobIdList,
  setSidetabActive,
  setJobDetail,
  setDetailMenu,
  setLoading,
  setNoteList,
  setNoteListDeleted,
  clearChatList,
  setStatusNotes,
  handleInventoryFormModal,
  handleInventoryActive,
  handleInventoryIdList
} = sideTabSlice.actions;

export default sideTabSlice.reducer;
