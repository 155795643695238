import { createSlice } from '@reduxjs/toolkit'

export interface TcpState {
  value: number,
  listTcp : any,
  loading : boolean
}
const initialState: TcpState = {
  value: 0,
  listTcp : [],
  loading : false
}

export const counterSlice = createSlice({
    name: 'tcp',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
    },
})

export default counterSlice.reducer