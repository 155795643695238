import { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { handleRiserAddType, handleRiserCanvasDotes, handleRiserCanvasFences } from '../../poleInformation.reducer'
import backgroundCanvas from '../../../../assets/images/bgcanvas.png'

export default function RiserCanvas() {
    const dispatch = useDispatch()
    const {
        riserCanvasDotes,
        riserAddType,
        riserCanvasFences,
        riserCanvasDotesActive
    } = useSelector((state: RootState) => state.poleInformation)
    const [lines, setLines] = useState<any>({})

    const canvas: any = document.getElementById('canvasRiser')

    const translatedX = (x: any) => {
        var rect = canvas.getBoundingClientRect();
        var factor = canvas.width / rect.width;
        return factor * (x - rect.left);
    }

    const translatedY = (y: any) => {
        var rect = canvas.getBoundingClientRect();
        var factor = canvas.height / rect.height;
        return factor * (y - rect.top);
    }

    const typeInitial = ["T", "C", "O", "P"]
    const valueInitial = ["1in", "2in", "3in", "4in", "VGR"]

    useLayoutEffect(() => {
        const canvas: any = document.getElementById('canvasRiser')
        const ctx: any = canvas.getContext("2d")

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        riserCanvasDotes.forEach((res: any) => {
            ctx.strokeStyle = 'grey'
            ctx.beginPath();
            ctx.arc((res.shapeX *2 ), (res.shapeY * 2), 15, 0, 2 * Math.PI, false);
            ctx.font = '10px, Calibri';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'red';
            ctx.fillText(res.generalRVGRSequence + "." + typeInitial[res.type] + "R - " + valueInitial[res.value], (res.textX * 2), (res.textY * 2) + 3);
            ctx.closePath();
            ctx.stroke();
        })
        
        riserCanvasFences.forEach((res: any) => {
            ctx.strokeStyle = `${res.stroke}`
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.lineTo((res.points.x1 * 2), (res.points.y1 * 2));
            ctx.lineTo((res.points.x2 * 2), (res.points.y2 * 2));
            ctx.closePath();
            ctx.stroke();
        })

    }, [
        riserCanvasDotes,
        riserCanvasFences
    ])


    let handleClick = (e: any) => {
        if (riserAddType === 'riserDot' || riserAddType === 'riserDotMove') {
            let { clientX, clientY } = e
            let newRiserVanvasDotes = JSON.parse(JSON.stringify(riserCanvasDotes))
            newRiserVanvasDotes[riserCanvasDotesActive].shapeX = (translatedX(clientX) / 2)
            newRiserVanvasDotes[riserCanvasDotesActive].shapeY = (translatedY(clientY) / 2)
            newRiserVanvasDotes[riserCanvasDotesActive].textX = (translatedX(clientX) / 2)
            newRiserVanvasDotes[riserCanvasDotesActive].textY = (translatedY(clientY + 3) / 2)
            dispatch(handleRiserCanvasDotes(newRiserVanvasDotes))
            dispatch(handleRiserAddType('riserDotMove'))
        }
    }

    let handleMouseDown = (e: any) => {
        if (riserAddType === 'riserFence') {
            let { clientX, clientY } = e
            setLines({ x1: translatedX(clientX) / 2, y1: translatedY(clientY) / 2})
        }
    }

    let handleMouseUp = (e: any) => {
        if (riserAddType === 'riserFence') {
            let { clientX, clientY } = e
            dispatch(handleRiserCanvasFences([...riserCanvasFences, { points: { ...lines, x2: translatedX(clientX) / 2, y2: translatedY(clientY) / 2 }, stroke: 'brown' }]))
            dispatch(handleRiserAddType('riserDotMove'))
        }
    }

    return (
        <div id="frame">
            <canvas
                id='canvasRiser'
                style={{ backgroundImage: `url('${backgroundCanvas}')`, cursor: 'crosshair' }}
                width={600}
                height={400}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onClick={handleClick}
            >
            </canvas>
        </div>
    )
}
