import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getAccount, postAccount, getFieldingManagers, deleteAccount, editAccount, accountResetPassword } from './account.api'


export interface AccountState {
  value: number,
  listAccount : any,
  loading : boolean,
  postAccountData : any,
  listFieldingManagers : any
}
const initialState: AccountState = {
  value: 0,
  listAccount : [],
  listFieldingManagers: [],
  loading : false,
  postAccountData : null
}

export const counterSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
        // },
        // decrement: (state) => {
        // state.value -= 1
        // },
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        // state.value += action.payload
        // },
    },
    extraReducers : (builder) => {
        // get
        builder.addCase(getAccount.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getAccount.fulfilled, (state, action) => {
            state.loading = false
            state.listAccount = action.payload.data
        })
        builder.addCase(getAccount.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(postAccount.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postAccount.fulfilled, (state, action) => {
            state.loading = false
            state.postAccountData = action.payload.data
        })
        builder.addCase(postAccount.rejected, (state, action) => {
            state.loading = false
            // state.postAccountData = action.payload.
        })
        builder.addCase(editAccount.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(editAccount.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editAccount.rejected, (state, action) => {
            state.loading = false
        })
        // get fielding managers
        builder.addCase(getFieldingManagers.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getFieldingManagers.fulfilled, (state, action) => {
            state.loading = false
            state.listFieldingManagers = action.payload.data
        })
        builder.addCase(getFieldingManagers.rejected, (state, action) => {
            state.loading = false
        })
        // delete account
        builder.addCase(deleteAccount.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(deleteAccount.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteAccount.rejected, (state, action) => {
            state.loading = false
        })
        //
        builder.addCase(accountResetPassword.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(accountResetPassword.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(accountResetPassword.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer