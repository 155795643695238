import React from 'react';

import {ScaleLoader} from "react-spinners";

// function LoadingCustom(props:any) {
//     if (props.isLoading) {
//         return (
//             <div className='loading_custom'>
//                 <ScaleLoader color="white" />
//             </div>
//         );
//     }
//     return null
// }

// export default LoadingCustom;

import {Modal, Backdrop, Fade} from '@material-ui/core';

function LoadingCustom(props: any) {

    return (
        <div>
            <Modal
                className='custom_modal loading_custom'
                open={props.isLoading}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.isLoading} >
                    <div className='modal_remove_outline'>
                    <ScaleLoader color="white" />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default LoadingCustom;