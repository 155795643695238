import { Button, TextField, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { Save, X } from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";

export default function AerialFieldingColorModal(props: any) {

  const { poleEnums } = useSelector((state: RootState) => state.poleInformation);

  const [values, setValues] = useState(0)

  const handleSave = (e: any) => {
    e.preventDefault()
    props.onSubmit(values)
  }

  return (
    <div className="formnote">
      <h3>Change Color<X onClick={props.handleCancel} /></h3>
      <br />
      <form onSubmit={(handleSave)}>
        <TextField
          id="select"
          className="inp"
          label="Color"
          variant="outlined"
          size="small"
          select
          name="color"
          onChange={(e) => setValues(parseInt(e.target.value))}
        >
          {poleEnums?.poleColors?.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
          ))}
        </TextField>
        <br />
        <div className="wrapbutton">
          <Button type="submit" className="save" variant="outlined" onClick={handleSave}>
            <Save /> Save
          </Button>
        </div>
      </form>
    </div >
  )
}
