import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Trash, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setNoteSymbolModal } from "../../../dashboard/dashboard.reducer";
import { deleteTempNoteMarker, getNoteListByJobNumberId, getNoteListDeleted } from "../../sideTab.api";
import { setNoteList, setNoteListDeleted } from "../../sideTab.reducer";

export default function NoteSymbolModal() {

  const dispatch = useDispatch()

  const { noteSymbolModal } = useSelector((state: RootState) => state.dashboard)
  const { projectIdActive, noteList, noteListDeleted } = useSelector((state: RootState) => state.sideTab)

  const [form, setForm] = useState<any>({
    type: "",
    project: "",
    company: "",
    location: "",
    userId: localStorage.getItem("id")
  })

  useEffect(() => {
    setForm({
      ...form,
      type: noteSymbolModal?.data?.markerType === 0 ? "Anchor" : noteSymbolModal?.data?.markerType === 1 ? "Tree" : "",
      project: projectIdActive?.projectNumber,
      company: projectIdActive?.companyName,
      location: noteSymbolModal?.data?.latitude + ", " + noteSymbolModal?.data?.longitude,
    })
  }, [projectIdActive, noteSymbolModal])

  const handleCancel = () => {
    dispatch(setNoteSymbolModal({ status: false }))
  }

  const handleDelete = () => {
    dispatch<any>(deleteTempNoteMarker(noteSymbolModal?.data?.id))
      .unwrap()
      .then((res: any) => {
        dispatch<any>(getNoteListByJobNumberId(noteSymbolModal?.data?.jobNumberId))
          .unwrap()
          .then((res: any) => {
            let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== noteSymbolModal?.data?.jobNumberId)
            dispatch(setNoteList(noteListNew.concat(res.data)))
            dispatch(setNoteSymbolModal({ status: false }))
          })
        dispatch<any>(getNoteListDeleted(noteSymbolModal?.data?.jobNumberId))
          .unwrap()
          .then((res: any) => {
            let noteListDeletedNew: any = noteListDeleted?.filter((item: any) => item.jobNumberId !== noteSymbolModal?.data?.jobNumberId)
            dispatch(setNoteListDeleted(noteListDeletedNew.concat(res.data)))
          })
      })
  }

  return (
    <div className="formproject">
      <h3>Symbol <X onClick={handleCancel} /></h3>
      <form>
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Type"
          size="small"
          name="name"
          value={form.type}
          inputProps={{
            readOnly: true
          }}
        />
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Project"
          size="small"
          name="name"
          value={form.project}
          inputProps={{
            readOnly: true
          }}
        />
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Location"
          size="small"
          name="name"
          value={form.location}
          inputProps={{
            readOnly: true
          }}
        />
        <br />
        <br />
        <div className="wrapbutton">
          <Button className="delete" variant="outlined" onClick={handleDelete}>
            <Trash /> Delete
          </Button>
        </div>
      </form>
    </div>
  );
}
